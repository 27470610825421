<script setup lang="ts">
  import GroupsFormMolecule from '@/components/molecules/groups-form-molecule/GroupsFormMolecule.vue';
  import { computed, nextTick, ref, watch } from 'vue';
  import { useStore } from 'vuex';

  const props = defineProps<{ initialDetails: any }>();

  const emit = defineEmits(['update:groupDetails']);

  let isUpdating = false;

  const store = useStore();

  const group = computed(() => store.getters['group/getGroup']);
  const localDetails = ref({ ...props.initialDetails });

  watch(
    group,
    (newValue) => {
      if (!isUpdating && newValue) {
        localDetails.value = { ...newValue };
      }
    },
    { immediate: true, deep: true },
  );

  const updateDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('group/SET_GROUP', updatedDetails);

    emit('update:groupDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };
</script>

<template>
  <div class="wrapper">
    <GroupsFormMolecule
      :details="localDetails"
      @updateDetails="updateDetails"
    />
  </div>
</template>

<style scoped>
  .wrapper {
    width: 90%;
    margin: auto;
    padding: 10px 20px;
  }
</style>
