import Collection from '@/models/collection/collection.model';
import APIService from '../api.service';

class CollectionService {
  public async getAll(data: any): Promise<Collection[]> {
    return APIService.post<Collection[]>(`/collections/cms/search`, data);
  }

  public async getAllNoFilter(): Promise<Collection[]> {
    return APIService.get<Collection[]>(`/collections`);
  }

  public async getById(id: string): Promise<Collection> {
    return APIService.get<Collection>(`/collections/${id}`);
  }

  public async create(data: any): Promise<Collection> {
    return APIService.post<Collection>(`/collections`, data);
  }

  public async update(id: string, data: any): Promise<Collection> {
    return APIService.post(`/collections/update/${id}`, data);
  }

  public async delete(id: string): Promise<any> {
    return APIService.delete(`/collections/${id}`);
  }

  public async deleteMultiple(data: any): Promise<any> {
    return APIService.post(`/collections/delete/multiple`, data);
  }

  public async restore(id: string): Promise<Collection> {
    return APIService.post(`/collections/restore/${id}`, {});
  }

  public async publish(id: string): Promise<Collection> {
    return APIService.post(`/collections/publish/${id}`, {});
  }
}

export default new CollectionService();
