<script setup lang="ts">
  type ButtonAtomProps = {
    class: string;
    disabled: boolean;
    onClick?: (event: MouseEvent) => void;
  };

  const props = withDefaults(defineProps<ButtonAtomProps>(), {
    disabled: false,
  });
  const handleClick = (event: MouseEvent) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };
</script>

<template>
  <button
    @click="handleClick"
    :class="[props.class, { disabled: disabled }]"
    :disabled="props.disabled"
    s
  >
    <slot></slot>
  </button>
</template>

<style scoped>
  .btn {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 100px;
    min-width: 80px;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
  }

  .btn-primary {
    color: white;
    background-color: var(--color-black);
  }

  .btn-secondary {
    color: var(--color-black);
    background-color: transparent;
    border: 1px solid var(--color-black);
  }

  .btn-no-border {
    border: none;
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
</style>
