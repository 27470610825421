<template>
  <div>
    <TopbarOrganism title="Users" />
    <TableMolecule :dataType="dataType" />
  </div>
</template>

<script setup lang="ts">
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';

  const dataType = 'User';
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>
