<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import ArticleEditorOrganism from '@/components/organisms/article-editor-organism/ArticleEditorOrganism.vue';
import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
import { useRoute, useRouter } from 'vue-router';
import Resource from '@/models/resource/resource.model';

const store = useStore();
const router = useRouter();
const route = useRoute();
const resource = computed(() => store.getters['resource/getResource']);

const loading = ref(true);

let topbarPrimaryButtonText = '';
let topbarSecondaryButtonText = '';
let topbarPrimaryButtonHandler;
let topbarSecondaryButtonHandler;

onMounted(() => {
  if (route.query.id) {
    store.dispatch('resource/fetchItemDetails', route.query.id).then(() => {
      loading.value = false;
    });
    topbarPrimaryButtonText = 'Save';
    topbarSecondaryButtonText = 'Cancel';
    topbarSecondaryButtonHandler = cancelHandler;
    topbarPrimaryButtonHandler = publish;
  } else {
    store.commit(
      'resource/SET_RESOURCE',
      new Resource({ resource_type: 'article' }),
    );
    loading.value = false;
    topbarPrimaryButtonText = 'Publish';
    topbarSecondaryButtonText = 'Submit to draft';
    topbarPrimaryButtonHandler = publish;
    topbarSecondaryButtonHandler = submitToDraft;
  }
});

onBeforeUnmount(() => {
  store.commit('resource/CLEAR_RESOURCE');
});

const cancelHandler = async () => {
  router.back();
};

const submitToDraft = async () => {
  store.commit('resource/SET_RESOURCE', {
    ...resource.value,
    status: 'draft',
    is_published: false,
  });
  await nextTick();
  store.dispatch('resource/createItem', resource.value).then(() => {
    router.back();
  });
};

const publish = async () => {
  store.commit('resource/SET_RESOURCE', {
    ...resource.value,
    status: 'published',
    is_published: true,
  });
  await nextTick();
  store.dispatch('resource/createItem', resource.value).then(() => {
    router.back();
  });
};
</script>

<template>
  <div class="article-page-container">
    <TopbarOrganism linkText="Back to articles" :showIcon="true" icon="angleLeft"
      :secondaryButtonText="topbarSecondaryButtonText" :primaryButtonText="topbarPrimaryButtonText"
      :onSecondaryClick="topbarSecondaryButtonHandler" :onPrimaryClick="topbarPrimaryButtonHandler" />
    <ArticleEditorOrganism v-if="!loading" :initialResourceDetails="resource" />
    <p v-else>Loading...</p>
  </div>
</template>
