<template>
  <div class="resources-modal-overlay">
    <div class="resources-modal" @click.stop>
      <span class="title">Add resources</span>
      <div class="resources-modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .resources-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .resources-modal {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 60%;
    max-height: 75%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    overflow: hidden;
  }

  .resources-modal-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    max-height: calc(75vh - 60px);
  }

  .resources-modal-content::-webkit-scrollbar {
    width: 10px;
  }

  .resources-modal-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  .resources-modal-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 8px;
  }

  .resources-modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }

  .title {
    display: flex;
    text-align: left;
    font-size: 24px;
    font-weight: 800;
    padding: 30px;
    margin-left: 40px;
  }
</style>
