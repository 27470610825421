<script setup lang="ts">
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import apiService from '@/services/api.service';
  import authService from '@/services/auth/auth.service';
  import { jwtDecode } from 'jwt-decode';
  import { computed, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import ResetPasswordModal from '@/components/atoms/ResetPasswordModal.vue';

  const store = useStore();
  const router = useRouter();

  const user = computed(() => store.getters['user/getUser']);

  const username = ref('');
  const password = ref('');
  const showAddNewTagModal = ref(false);
  const isVisible = ref(true);

  const login = async () => {
    try {
      const response = await authService.login(username.value, password.value);
      if (response.access_token) {
        await apiService.setAuthHeaders(response.access_token);
        await apiService.storeAuthData(response);

        const decodedToken = jwtDecode(response.access_token);
        store.dispatch('user/fetchUser', decodedToken.sub);
        watch(user, (newUser, oldUser) => {
          if (newUser) {
            router.push('/');
          }
        });
      } else {
        //showAlert("Error loging in", response.data.message);
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error
    }
  };

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
    isVisible.value = !isVisible.value;
  }

  function handleGoBack() {
    isVisible.value = !isVisible.value;
    showAddNewTagModal.value = false;
  }
</script>

<template>
  <div class="sign-in-wrapper">
    <h1 class="logo-text">Bryan Post</h1>

    <div class="sign-in-form" v-show="isVisible">
      <div class="header">
        <h1 class="sign-in-title">Sign in to Bryan Post</h1>
      </div>

      <div class="sign-in-inputs">
        <InputAtom
          class="input-signin"
          id="username-input"
          placeholder="Email"
          v-model="username"
          @keyup.enter="login"
        ></InputAtom>
        <InputAtom
          class="input-signin"
          id="password-input"
          placeholder="Password"
          v-model="password"
          type="password"
          @keyup.enter="login"
        >
        </InputAtom>
      </div>

      <div class="reset-password">
        <label class="reset-password-btn" @click="openAddNewTagModal"
          >Reset password</label
        >
      </div>

      <ButtonAtom class="btn btn-primary sign-in-btn" :onClick="login"
        >Sign in</ButtonAtom
      >
    </div>
  </div>

  <ResetPasswordModal
    :show="showAddNewTagModal"
    @update:show="showAddNewTagModal = $event"
    @go-back="handleGoBack"
  />
</template>

<style scoped src="./SignInView.css"></style>
