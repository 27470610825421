<script setup lang="ts">
  import { onMounted, onUnmounted, defineEmits } from 'vue';
  import InputAtom from './InputAtom.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import userImgSrc from '@/assets/user-default.png';
  import UserImgAtom from '@/components/atoms/UserImgAtom.vue';

  const props = defineProps({
    show: Boolean,
  });

  const emits = defineEmits(['update:show']);

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div
    v-if="props.show"
    class="send-notification-modal-overlay"
    @click="closeModal"
  >
    <div class="send-notification-modal" @click.stop>
      <h3 class="send-notification-modal-title">Personal Push Notification</h3>
      <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
      <div class="wrapper">
        <div class="center-container">
          <div class="cover-section">
            <div class="cover-img">
              <ButtonAtom class="btn btn-secondary coverBtn">
                <button class="clear-image-btn">
                  <IconAtom icon="editIcon" color="#6B6E78" />
                </button>
              </ButtonAtom>
            </div>
          </div>
        </div>
        <div class="details-container">
          <div class="title">
            <label for="title">Title</label>
            <InputAtom id="title" placeholder="Name" class="input" />
          </div>

          <div class="text">
            <label for="text">Text</label>
            <textarea
              id="text"
              placeholder="Message"
              class="input textarea"
              rows="4"
            />
          </div>

          <div class="link">
            <label for="link">URL Link</label>
            <InputAtom id="link" placeholder="Link" class="input" />
          </div>
        </div>

        <div class="attach-resources">
          <div class="attach-resources-text">Attach Resources (Optional)</div>
          <ButtonAtom class="btn btn-secondary">Select</ButtonAtom>
        </div>

        <div class="send-to">
          <div class="personal-details">
            <label>Send to</label>
            <div class="account-details">
              <UserImgAtom class="image-detail" :src="userImgSrc" />
              <div class="name-email-details">
                <p class="name-detail">Amir Kishk</p>
                <p class="email-detail">kishk.amir@gmail.com</p>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons">
          <ButtonAtom class="btn btn-secondary" @click="closeModal"
            >Cancel</ButtonAtom
          >
          <ButtonAtom class="btn btn-primary">Send now</ButtonAtom>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .send-notification-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .send-notification-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 20%;
    min-width: 400px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .send-notification-modal-title {
    margin-bottom: 24px;
    font-size: bold;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .input {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    margin: 12px 0;
  }

  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 19.6px;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  .center-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .cover-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
  }

  .btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
  }

  .btn.coverBtn {
    background-image: url('../../assets/default-user-img.png');
    background-size: cover;
    height: 100px;
    width: 100px;
    border-radius: 16px;
    padding: 0;
    min-width: 100px;
  }

  .clear-image-btn {
    position: absolute;
    top: -3px;
    right: -3px;
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }

  .attach-resources {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .attach-resources-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 20.8px;
  }

  .textarea {
    width: 100%;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--color-gray-30);
    resize: vertical;
    overflow: auto;
  }

  .personal-details {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    gap: 8px;
    border-bottom: 1px solid #e0e0e0;
  }

  .account-details {
    display: flex;
    gap: 16px;
  }

  .image-detail {
    width: 40px;
    height: 40px;
  }

  .name-email-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }

  .email-detail {
    font-size: 12px;
    font-weight: 500;
    color: #83868e;
  }
</style>
