<script setup lang="ts">
  import { onMounted, onUnmounted, defineProps, defineEmits, ref } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import store from '@/store';

  const props = defineProps({
    show: Boolean,
    group_id: String,
  });

  const emit = defineEmits(['update:show']);

  const closeModal = () => {
    emit('update:show', false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const onSave = () => {
    store.dispatch('group/createDiscussion', {
      description: content.value,
      group_id: props.group_id,
    });
    content.value = '';
    closeModal();
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  const content = ref('');
</script>

<template>
  <div v-if="props.show" class="discussion-modal-overlay" @click="closeModal">
    <div class="discussion-modal" @click.stop>
      <div class="modal-top-section">
        <h2>Create discussion</h2>
        <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
      </div>
      <textarea v-model="content" name="placeholder" id="placeholder" />
      <div class="buttons-container">
        <ButtonAtom class="btn btn-primary" @click="onSave">Save</ButtonAtom>
        <ButtonAtom class="btn btn-secondary" @click="closeModal"
          >Cancel</ButtonAtom
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
  .discussion-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .discussion-modal {
    padding: 30px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    border-radius: 8px;
    max-height: 50%;
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .discussion-modal h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .discussion-editor {
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 16px;
    box-sizing: border-box;
    background-color: var(--color-gray-30);
  }

  .buttons-container {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  .buttons-container p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
</style>
