import Discussion from '../discussion/discussion.model';

export default class Group {
  group_id: string;
  name: string;
  description: string;
  banner: string;
  is_deleted: boolean;
  is_published: boolean;
  status: string;
  user_count?: number;
  comment_count?: number;
  last_comment_at?: Date | string | null;
  created_at: Date;
  updated_at: Date;
  disscussions?: Discussion[];

  constructor(data: Partial<Group>) {
    this.group_id = data.group_id || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.banner = data.banner || '';
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
    this.user_count = data.user_count || 0;
    this.comment_count = data.comment_count || 0;
    this.last_comment_at = data.last_comment_at || null;
    this.status = data.status || 'draft';
    this.is_deleted = data.is_deleted || false;
    this.is_published = data.is_published || false;
    this.disscussions = data.disscussions || ([] as Discussion[]);
  }
}
