import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TinyEditor from "@/components/atoms/TinyEditor.vue";
import localStorageService from "./shared/localStorageService";
import apiService, { AuthData } from "./services/api.service";
import { jwtDecode } from "jwt-decode";

const app = createApp(App);

app.component("TinyEditor", TinyEditor);

const authData = localStorageService.getValue<AuthData>("auth");
if (authData && authData.access_token) {
  apiService.setAuthHeaders(authData.access_token);
  // Optionally, set the auth state in Vuex if using Vuex
  const decodedToken = jwtDecode(authData.access_token);
  await store.dispatch("user/fetchUser", decodedToken.sub);
}

app.use(store).use(router).mount("#app");
