import { FilterParams } from '@/shared/filterParams';
import tagService from '@/services/tag/tag.service';
import Tag from '@/models/tag/tag.model';

const tagModule = {
  namespaced: true,
  state: {
    tag: null,
    items: [],
    totalPages: 1,
    counts: { all: 0 },
  },
  mutations: {
    SET_TAG(state, tag) {
      state.tag = tag;
      if (tag.resourceTags) {
        state.resources = tag.resourceTags.flatMap((it) => it.resource);
      }
    },
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
      state.counts = { all: data.resultCount };
    },
    CLEAR_TAG(state) {
      state.tag = null;
      state.resources = [];
    },
  },
  actions: {
    async fetchTags({ commit }, params: FilterParams) {
      const data = await tagService.getAll(params);

      commit('SET_ITEMS', data);
    },
    async fetchItemDetails({ commit }, id: string) {
      const details: any = await tagService.getById(id);
      commit('SET_TAG', details.data);
    },
    async createItem({ state, dispatch }, payload: Tag) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        let newItem;
        if (payload.tag_id) {
          newItem = await tagService.update(payload);
        } else {
          newItem = await tagService.create(payload);
        }
        dispatch('loader/hideLoader', null, { root: true });
        return newItem.data; // Return the newly created tag
      } catch (e: any) {
        console.error(e);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async updateItem({ dispatch }, payload: Tag) {
      // await ResourceService.update(payload);
      // dispatch("fetchItemDetails", payload.resource_id);
    },
  },
  getters: {
    getTagCounts(state) {
      return state.counts;
    },
    getTag(state) {
      return state.tag;
    },
    getItems(state) {
      return state.items;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
  },
};

export default tagModule;
