<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.05 12.0001V12C21.05 9.59979 20.0965 7.29789 18.3993 5.60068C16.7021 3.90348 14.4002 2.95 12 2.95C9.59979 2.95 7.29789 3.90348 5.60068 5.60068C3.90348 7.29789 2.95 9.59979 2.95 12V19.56C2.95 19.9552 3.10698 20.3342 3.38641 20.6136C3.66584 20.893 4.04483 21.05 4.44 21.05H12H12.0001C14.3994 21.0474 16.6998 20.093 18.3964 18.3964C20.093 16.6998 21.0474 14.3994 21.05 12.0001ZM15.4245 11.4645C15.5689 11.3201 15.65 11.1242 15.65 10.92C15.65 10.7158 15.5689 10.5199 15.4245 10.3755C15.2801 10.2311 15.0842 10.15 14.88 10.15H8.76C8.55578 10.15 8.35993 10.2311 8.21553 10.3755C8.07113 10.5199 7.99 10.7158 7.99 10.92C7.99 11.1242 8.07113 11.3201 8.21553 11.4645C8.35993 11.6089 8.55578 11.69 8.76 11.69H14.88C15.0842 11.69 15.2801 11.6089 15.4245 11.4645ZM14.88 13.03H8.76C8.55578 13.03 8.35993 13.1111 8.21553 13.2555C8.07113 13.3999 7.99 13.5958 7.99 13.8C7.99 14.0042 8.07113 14.2001 8.21553 14.3445C8.35993 14.4889 8.55578 14.57 8.76 14.57H14.88C15.0842 14.57 15.2801 14.4889 15.4245 14.3445C15.5689 14.2001 15.65 14.0042 15.65 13.8C15.65 13.5958 15.5689 13.3999 15.4245 13.2555C15.2801 13.1111 15.0842 13.03 14.88 13.03ZM17.3104 6.68963C18.7188 8.09801 19.51 10.0082 19.51 11.9999C19.5079 13.9911 18.716 15.9001 17.308 17.308C15.9001 18.716 13.9911 19.5079 11.9999 19.51H4.49V12C4.49 10.0082 5.28123 8.09803 6.68963 6.68963C8.09803 5.28123 10.0082 4.49 12 4.49C13.9918 4.49 15.902 5.28123 17.3104 6.68963Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.1"
    />
  </svg>
</template>
