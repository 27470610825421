<template>
  <canvas
    ref="chartCanvas"
    class="chart-canvas"
    width="200"
    height="200"
    style="width: 200px; height: 200px; margin: auto auto"
  ></canvas>
</template>

<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';
  import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    PieController,
    ChartData,
    ChartOptions,
  } from 'chart.js';
  import type { Chart } from 'chart.js';

  ChartJS.register(ArcElement, Tooltip, Legend, PieController);

  interface Props {
    chartData: ChartData<'pie'>;
    chartOptions: ChartOptions<'pie'>;
  }

  const props = defineProps<Props>();
  const chartCanvas = ref<HTMLCanvasElement | null>(null);
  let chartInstance: Chart<'pie'> | null = null;

  onMounted(() => {
    if (chartCanvas.value) {
      const config = {
        type: 'pie' as const,
        data: props.chartData,
        options: {
          ...props.chartOptions,
          rotation: -90,
          circumference: 360,
          responsive: false,
          maintainAspectRatio: true,
        },
      };

      chartInstance = new ChartJS(chartCanvas.value, config);
    }
  });

  watch(
    () => props.chartData,
    (newData) => {
      if (chartInstance) {
        chartInstance.data = newData;
        chartInstance.update();
      }
    },
    { deep: true },
  );
</script>
