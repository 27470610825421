import apiService from '../api.service';

class AnalyticsService {
  public async getUserAnalytics() {
    return apiService.get('/analytics/users');
  }

  public async getPerformanceAnalytics() {
    const response = await apiService.get('/analytics/performance');
    return response;
  }

  public async getDemographicsAnalytics() {
    const response = await apiService.get('/analytics/demographics');
    return response;
  }

  public async getMostViewedResourceContent() {
    return apiService.get('/analytics/performance/resource-content');
  }

  public async getDemographicAnalytics() {
    const response: any = await apiService.get('/analytics/demographics');
    console.log(response.data, 'res data');

    return response;
  }

  public async getFinancialAnalytics() {
    const response: any = await apiService.get('/analytics/financial');
    return response;
  }

  public async getFinancialAnalyticsForPeriod() {
    const response: any = await apiService.get('/analytics/financial/period');
    return response;
  }

  public async getGroupStats() {
    const response: any = await apiService.get('/analytics/performance/groups');
    return response;
  }
}

export default new AnalyticsService();
