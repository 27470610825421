import analyticsService from '@/services/analytics/analytics.service';

const analyticsModule = {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    async fetchAnalyticsData({ commit }) {
      const response: any = await analyticsService.getUserAnalytics();

      commit('SET_DATA', response.data);
    },
    async fetchPerformanceAnalytics({ commit }) {
      const general: any = await analyticsService.getPerformanceAnalytics();
      const mostViewedResourceContent: any =
        await analyticsService.getMostViewedResourceContent();
      const groupsPerformance: any = await analyticsService.getGroupStats();
      commit('SET_DATA', {
        ...general.data,
        mostViewedResourceContent: mostViewedResourceContent.data,
        mostActiveGroups: groupsPerformance.data,
      });
    },
    async fetchDemographicsAnalytics({ commit }) {
      const response: any = await analyticsService.getDemographicAnalytics();
      commit('SET_DATA', response.data);
    },
    async fetchFinancialAnalytics({ commit }) {
      const response: any = await analyticsService.getFinancialAnalytics();
      const dataForPeriod: any =
        await analyticsService.getFinancialAnalyticsForPeriod();
      commit('SET_DATA', { ...response.data, ...dataForPeriod.data });
    },
  },
  getters: {
    getAnalyticsData(state) {
      return state.data;
    },
  },
};

export default analyticsModule;
