import userService from '@/services/user/user.service';
import User from '@/models/user/user.model';
import { FilterParams, SearchParamsAND } from '@/shared/filterParams';

const userModule = {
  namespaced: true,
  state: {
    user: null,
    selectedUser: null,
    counts: { all: 0, active: 0, banned: 0, inactive: 0 },
    items: [],
    totalPages: 1,
  },
  mutations: {
    SET_USER(state: { user: User }, user: User) {
      state.user = user;
    },
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
      state.counts = { ...data.counts.user_status };
    },
    SET_SELECTED_USER(state, user_id) {
      state.selectedUser = state.items.find((user) => user.user_id === user_id);
    },
    CLEAR_USER(state) {
      state.user = null;
    },
    UPDATE_USER(state, updatedUser) {
      state.items = state.items.map((user) => {
        if (user.user_id === updatedUser.data.user_id) {
          user.name = updatedUser.data.name;
          user.surname = updatedUser.data.surname;
          user.email = updatedUser.data.email;
          user.phone_number = updatedUser.data.phone_number;
          user.username = updatedUser.data.username;
          user.full_name = `${updatedUser.data.name} ${updatedUser.data.surname}`;
        }
        return user;
      });
    },
  },
  actions: {
    async fetchUser({ commit }, userId) {
      try {
        const response = await userService.getById(userId);
        commit('SET_USER', response);
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    },

    selectUser({ commit }, userId) {
      commit('SET_SELECTED_USER', userId);
    },

    async fetchUsers({ commit }, params: FilterParams) {
      try {
        const data = await userService.getAll(params);
        commit('SET_ITEMS', data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    },

    async ban({ dispatch }, data: { id: string; filters: FilterParams }) {
      try {
        const filters = structuredClone(data.filters);
        await userService.updateStatus(data.id, 'banned');
        dispatch('fetchUsers', filters);
      } catch (error) {
        console.error('Failed to ban user:', error);
      }
    },

    async delete({ dispatch }, data: { id: string; filters: FilterParams }) {
      try {
        const filters = structuredClone(data.filters);
        await userService.updateStatus(data.id, 'inactive');
        dispatch('fetchUsers', filters);
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    },

    async updateUser({ commit }, user) {
      try {
        const data = await userService.update(user);
        commit('UPDATE_USER', data);
      } catch (error) {
        console.error('Failed to ban user:', error);
      }
    },

    async restoreItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      try {
        const filters = structuredClone(data.filters);
        await userService.updateStatus(data.id, 'active');
        filters.searchParamsAND?.push(
          new SearchParamsAND('banned', 'user_status', 'equals'),
        );
        dispatch('fetchUsers', filters);
      } catch (error) {
        console.error('Failed to ban user:', error);
      }
    },

    signOut({ commit }) {
      window.localStorage.removeItem('auth');
      window.location.reload();
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getItems(state) {
      return state.items;
    },
    getUserCounts(state) {
      return state.counts;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
    getSelectedUser(state) {
      return state.selectedUser;
    },
  },
};

export default userModule;
