// store/modules/loader.js
export const loader = {
  namespaced: true,
  state: {
    isLoading: false,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('SET_LOADING', true);
    },
    hideLoader({ commit }) {
      commit('SET_LOADING', false);
    },
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
  },
};
