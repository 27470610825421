<script setup lang="ts">
  import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import CollectionsFormOrganism from '@/components/organisms/collections-form-organism/CollectionsFormOrganism.vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Collection from '@/models/collection/collection.model';
  import { AdditionalParams } from '@/shared/filterParams';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import ResourceModalMolecule from '@/components/molecules/resouce-modal-molecule/ResourceModalMolecule.vue';

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const collection = computed(() => store.getters['collection/getCollection']);
  const collection_id = ref('');
  const tableKey = ref(0);

  const loading = ref(true);
  const showResourcesModal = ref(false);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      collection_id.value = route.query.id.toString();
      store
        .dispatch('collection/fetchItemDetails', collection_id.value)
        .then(() => {
          loading.value = false;
        });

      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = publish;
    } else {
      store.commit('collection/SET_COLLECTION', new Collection({}));
      loading.value = false;
      loading.value = false;
      topbarPrimaryButtonText = 'Publish';
      topbarSecondaryButtonText = 'Save to draft';
      topbarPrimaryButtonHandler = publish;
      topbarSecondaryButtonHandler = submitToDraft;
    }
  });

  const openResourcesModal = () => {
    showResourcesModal.value = true;
  };

  const closeResourcesModal = () => {
    showResourcesModal.value = false;
    store.commit('resource/CLEAR_NOT_CONNECTED');
    tableKey.value += 1;
  };

  onBeforeUnmount(() => {
    store.commit('collection/CLEAR_COLLECTION');
    store.commit('resource/CLEAR_CONNECTED_RESOURCE');
    store.commit('resource/CLEAR_NOT_CONNECTED');
  });

  const cancelHandler = async () => {
    router.back();
  };

  const submitToDraft = async () => {
    store.commit('collection/SET_COLLECTION', {
      ...collection.value,
      status: 'draft',
      is_published: false,
    });
    await nextTick();
    store.dispatch('collection/createItem', collection.value).then(() => {
      router.back();
    });
  };

  const publish = async () => {
    store.commit('collection/SET_COLLECTION', {
      ...collection.value,
      status: 'published',
      is_published: true,
    });
    await nextTick();
    store.dispatch('collection/createItem', collection.value).then(() => {
      router.back();
    });
  };
</script>

<template>
  <div>
    <TopbarOrganism
      linkText="Back to collections"
      :showIcon="true"
      icon="angleLeft"
      :secondaryButtonText="topbarSecondaryButtonText"
      :primaryButtonText="topbarPrimaryButtonText"
      :onSecondaryClick="topbarSecondaryButtonHandler"
      :onPrimaryClick="topbarPrimaryButtonHandler"
    />
    <div v-if="!loading">
      <CollectionsFormOrganism :initialDetails="collection" />
      <TableMolecule
        dataType="ConnectedResources"
        :key="tableKey"
        :dynamicAdditionalFilter="
          new AdditionalParams(
            'collections',
            'collection_id',
            collection_id,
            'some',
          )
        "
        :onActionClick="openResourcesModal"
      />
    </div>
    <p v-else>Loading...</p>
  </div>

  <ResourceModalMolecule v-if="showResourcesModal" @click="closeResourcesModal">
    <TableMolecule
      dataType="NotConnectedResources"
      :dynamicAdditionalFilter="
        new AdditionalParams(
          'collections',
          'collection_id',
          collection_id,
          'none',
        )
      "
    />
  </ResourceModalMolecule>
</template>

<style scoped></style>
