<script setup lang="ts">
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import store from '@/store';
  import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
  import { useRouter } from 'vue-router';

  const emit = defineEmits(['close', 'action', 'refresh']);
  const notificationActionModalRef = ref<HTMLElement | null>(null);
  const router = useRouter();

  const onActionClick = (action: string): void => {
  if (action === 'editDetails') {
    router.push('/notificationDetails?id=' + props.notificationId);
  } else if (action === 'deleteNotification') {
    store.dispatch('notification/deleteItem', props.notificationId).then(() => {
      emit('refresh');
    });
  }
};

  const actions = [
    { icon: 'deleteIcon', label: 'Delete notification', type: 'deleteNotification' },
    { icon: 'settingIcon', label: 'Edit scheduling date', type: 'editDate' },
    { icon: 'shareIcon', label: 'Send right now', type: 'sendNotification' },
    { icon: 'settingIcon', label: 'Edit details', type: 'editDetails' },
  ];

  const props = withDefaults(
    defineProps<{
      isVisible: boolean;
      activeButton: string;
      notificationId: string;
    }>(),
    {},
  );

  const filteredActions = computed(() => {
    if (props.activeButton === 'Sent' || props.activeButton === '') {
      return [actions[0]];
    }
    if (props.activeButton === 'Scheduled') {
      return [actions[1], actions[2]];
    }
    if (props.activeButton === 'Drafts') {
      return [actions[3], actions[2]];
    }
    return [];
  });

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      notificationActionModalRef.value &&
      !notificationActionModalRef.value.contains(event.target as Node) &&
      props.isVisible
    ) {
      emit('close');
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
</script>

<template>
  <div class="notification-action-container" ref="notificationActionModalRef">
    <span
      v-for="(action, index) in filteredActions"
      :key="index"
      class="notification-action"
      @click="onActionClick(action.type)"
    >
      <IconAtom
        :icon="action.icon"
        class="notification-action-icon"
        :aria-label="action.label"
      ></IconAtom>
      {{ action.label }}
    </span>
  </div>
</template>

<style scoped src="./NotificationActionModal.css"></style>
