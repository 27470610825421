<script setup>
  import defaultUserImg from '@/assets/user-default.png';

  const props = defineProps({
    src: {
      type: String,
      required: true,
      default: defaultUserImg,
    },
  });
</script>

<template>
  <img :src="props.src" alt="User Image" class="user-img" />
</template>

<style scoped>
  .user-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }
</style>
