import Notification from '@/models/notification/notification.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class NotificationService {
  public async getAll(params: FilterParams): Promise<Notification[]> {
    return APIService.post<Notification[]>(`/notifications/cms/search`, params);
  }
  public async getById(id: string): Promise<Notification> {
    return APIService.get<Notification>(`/Notifications/${id}`);
  }
  public async deleteMultiple(data: any): Promise<Notification[]> {
    return APIService.post(`/Notifications/delete/multiple`, data);
  }

  public async delete(id: string): Promise<any> {
    return APIService.delete(`/notifications/${id}`);
  }

  public async create(data: any): Promise<Notification> {
    return APIService.post<Notification>(`/notifications`, data);
  }

  public async update(id: string, data: any): Promise<Notification> {
    return APIService.put(`/notifications/${id}`, data);
  }

  public async restore(id: string): Promise<Notification> {
    return APIService.post(`/notifications/restore/${id}`, {});
  }

  public async publish(id: string): Promise<Notification> {
    return APIService.post(`/notifications/publish/${id}`, {});
  }
}

export default new NotificationService();
