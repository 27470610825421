import { FilterParams, SearchParamsAND } from '@/shared/filterParams';
import notificationService from '@/services/notification/notification.service';
import Notification from '@/models/notification/notification.model';

const notificationModule = {
  namespaced: true,
  state: {
    notification: null,
    counts: { sent: 0, draft: 0, scheduled: 0 },
    items: [], // Add an items state to store rowsData
    totalPages: 1, // Add a totalPages state to store pagination data
  },
  mutations: {
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
      state.counts = { ...data.counts.status };
    },
    CLEAR_NOTIFICATION(state) {
      state.notification = null;
    },
  },
  actions: {
    async fetchNotifications({ commit }, params: FilterParams) {
      const data = await notificationService.getAll(params);
      commit('SET_ITEMS', data);
    },
    async fetchItemDetails({ commit }, id: string) {
      const details: any = await notificationService.getById(id);
      commit('SET_NOTIFICATION', details.data);
    },
    async createItem({ state, dispatch }, payload: Notification) {
      dispatch('loader/showLoader', null, { root: true });
      const formData = new FormData();

      // Append string fields
      formData.append('title', payload.title); // String
      formData.append('message', payload.message); // String
      formData.append('status', payload.status); // Enum as string
      formData.append('send_to_all', payload.send_to_all.toString()); // Boolean as string
      formData.append('url', payload.url); // String
      if (payload.repeat_type) {
        formData.append('repeat_type', payload.repeat_type);
      }
      // Append array fields
      if (payload.send_to) {
        payload.send_to.forEach((item) => {
          formData.append('send_to', item);
        });
      }

      if (payload.resouces) {
        payload.resouces.forEach((item) => {
          formData.append('resouces', item);
        });
      }
      // Append date fields
      if (payload.schedule_at) {
        formData.append('schedule_at', payload.schedule_at.toISOString());
      }

      // Append banner file if it exists
      if (payload.banner && typeof payload.banner == 'object') {
        const file = payload.banner as any;
        const fileName = file.name.replace(/\s+/g, '_');
        formData.append('bannerFile', file, fileName);
      } else if (payload.banner != '') {
        formData.append('banner', payload.banner);
      }

      try {
        if (payload.notification_id) {
          await notificationService.update(payload.notification_id, formData);
        } else {
          await notificationService.create(formData);
        }
      } catch (error) {
        console.error('Error creating group', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async deleteItems({ dispatch }, payload: { data: string[] }) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await notificationService.deleteMultiple({
          notification_ids: payload.data,
        });
      } catch (error) {
        console.error('Error deleting notifications', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async deleteItem({ dispatch }, id: string) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await notificationService.delete(id);
      } catch (error) {
        console.error('Error deleting notification', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    // async publishItem(
    //   { dispatch },
    //   data: { id: string; filters: FilterParams },
    // ) {
    //   const filters = structuredClone(data.filters);
    //   await groupService.publish(data.id);
    //   filters.searchParamsAND?.push(
    //     new SearchParamsAND('draft', 'status', 'equals'),
    //   );
    //   dispatch('fetchGroups', filters);
    // },
    // async restoreItem(
    //   { dispatch },
    //   data: { id: string; filters: FilterParams },
    // ) {
    //   const filters = structuredClone(data.filters);
    //   await groupService.restore(data.id);
    //   filters?.searchParamsAND?.push(
    //     new SearchParamsAND('unpublished', 'status', 'equals'),
    //   );
    //   dispatch('fetchGroups', filters);
    // },
    // Other actions...
  },
  getters: {
    getNotificationCounts(state) {
      return state.counts;
    },
    getNotification(state) {
      return state.notification;
    },
    getItems(state) {
      return state.items;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
  },
};

export default notificationModule;
