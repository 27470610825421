<template>
  <table>
    <thead>
      <tr>
        <th>Groups</th>
        <th>Views</th>
      </tr>
    </thead>
    <tbody v-if="analyticsData?.mostActiveGroups">
      <tr
        v-for="dataItem in analyticsData.mostActiveGroups"
        :key="dataItem.data"
      >
        <td>{{ dataItem.label || '-' }}</td>
        <td>{{ dataItem.data }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
  import store from '@/store';
  import { computed } from 'vue';

  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );
</script>

<style scoped src="./style.css" />
