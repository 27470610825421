<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import IconAtom from "@/components/atoms/IconAtom.vue";
import InputAtom from "@/components/atoms/InputAtom.vue";
import ButtonAtom from "@/components/atoms/ButtonAtom.vue";
import { useStore } from "vuex";
import { FilterParams } from "@/shared/filterParams";
import SortModalMolecule from "../sort-modal-molecule/SortModalMolecule.vue";
import { SearchbarModalOptions } from "@/shared/searchbarModalOptions";
import FilterModalMolecule from "../filter-modal-molecule/FilterModalMolecule.vue";

const searchQuery = ref("");
const sortProperty = ref("");
const filterProperties = ref<string[]>([]);
const store = useStore();
const showSortModal = ref(false);
const showFilterModal = ref(false);
const sortModalPosition = ref({ top: 0, left: 0 });
const filterModalPosition = ref({ top: 0, right: 0 });
const emit = defineEmits(["update:modelValue"]);

const defaultSortOptions: SearchbarModalOptions[] = [
  new SearchbarModalOptions("created_at:desc", "Recent first"),
  new SearchbarModalOptions("created_at:asc", "Old first"),
];

const sortOptions = ref<SearchbarModalOptions[]>([]);

const props = withDefaults(
  defineProps<{
    showSort: boolean;
    showFilter: boolean;
    filterTitle: string;
    itemsFetchAction: string;
    pageSize: number;
    additionalSortOptions?: SearchbarModalOptions[];
    filterOptions?: SearchbarModalOptions[];
    modelValue: FilterParams;
  }>(),
  {
    showFilter: true,
    showSort: true,
    pageSize: 10,
    filterOptions: () => [
      new SearchbarModalOptions(
        "tags.tag.tag_name:postman test",
        "postman test"
      ),
    ],
  }
);

onMounted(() => {
  sortOptions.value = props.additionalSortOptions
    ? [...defaultSortOptions, ...props.additionalSortOptions]
    : [...defaultSortOptions];
});

const filterParams = ref(props.modelValue);

const buildFilterParams = () => {
  let filterParamsNew = new FilterParams(1, props.pageSize, {
    searchTerm: searchQuery.value,
  });

  if (sortProperty.value && sortProperty.value != "") {
    const sortProps = sortProperty.value.split(":");
    filterParamsNew.sortParams = {
      sortBy: sortProps[0],
      sortOrder: sortProps[1],
    };
  }

  if (filterProperties.value && filterProperties.value.length > 0) {
    const searchParamsAND = filterProperties.value.map((filterProp) => {
      const propParts = filterProp.split(":");
      return {
        searchField: propParts[0],
        searchTerm: propParts[1],
        action: "equals",
      };
    });
    filterParamsNew.searchParamsAND = searchParamsAND;
  }

  filterParams.value = filterParamsNew;

  emit("update:modelValue", filterParams.value);
};

watch(searchQuery, (newVal) => {
  buildFilterParams();
  //return store.dispatch("resource/fetchResources", buildFilterParams());
});

watch(sortProperty, (newVal) => {
  buildFilterParams();
  //return store.dispatch("resource/fetchResources", buildFilterParams());
});

watch(filterProperties, (newVal) => {
  buildFilterParams();
  //return store.dispatch("resource/fetchResources", buildFilterParams());
});

const openSortModal = (event: MouseEvent): void => {
  showFilterModal.value = false;
  event.stopPropagation();
  let targetElement = event.target as HTMLElement;

  // Traverse up the DOM tree to find the closest button element
  while (targetElement && targetElement.tagName !== "BUTTON") {
    targetElement = targetElement.parentElement as HTMLElement;
  }

  if (targetElement) {
    const buttonRect = targetElement.getBoundingClientRect();

    sortModalPosition.value = {
      top: buttonRect.bottom,
      left: buttonRect.left,
    };
    showSortModal.value = true;
  }
};

const openFilterModal = (event: MouseEvent): void => {
  showSortModal.value = false;
  event.stopPropagation();
  let targetElement = event.target as HTMLElement;

  // Traverse up the DOM tree to find the closest button element
  while (targetElement && targetElement.tagName !== "BUTTON") {
    targetElement = targetElement.parentElement as HTMLElement;
  }

  if (targetElement) {
    const buttonRect = targetElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth; // Get the width of the viewport
    filterModalPosition.value = {
      top: buttonRect.bottom,
      right: viewportWidth - buttonRect.right, // Calculate right position
    };
    showFilterModal.value = true;
  }
};

const handleSortUpdate = (selectedSort: string) => {
  sortProperty.value = selectedSort;
};
const handleFilterUpdate = (selectedFilters: string[]) => {
  filterProperties.value = selectedFilters ? [...selectedFilters] : [];
};
</script>

<template>
  <div class="search-container">
    <ButtonAtom class="btn btn-secondary">
      <IconAtom icon="searchIcon" color="#333" />
    </ButtonAtom>
    <InputAtom
      class="search-input"
      placeholder="Search..."
      v-model="searchQuery"
    />
    <ButtonAtom
      v-if="props.showSort"
      class="btn btn-secondary"
      @click="openSortModal"
    >
      <IconAtom icon="sortIcon" color="#333" /><span>sort</span>
    </ButtonAtom>
    <ButtonAtom
      v-if="props.showFilter"
      class="btn btn-secondary"
      @click="openFilterModal"
    >
      <IconAtom icon="filterIcon" color="#333" /><span>filter</span>
    </ButtonAtom>

    <SortModalMolecule
      :isVisible="showSortModal"
      :top="sortModalPosition.top"
      :left="sortModalPosition.left"
      @close="showSortModal = false"
      @update:sort="handleSortUpdate"
      :sortOptions="sortOptions"
    />
    <FilterModalMolecule
      :filterModalTitle="props.filterTitle"
      :isVisible="showFilterModal"
      :top="filterModalPosition.top"
      :right="filterModalPosition.right"
      @close="showFilterModal = false"
      :filterOptions="filterOptions"
      @update:sort="handleFilterUpdate"
    />
  </div>
</template>

<style scoped src="./SearchbarMolecule.css"></style>
