class LocalStorageService {
  private static instance: LocalStorageService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): LocalStorageService {
    if (!LocalStorageService.instance) {
      LocalStorageService.instance = new LocalStorageService();
    }

    return LocalStorageService.instance;
  }

  public setValue(key: string, value: any) {
    const jsonValue = JSON.stringify(value);
    window.localStorage.setItem(key, jsonValue);
  }

  public getValue<T>(key: string): T | null {
    const storedValue = window.localStorage.getItem(key);

    if (storedValue) {
      return JSON.parse(storedValue);
    }

    return null;
  }

  public deleteFromStorage(key: string): void {
    window.localStorage.removeItem(key);
  }
}

export default LocalStorageService.getInstance();
