export default class Notification {
  notification_id: string;
  schedule_id: string;
  title: string;
  message: string;
  banner: string;
  sent: string;
  send_to: string[];
  resouces: string[];
  send_to_count: string;
  send_to_all: boolean;
  url: string;
  time_sent: Date;
  status: string;
  schedule_at: Date;
  updated_at: Date;
  repeat_type: string;

  constructor(data: Partial<Notification>) {
    this.notification_id = data.notification_id || '';
    this.schedule_id = data.schedule_id || '';
    this.title = data.title || '';
    this.message = data.message || '';
    this.send_to = data.send_to || [];
    this.sent = data.sent || '';
    this.send_to_all = data.send_to_all || false;
    this.resouces = data.resouces || [];
    this.url = data.url || '';
    this.send_to_count = data.send_to_count || '';
    this.banner = data.banner || '';
    this.status = data.status || '';
    this.time_sent = data.time_sent ? new Date(data.time_sent) : new Date();
    this.schedule_at = data.schedule_at
      ? new Date(data.schedule_at)
      : new Date();
    this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
    this.repeat_type = data.repeat_type || '';
  }
}
