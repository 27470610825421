<template>
  <div class="demographics-analytics-grid">
    <div class="col-left">
      <AnalyticsCardOrganism title="Age Demographics" />
      <AnalyticsCardOrganism title="Top Countries" />
    </div>
    <div class="col-right">
      <AnalyticsCardOrganism title="Gender Demographics" class="col-gender">
        <template #body>
          <PieChart :chartData="chartData" :chartOptions="chartOptions" />
        </template>
      </AnalyticsCardOrganism>
      <AnalyticsCardOrganism title="Children Age Demographics">
        <template #body>
          <BarChart
            v-if="analyticsData?.kidsAgeDemographics"
            :data="analyticsData?.kidsAgeDemographics"
            :bar-thickness="30"
            bar-color="#B471F8"
            orientation="horizontal"
            x-axis-title="Amount"
          />
        </template>
      </AnalyticsCardOrganism>
      <AnalyticsCardOrganism title="How many kids parents have">
        <template #body>
          <BarChart
            v-if="analyticsData?.kidsNumberDemographics"
            :data="analyticsData?.kidsNumberDemographics"
            :bar-thickness="30"
            bar-color="#87DFF2"
            orientation="horizontal"
          />
        </template>
      </AnalyticsCardOrganism>
    </div>
  </div>
</template>

<script setup lang="ts">
  import PieChart from '../charts/PieChart.vue';
  import BarChart from '../charts/BarChart.vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { ChartData, ChartOptions } from 'chart.js';

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  const chartData = computed<ChartData<'pie'>>(() => {
    const gender = analyticsData.value.genderDemographics || [];

    const maleData = gender[0]?.data || 0;
    const femaleData = gender[1]?.data || 0;

    return {
      labels: [gender[0]?.label || 'Unknown', gender[1]?.label || 'Unknown'],
      datasets: [
        {
          data: [maleData, femaleData],
          backgroundColor: ['#1C3D92', '#7AD1DC'],
          borderColor: ['#1C3D92', '#7AD1DC'],
          borderWidth: 1,
        },
      ],
    };
  });

  const chartOptions: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
  };
</script>

<style scoped src="./style.css" />
