<script setup lang="ts">
  import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  const date = ref<Date>(new Date());
  const isDateVisible = ref(false);

  const store = useStore();
  const notification = computed(
    () => store.getters['notification/getNotification'],
  );

  const selectedTime = ref('09:00');
  const selectedRepeat = ref('Does not repeat');
  const selectedFrequency = ref('Once');
  const router = useRouter();
  const timeOptions: string[] = [];
  const startTime = 9;
  const endTime = 17;

  for (let hour = startTime; hour <= endTime; hour++) {
    timeOptions.push(`${hour.toString().padStart(2, '0')}:00`);
    timeOptions.push(`${hour.toString().padStart(2, '0')}:30`);
  }
  const repeatOptions = ['Does not repeat', 'Daily', 'Weekly', 'Monthly'];
  const frequencyOptions = ['Once', 'Custom'];

  const isDropdownOpen = ref({
    time: false,
    repeat: false,
    frequency: false,
  });

  const closeOptions = () => {
    isDropdownOpen.value = {
      time: false,
      repeat: false,
      frequency: false,
    };
  };

  const toggleDropdown = (field) => {
    const isCurrentlyOpen = isDropdownOpen.value[field];
    isDropdownOpen.value = {
      time: false,
      repeat: false,
      frequency: false,
    };
    isDropdownOpen.value[field] = !isCurrentlyOpen;

    if (isDropdownOpen.value[field]) {
      document.addEventListener('click', handleOutsideClick, true); // Capture phase
    } else {
      document.removeEventListener('click', handleOutsideClick, true);
    }
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dropdown')) {
      closeOptions();
      document.removeEventListener('click', handleOutsideClick, true);
    }
  };

  const selectTime = (option) => {
    selectedTime.value = option;
    isDropdownOpen.value.time = false;
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const selectRepeat = (option) => {
    selectedRepeat.value = option;
    isDropdownOpen.value.repeat = false;
    if (option === 'Does not repeat') {
      selectedFrequency.value = 'Once';
    } else {
      selectedFrequency.value = 'Custom';
    }
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const selectFrequency = (option) => {
    selectedFrequency.value = option;
    isDropdownOpen.value.frequency = false;
    document.removeEventListener('click', handleOutsideClick, true);
  };

  const props = defineProps({
    show: Boolean,
  });

  const scheduleNotification = async () => {
    const scheduleDate = new Date(date.value);
    const [hours, minutes] = selectedTime.value.split(':').map(Number);
    scheduleDate.setHours(hours, minutes);
    store.commit('notification/SET_NOTIFICATION', {
      ...notification.value,
      status: 'scheduled',
      schedule_at: scheduleDate,
      repeat_type:
        selectedRepeat.value === 'Does not repeat'
          ? 'NONE'
          : selectedRepeat.value.toUpperCase(),
    });

    await nextTick();

    store.dispatch('notification/createItem', notification.value).then(() => {
      closeModal();
      router.back();
    });
  };

  const emits = defineEmits(['update:show']);

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
    document.removeEventListener('click', handleOutsideClick, true);
  });
</script>

<template>
  <div
    v-if="props.show"
    class="schedule-notification-modal-overlay"
    @click="
      closeModal();
      closeOptions();
    "
  >
    <div class="schedule-notification-modal" @click.stop="closeOptions">
      <h3 class="schedule-notification-title">Schedule notification</h3>
      <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />

      <div class="schedule-details">
        <!-- Date Field -->
        <div class="field date">
          <label for="date">Date</label>
          <VueDatePicker
            v-model="date"
            type="date"
            month-name-format="long"
            :enable-time-picker="false"
            :hide-input-icon="true"
            :auto-apply="true"
            placeholder="Select date"
            :format="'dd/MM/yyyy'"
            class="date-input"
            @open="isDateVisible = true"
            @closed="isDateVisible = false"
          />
          <IconAtom
            :icon="isDateVisible ? 'angleUp' : 'angleDown'"
            class="date-icon"
          />
        </div>

        <!-- Time Field -->
        <div class="field time">
          <label for="time">Time</label>
          <div class="dropdown" @click.stop>
            <div class="dropdown-toggle" @click="toggleDropdown('time')">
              <span>{{ selectedTime }}</span>
              <IconAtom
                :icon="isDropdownOpen.time ? 'angleUp' : 'angleDown'"
                class="dropdown-icon"
              />
            </div>
            <ul v-if="isDropdownOpen.time" class="dropdown-menu">
              <li
                v-for="option in timeOptions"
                :key="option"
                @click="selectTime(option)"
              >
                {{ option }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Repeat Field -->
        <div class="field repeat">
          <label for="repeat">Repeat</label>
          <div class="dropdown" @click.stop>
            <div class="dropdown-toggle" @click="toggleDropdown('repeat')">
              <span>{{ selectedRepeat }}</span>
              <IconAtom
                :icon="isDropdownOpen.repeat ? 'angleUp' : 'angleDown'"
                class="dropdown-icon"
              />
            </div>
            <ul v-if="isDropdownOpen.repeat" class="dropdown-menu">
              <li
                v-for="option in repeatOptions"
                :key="option"
                @click="selectRepeat(option)"
              >
                {{ option }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Frequency Field -->
        <div class="field frequency">
          <label for="frequency">Frequency</label>
          <div class="dropdown" @click.stop>
            <div class="dropdown-toggle">
              <span>{{ selectedFrequency }}</span>
              <IconAtom
                :icon="isDropdownOpen.frequency ? 'angleUp' : 'angleDown'"
                class="dropdown-icon"
              />
            </div>
            <ul v-if="isDropdownOpen.frequency" class="dropdown-menu">
              <li
                v-for="option in frequencyOptions"
                :key="option"
                @click="selectFrequency(option)"
              >
                {{ option }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="buttons-section">
        <ButtonAtom class="btn btn-secondary" @click="closeModal">
          Cancel
        </ButtonAtom>
        <ButtonAtom @click="scheduleNotification" class="btn btn-primary"
          >Schedule Notification</ButtonAtom
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
  .schedule-notification-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .schedule-notification-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 8px;
  }

  .schedule-notification-title {
    margin-bottom: 12px;
    font-weight: 800;
    font-size: 24px;
    line-height: 31.2px;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .schedule-details {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 16px;
  }

  .field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .date,
  .repeat {
    width: 250px;
  }

  .time,
  .frequency {
    width: 170px;
  }

  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 19.6px;
  }

  .input {
    width: 100%;
    border: none;
    margin: 12px 0;
    background-color: var(--color-gray-30);
    padding: 12px 16px;
    border-radius: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  .buttons-section {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
  }

  .btn {
    padding: 12px 24px;
  }

  /* CALENDAR STYLE */

  :deep(.dp__theme_light) {
    --dp-primary-color: var(--color-black) !important;
    --dp-primary-disabled-color: #6b6b6b !important;
    --dp-primary-text-color: white !important;
    --dp-hover-color: white !important;
    --dp-hover-text-color: var(--color-black) !important;
    --dp-border-color-hover: var(--color-black) !important;
    --dp-border-color-focus: var(--color-black) !important;
    --dp-highlight-color: rgba(0, 0, 0, 0.2) !important;
    --dp-cell-border-radius: 50%;
    --dp-border-color: none;
    --dp-menu-border-color: none;
    --dp-menu-min-width: 250px;
    --dp-border-radius: 16px !important;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  :deep(.dp__input_wrap) {
    box-shadow: 0;
  }

  :deep(.dp__input) {
    background-color: var(--color-gray-30) !important;
    color: var(--color-black) !important;
    border: none !important;
    border-radius: 16px;
    margin: 12px 0;
    padding: 12px 16px;
  }

  :deep(.dp__input::placeholder) {
    color: var(--color-black) !important;
    opacity: 1 !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22.4px !important;
  }

  :deep(.dp__arrow_top) {
    display: none !important;
  }

  :deep(.dp__outer_menu_wrap) {
    top: 62px !important;
    left: 0px !important;
    width: 250px !important;
  }

  :deep(.dp__instance_calendar) {
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  :deep(.dp__month_year_wrap) {
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19.6px;
    text-align: center;
  }

  :deep(.dp__month_year_select) {
    width: fit-content;
  }

  :deep(.dp__month_year_select) {
    background-color: white;
    color: var(--color-black);
  }

  :deep(.dp__inner_nav) {
    color: var(--color-black);
  }

  :deep(.dp__inner_nav) {
    color: var(--color-black);
  }

  :deep(.dp__inner_nav:hover) {
    background-color: white;
  }

  :deep(.dp__calendar_header) {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-gray-500);
    height: 40px;
    align-items: flex-end; /* This centers the text vertically */
  }

  :deep(.dp__calendar_header_item) {
    height: fit-content;
  }

  :deep(.dp__cell_inner) {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    width: 24px;
    padding: 3px 8px;
  }

  :deep(.dp__cell_inner:hover) {
    background-color: var(--color-black);
    color: white;
  }

  :deep(.dp__calendar_row) {
    padding: 4px;
  }

  :deep(.dp--clear-btn) {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--dp-icon-color);
    background: rgba(0, 0, 0, 0);
    border: none;
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  /* .datePicker-wrapper {
    width: 250px;
    position: relative;
  } */

  .field {
    position: relative;
  }

  .date-icon {
    position: absolute;
    right: 5%;
    top: 55%;
    pointer-events: none;
  }

  .dropdown {
    position: relative;
    width: 100%;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-30);
    padding: 12px 16px;
    border-radius: 16px;
    cursor: pointer;
    margin: 12px 0;
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + -10px);
    left: 0;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 100;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .dropdown-menu li {
    padding: 12px 16px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid var(--color-gray-100);
  }

  .dropdown-menu li:last-child {
    border-bottom: none;
  }

  .dropdown-menu li:hover {
    background-color: var(--color-gray-30);
  }

  .dropdown-icon {
    pointer-events: none;
  }

  .field {
    position: relative;
  }
</style>
