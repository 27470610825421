<script setup lang="ts">
  import NotificationFormMolecule from '@/components/molecules/notification-form-molecule/NotificationFormMolecule.vue';
  import { computed, nextTick, ref, watch } from 'vue';
  import { useStore } from 'vuex';

  const props = defineProps<{ initialDetails: any }>();

  const emit = defineEmits(['update:notificationDetails']);

  let isUpdating = false;

  const store = useStore();

  const notification = computed(
    () => store.getters['notification/getNotification'],
  );
  const localDetails = ref({ ...props.initialDetails });

  watch(
    notification,
    (newValue) => {
      if (!isUpdating && newValue) {
        localDetails.value = { ...newValue };
      }
    },
    { immediate: true, deep: true },
  );

  const updateDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('notification/SET_NOTIFICATION', updatedDetails);

    emit('update:notificationDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };
</script>

<template>
  <div class="wrapper">
    <NotificationFormMolecule
      :details="localDetails"
      @updateDetails="updateDetails"
    />
  </div>
</template>

<style scoped>
  .wrapper {
    width: 90%;
    margin: auto;
    padding: 10px 20px;
  }
</style>
