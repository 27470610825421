<script setup lang="ts">
  import { defineProps, ref } from 'vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import LinkAtom from '@/components/atoms/LinkAtom.vue';
  import TopbarMolecule from '@/components/molecules/topbar-molecule/TopbarMolecule.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  type TopbarOrganismProps = {
    title?: string;
    onSecondaryClick?: () => void;
    onPrimaryClick?: () => void;
    onButtonOneClick?: () => void;
    onButtonTwoClick?: () => void;
    secondaryButtonText?: string;
    primaryButtonText?: string;
    modalButtonOne?: string;
    modalButtonTwo?: string;
    buttonLabel?: string;
    showIcon?: boolean;
    linkText?: string;
    icon?: string;
  };

  const handleClick = (event: Event) => {
    event.stopPropagation();
    isOpen.value = !isOpen.value;
  };

  const isOpen = ref(false);

  const props = defineProps<TopbarOrganismProps>();
</script>

<template>
  <div class="topbar">
    <div
      class="topbar-section clickable"
      @click="router.back()"
      v-if="props.showIcon"
    >
      <IconAtom :icon="props.icon || 'angleLeft'" color="black" />
      <LinkAtom v-if="props.linkText" :text="props.linkText" />
    </div>
    <div class="topbar-section clickable">
      <h1 v-if="props.title">{{ props.title }}</h1>
    </div>

    <div class="topbar-section">
      <div class="topbar-buttons">
        <ButtonAtom
          v-if="props.secondaryButtonText"
          @click="props.onSecondaryClick"
          class="btn btn-secondary"
        >
          {{ props.secondaryButtonText }}
        </ButtonAtom>
        <div style="position: relative">
          <ButtonAtom
            v-if="props.primaryButtonText"
            @click="props.onPrimaryClick"
            class="btn btn-primary"
          >
            {{ props.primaryButtonText }}
            <IconAtom
              v-if="props.modalButtonOne || props.modalButtonTwo"
              :icon="isOpen ? 'angleUp' : 'angleDown'"
              color="white"
              class="toggle-icon"
              @click="handleClick"
            />
          </ButtonAtom>
          <div
            v-if="isOpen"
            class="modal-action-container"
            ref="userActionModalRef"
          >
            <span class="modal-action" @click="props.onButtonOneClick">{{
              props.modalButtonOne
            }}</span>
            <span class="modal-action" @click="props.onButtonTwoClick"
              >{{ props.modalButtonTwo }}
            </span>
          </div>
        </div>
      </div>
      <TopbarMolecule />
    </div>
  </div>
</template>

<style scoped src="./TopbarOrganism.css">
  .toggle-icon {
    position: absolute;
    z-index: 50;
  }
</style>
