<script setup lang="ts">
  import commentsIcon from '@/assets/svg/commentsIcon.vue';
  import shareIcon from '@/assets/svg/shareIcon.vue';
  import { computed } from 'vue';
  import { defineEmits } from 'vue';
  import { useStore } from 'vuex';

  const emit = defineEmits(['open']);

  const props = defineProps({
    resource_id: String,
  });
  const store = useStore();
  const comments = computed(() => store.getters['comment/getCount']);

  const handleClick = () => {
    emit('open');
  };
</script>

<template>
  <div class="view-comments-container">
    <div class="comments comments-icon" @click="handleClick">
      <commentsIcon />
      <h4>View post comments ({{ comments?.toString() ?? 0 }})</h4>
    </div>
    <div class="share">
      <shareIcon color="grey" class="comments-icon" />
    </div>
  </div>
</template>

<style scoped>
  .view-comments-container {
    background-color: white;
    margin-bottom: 16px;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .comments {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .comments-icon {
    cursor: pointer;
  }
</style>
