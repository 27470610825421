import APIService, { AuthData } from "../api.service";

class AuthService {
  public async login(username: string, password: string): Promise<AuthData> {
    const loginResponse: any = await APIService.post<AuthData>("/auth/login", {
      username,
      password,
    });
    const authData = loginResponse.data;

    if (authData.access_token) {
      APIService.setAuthHeaders(authData.access_token);
      APIService.storeAuthData(authData);
    }

    return authData;
  }
}

export default new AuthService();
