<script setup lang="ts">
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TagEditorOrganism from '@/components/organisms/tag-editor-organism/TagEditorOrganism.vue';
  import { useStore } from 'vuex';
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import Tag from '@/models/tag/tag.model';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import { AdditionalParams } from '@/shared/filterParams';
  import ResourceModalMolecule from '@/components/molecules/resouce-modal-molecule/ResourceModalMolecule.vue';

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const tag = computed(() => store.getters['tag/getTag']);

  const loading = ref(true);
  const showResourcesModal = ref(false);
  const tableKey = ref(0);
  const tag_id = ref('');

  let primaryButtonText;
  let primaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      tag_id.value = route.query.id.toString();
      store.dispatch('tag/fetchItemDetails', tag_id.value).then(() => {
        loading.value = false;
      });

      primaryButtonText = 'Save';
    } else {
      store.commit('tag/SET_TAG', new Tag({}));
      loading.value = false;
      primaryButtonText = 'Publish';
    }
    primaryButtonHandler = publish;
  });

  const publish = async () => {
    store.dispatch('tag/createItem', tag.value).then(() => {
      router.back();
    });
  };

  const openResourcesModal = () => {
    showResourcesModal.value = true;
  };

  const closeResourcesModal = () => {
    showResourcesModal.value = false;
    store.commit('resource/CLEAR_NOT_CONNECTED');
    tableKey.value += 1;
  };

  onBeforeUnmount(() => {
    store.commit('tag/CLEAR_TAG');
    store.commit('resource/CLEAR_CONNECTED_RESOURCE');
    store.commit('resource/CLEAR_NOT_CONNECTED');
  });
</script>

<template>
  <div class="tags-detail">
    <TopbarOrganism
      linkText="Back to tags"
      :showIcon="true"
      icon="angleLeft"
      :primaryButtonText="primaryButtonText"
      route="/tagsList"
      :onPrimaryClick="primaryButtonHandler"
    />
    <div v-if="!loading">
      <TagEditorOrganism :initialDetails="tag" />
      <TableMolecule
        dataType="ConnectedResources"
        :key="tableKey"
        :dynamicAdditionalFilter="
          new AdditionalParams('tags', 'tag_id', tag_id, 'some')
        "
        :onActionClick="openResourcesModal"
      />
    </div>
    <p v-else>Loading...</p>
  </div>
  <ResourceModalMolecule v-if="showResourcesModal" @click="closeResourcesModal">
    <TableMolecule
      dataType="NotConnectedResources"
      :dynamicAdditionalFilter="
        new AdditionalParams('tags', 'tag_id', tag_id, 'none')
      "
    />
  </ResourceModalMolecule>
</template>

<style scoped src=""></style>
