import { FilterParams, SearchParamsAND } from '@/shared/filterParams';
import Tag from '@/models/tag/tag.model';
import collectionService from '@/services/collection/collection.service';
import Collection from '@/models/collection/collection.model';
import { transformToSelectOptions } from '@/shared/selectOption';
import groupService from '@/services/group/group.service';

const collectionModule = {
  namespaced: true,
  state: {
    collection: null,
    items: [],
    totalPages: 1,
    collection_tags: [],
    counts: { all: 0, draft: 0, published: 0, unpublished: 0 },
  },
  mutations: {
    SET_COLLECTION(state, collection) {
      state.collection = collection;
      if (!state.collection_tags || state.collection_tags.length < 1) {
        state.collection_tags =
          transformToSelectOptions(
            collection.collectionTag?.map((collectionTag) => collectionTag.tag),
            'tag_id',
            'tag_name',
          ) || [];
      }
      if (collection.resources) {
        state.resources = collection.resources.flatMap((it) => it.resource);
      }
    },
    SET_ITEMS(state, data) {
      state.items = data.data;
      state.totalPages = data.totalPages;
      state.counts = { ...data.counts.status };
    },
    ADD_TAG(state, tag) {
      state.collection_tags.push(tag);
    },
    REMOVE_TAG(state, tag) {
      state.collection_tags = state.collection_tags.filter(
        (t) => t.id !== tag.id,
      );
      if (state.collection_tags.length === 0) {
        state.collection_tags.splice(0);
      }
    },
    CLEAR_COLLECTION(state) {
      state.collection = null;
      state.collection_tags = [];
    },
  },
  actions: {
    async fetchCollections({ commit }, params: FilterParams) {
      const data = await collectionService.getAll(params);

      commit('SET_ITEMS', data);
    },
    async fetchItemDetails({ commit }, id: string) {
      const details: any = await collectionService.getById(id);
      commit('SET_COLLECTION', details.data);
    },

    async deleteItems({ dispatch }, payload: { data: string[] }) {
      dispatch('loader/showLoader', null, { root: true });
      try {
        await collectionService.deleteMultiple({
          collection_ids: payload.data,
        });
      } catch (error) {
        console.error('Error deleting groups', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },

    async createItem({ state, dispatch }, payload: Collection) {
      dispatch('loader/showLoader', null, { root: true });
      const formData = new FormData();

      formData.append('collection_name', payload.collection_name);
      formData.append('status', payload.status);
      formData.append('is_published', payload.is_published.toString());
      formData.append('is_featured', payload.is_featured.toString());

      if (state.collection_tags.length > 0) {
        const tagIds = state.collection_tags.map((tag) => tag.id);
        formData.append('tag_ids', tagIds.join(','));
      }

      if (payload.banner && typeof payload.banner == 'object') {
        const file = payload.banner as any;
        const fileName = file.name.replace(/\s+/g, '_');
        formData.append('bannerFile', file, fileName);
      } else if (payload.banner != '') {
        formData.append('banner', payload.banner);
      }

      try {
        if (payload.collection_id) {
          await collectionService.update(payload.collection_id, formData);
        } else {
          await collectionService.create(formData);
        }
      } catch (error) {
        console.error('Error creating resource', error);
        dispatch('loader/hideLoader', null, { root: true });
      }
      dispatch('loader/hideLoader', null, { root: true });
    },
    async updateItem({ dispatch }, payload: Tag) {
      // await ResourceService.update(payload);
      // dispatch("fetchItemDetails", payload.resource_id);
    },
    addTag({ commit }, tag) {
      commit('ADD_TAG', tag);
    },
    removeTag({ commit }, tag) {
      commit('REMOVE_TAG', tag);
    },

    async publishItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      const filters = structuredClone(data.filters);
      await collectionService.publish(data.id);
      filters.searchParamsAND?.push(
        new SearchParamsAND('draft', 'status', 'equals'),
      );
      dispatch('fetchCollections', filters);
    },
    async restoreItem(
      { dispatch },
      data: { id: string; filters: FilterParams },
    ) {
      const filters = structuredClone(data.filters);
      await collectionService.restore(data.id);
      filters?.searchParamsAND?.push(
        new SearchParamsAND('unpublished', 'status', 'equals'),
      );
      dispatch('fetchCollections', filters);
    },
  },
  getters: {
    getCollectionCounts(state) {
      return state.counts;
    },
    getCollectionTags(state) {
      return state.collection_tags;
    },
    getCollection(state) {
      return state.collection;
    },
    getItems(state) {
      return state.items;
    },
    getTotalPages(state) {
      return state.totalPages;
    },
  },
};

export default collectionModule;
