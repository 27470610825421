<script setup lang="ts">
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import userImgSrc from '@/assets/user-default.png';
  import UserImgAtom from '@/components/atoms/UserImgAtom.vue';

  const emit = defineEmits(['close', 'change-password']);
  const store = useStore();

  const user = computed(() => store.getters['user/getUser']);

  const actions = [
    { icon: 'lockIcon', label: 'Change password', type: 'changePassword' },
    { icon: 'signOutIcon', label: 'Sign out', type: 'signOut' },
  ];

  const onActionClick = (action: string): void => {
    if (action === 'signOut') {
      store.dispatch('user/signOut');
      emit('close');
    } else if (action === 'changePassword') {
      emit('change-password');
      emit('close');
    }
  };
</script>

<template>
  <div class="sign-out-action-container" @click.stop>
    <div class="personal-details">
      <UserImgAtom class="image-detail" :src="userImgSrc" />
      <div class="name-email-details">
        <p class="name-detail"></p>
        <p class="email-detail"></p>
      </div>
    </div>
    <span
      v-for="(action, index) in actions"
      :key="index"
      class="action"
      @click="onActionClick(action.type)"
    >
      <IconAtom
        :icon="action.icon"
        class="action-icon"
        :aria-label="action.label"
      />
      {{ action.label }}
    </span>
  </div>
</template>

<style scoped>
  .sign-out-action-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    /* padding: 5px 10px; */
    background-color: #fff;
    border-radius: 10%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    top: 70px;
    right: 5%;
    z-index: 1;
  }

  .action {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .action:hover {
    background-color: #f1f1f1;
  }

  .action:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e0e0e0;
  }

  .action-icon {
    width: 20px;
    height: 20px;
  }

  .personal-details {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    gap: 8px;
    border-bottom: 1px solid #e0e0e0;
  }

  .image-detail {
    width: 40px;
    height: 40px;
  }

  .name-email-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }

  .email-detail {
    font-size: 12px;
    font-weight: 500;
    color: #83868e;
  }
</style>
