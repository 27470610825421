import Discussion from '@/models/discussion/discussion.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class DiscussionService {
  public async getAll(): Promise<Discussion[]> {
    return APIService.get<Discussion[]>(`/discussion`);
  }

  public async getById(id: string): Promise<Discussion> {
    return APIService.get<Discussion>(`/discussion/${id}`);
  }

  public async getByGroup(
    id: string,
    params: FilterParams,
  ): Promise<Discussion[]> {
    return APIService.post<Discussion[]>(
      `/discussions/group
    /${id}`,
      params,
    );
  }

  public async create(data: Discussion): Promise<Discussion> {
    return APIService.post<Discussion>(`/discussions`, data);
  }

  public async update(data: Discussion): Promise<Discussion> {
    return APIService.put(`/discussion/update/${data.discussion_id}`, data);
  }

  public async delete(id: string): Promise<any> {
    return APIService.delete(`/discussion/${id}`);
  }
}

export default new DiscussionService();
