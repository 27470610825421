<script setup lang="ts">
  import {
    onMounted,
    onUnmounted,
    defineEmits,
    computed,
    ref,
    watch,
  } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import { useStore } from 'vuex';

  type UserActionsModal = {
    title: string;
    message?: string;
    buttonPrimaryText: string;
    buttonSecondaryText: string;
  };
  const props = defineProps({
    show: Boolean,
    action: String,
  });

  const store = useStore();
  const selectedUser = computed(() => store.getters['user/getSelectedUser']);
  const emits = defineEmits(['update:show', 'dispatch:action']);

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const modalContext = ref<UserActionsModal>({
    title: '',
    message: '',
    buttonPrimaryText: '',
    buttonSecondaryText: '',
  });

  const handleAction = () => {
    emits('dispatch:action');
    closeModal();
  };

  const updateModalContext = () => {
    switch (props.action) {
      case 'delete':
        modalContext.value.title = `Are you sure you want to delete ${selectedUser.value.full_name}'s account?`;
        modalContext.value.message =
          'Account cannot be restored, only created again';
        modalContext.value.buttonPrimaryText = 'Yes, delete account';
        modalContext.value.buttonSecondaryText = 'No, keep account';
        break;

      case 'passwordReset':
        modalContext.value.title = `Do you want to send a password reset to ${selectedUser.value.name}?`;
        modalContext.value.message =
          'User will be able to reset their password';
        modalContext.value.buttonPrimaryText = 'Yes, send';
        modalContext.value.buttonSecondaryText = 'No';
        break;

      case 'ban':
        modalContext.value.title = `Are you sure you want to ban ${selectedUser.value.name} from all communities they joined?`;
        modalContext.value.message = '';
        modalContext.value.buttonPrimaryText = 'Yes, ban from community';
        modalContext.value.buttonSecondaryText = 'No';
        break;

      case 'notification':
        modalContext.value.title = `Are you sure you want to ban ${selectedUser.value.name} from all communities they joined?`;
        modalContext.value.message = '';
        modalContext.value.buttonSecondaryText = 'Cancel';
        modalContext.value.buttonPrimaryText = 'Send personal notification';
        break;

      default:
        modalContext.value.title = '';
        modalContext.value.message = '';
        modalContext.value.buttonPrimaryText = '';
        modalContext.value.buttonSecondaryText = '';
        break;
    }
  };

  watch(() => props.action, updateModalContext, { immediate: true });

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div v-if="show" class="delete-modal-overlay" @click="closeModal">
    <div class="delete-modal" @click.stop>
      <div class="text-section">
        <h2>{{ modalContext.title }}</h2>
        <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
        <p v-if="modalContext.message">{{ modalContext.message }}</p>
      </div>
      <div class="buttons-section">
        <ButtonAtom class="btn btn-primary" @click="handleAction">
          <p>{{ modalContext.buttonPrimaryText }}</p>
        </ButtonAtom>
        <ButtonAtom class="btn btn-secondary" @click="closeModal">
          <p>{{ modalContext.buttonSecondaryText }}</p>
        </ButtonAtom>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 470px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 32px;
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .text-section,
  .buttons-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .text-section {
    width: 90%;
  }

  .text-section h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  .text-section p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .btn p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
</style>
