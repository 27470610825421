<script setup>
  import { computed, defineAsyncComponent } from 'vue';

  const props = defineProps({
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  });

  const dynamicComponent = computed(() => {
    const icon = props.icon;
    return defineAsyncComponent(() => import(`../../assets/svg/${icon}.vue`));
  });
</script>

<template>
  <component :is="dynamicComponent" :style="{ color: props.color }" />
</template>
