<script setup lang="ts">
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';

  const dataType = 'Notification';
</script>

<template>
  <div class="Audio-container">
    <TopbarOrganism title="Push notifications" />
    <TableMolecule :dataType="dataType" />
  </div>
</template>

<style scoped></style>
