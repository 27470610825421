import Group from '@/models/group/group.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class GroupService {
  public async getAll(params: FilterParams): Promise<Group[]> {
    return APIService.post<Group[]>(`/groups/cms/search`, params);
  }
  public async getById(id: string): Promise<Group> {
    return APIService.get<Group>(`/groups/${id}`);
  }
  public async deleteMultiple(data: any): Promise<Group[]> {
    return APIService.post(`/groups/delete/multiple`, data);
  }

  public async create(data: any): Promise<Group> {
    return APIService.post<Group>(`/groups`, data);
  }

  public async update(id: string, data: any): Promise<Group> {
    return APIService.post(`/groups/update/${id}`, data);
  }

  public async restore(id: string): Promise<Group> {
    return APIService.post(`/groups/restore/${id}`, {});
  }

  public async publish(id: string): Promise<Group> {
    return APIService.post(`/groups/publish/${id}`, {});
  }
}

export default new GroupService();
