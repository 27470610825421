<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, watch, nextTick } from "vue";
import ArticleEditorMolecule from "@/components/molecules/article-editor-molecules/ArticleEditorMolecule.vue";
import ArticlePreviewMolecule from "@/components/molecules/article-editor-molecules/ArticlePreviewMolecule.vue";
import { useStore } from "vuex";
import TagEditorMolecule from "@/components/molecules/tag-editor-molecules/TagEditorMolecule.vue";

const store = useStore();

const props = defineProps<{ initialDetails: any }>();

const details = computed(() => store.getters["tag/getTag"]);
const localDetails = ref({ ...props.initialDetails });

const emit = defineEmits(["update:tagDetails"]);

let isUpdating = false;

watch(
  details,
  (newValue) => {
    if (!isUpdating && newValue) {
      localDetails.value = { ...newValue };
    }
  },
  { immediate: true, deep: true }
);

const updateDetails = (updatedDetails) => {
  if (isUpdating) return;
  isUpdating = true;

  store.commit("tag/SET_TAG", updatedDetails);

  emit("update:tagDetails", updatedDetails);

  nextTick(() => {
    isUpdating = false;
  });
};
</script>

<template>
  <div class="article-wrapper">
    <div class="article-editor">
      <TagEditorMolecule
        :details="localDetails"
        @updateDetails="updateDetails"
      />
    </div>
  </div>
</template>

<style scoped src="./TagEditorOrganism.css"></style>
