<script setup lang="ts">
  import { ref, onMounted, onUnmounted, defineEmits } from 'vue';
  import InputAtom from './InputAtom.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import store from '@/store';

  const props = defineProps({
    show: Boolean,
  });

  const newTagName = ref('');
  const emits = defineEmits([
    'newTagAdded',
    'closeModal',
    'update:show',
    'addTagToSelection',
  ]);

  async function handleAdd() {
    if (newTagName.value.trim() !== '') {
      const newTag = await store.dispatch('tag/createItem', {
        tag_name: newTagName.value,
        tag_id: null,
      });

      if (newTag) {
        emits('addTagToSelection', {
          tag_id: newTag.tag_id,
          tag_name: newTag.tag_name,
        });
      }
    }
    newTagName.value = '';
    emits('newTagAdded');
  }

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div v-if="props.show" class="new-tag-modal-overlay" @click="closeModal">
    <div class="new-tag-modal" @click.stop>
      <h3 class="newTagTitle">Add new tag</h3>
      <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
      <label for="newTagInput">Name</label>
      <InputAtom
        id="newTagInput"
        v-model="newTagName"
        placeholder="Name"
        class="input"
      />
      <ButtonAtom class="btn btn-primary" @click="handleAdd">Add</ButtonAtom>
    </div>
  </div>
</template>

<style scoped>
  .new-tag-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .new-tag-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .newTagTitle {
    margin-bottom: 24px;
    font-size: bold;
  }

  .input {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    margin: 12px 0;
  }
  .btn {
    margin: auto;
    padding: 8px 32px;
  }
</style>
