<script setup lang="ts">
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import tagService from '@/services/tag/tag.service';
  import { SearchbarModalOptions } from '@/shared/searchbarModalOptions';
  import { onMounted, ref } from 'vue';

  const dataType = 'Video';
  const filterOptions = ref<SearchbarModalOptions[]>([]);

  onMounted(async () => {
    try {
      const allTags: any = await tagService.getAllNoFilter();
      const tagFilterOptions = allTags.data
        .filter((tag) => tag.resource_count > 0)
        .map((tag) => {
          return new SearchbarModalOptions(
            `tags.tag.tag_name:${tag.tag_name}`,
            tag.tag_name,
          );
        });
      filterOptions.value = [...tagFilterOptions];
    } catch (error) {
      console.error('Failed to load tags:', error);
    }
  });
</script>

<template>
  <div class="Video-container">
    <TopbarOrganism title="Videos" />
    <TableMolecule :dataType="dataType" :filterOptions="filterOptions" />
  </div>
</template>

<style scoped></style>
