import User from '@/models/user/user.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class UserService {
  public async getAll(params: FilterParams): Promise<User[]> {
    return APIService.post<User[]>(`/user/cms/search`, params);
  }

  public async getById(id: string): Promise<User> {
    return APIService.get<User>(`/user/${id}`);
  }

  public async create(data: User): Promise<User> {
    return APIService.post<User>(`/user`, data);
  }

  public async update(data: User): Promise<User> {
    return APIService.put(`/user/update/${data.user_id}`, data);
  }

  public async updateStatus(
    user_id: string,
    user_status: string,
  ): Promise<User> {
    return APIService.put(`/user/status/${user_id}`, { user_status });
  }
}

export default new UserService();
