export class SelectOption {
  constructor(public id: string, public value: string) {}
}

export const transformToSelectOptions = (
  array: any[],
  idProp: string,
  valueProp: string,
): SelectOption[] => {
  if (!Array.isArray(array)) {
    return [];
  }

  return array.map((item) => {
    return { id: item[idProp], value: item[valueProp] };
  });
};
