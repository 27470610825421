<script setup>
  import { ref, computed } from 'vue';
  import { useRoute, RouterLink } from 'vue-router';
  import SidebarMolecule from '@/components/molecules/sidebar-item-molecule/SidebarItemMolecule.vue';
  import LinkAtom from '@/components/atoms/LinkAtom.vue';

  const openItem = ref(null);
  const route = useRoute();

  const activeItem = computed(() => {
    const mainPath = route.path.split('/')[1];
    return mainPath ? `/${mainPath}` : '/';
  });

  const isActiveSubItem = (path) => {
    return route.path === path;
  };

  const toggleItem = (item) => {
    if (openItem.value !== item) {
      openItem.value = item;
    } else {
      openItem.value = null;
    }
  };

  const itemMainProp = (item) => {
    return {
      isOpen: openItem.value === item,
      isActive:
        activeItem.value === item || (item === '' && activeItem.value === '/'),
      onToggle: () => toggleItem(item),
    };
  };

  const resources = [
    { text: 'Articles', path: '/articlesList' },
    { text: 'Videos', path: '/videosList' },
    { text: 'Audio', path: '/audiosList' },
    { text: 'Collections', path: '/collectionsList' },
    { text: 'Tags', path: '/tagsList' },
  ];
</script>

<template>
  <div class="sidebar">
    <h1 class="logo-text">Bryan Post</h1>

    <RouterLink to="/" class="link">
      <SidebarMolecule
        text="Overview"
        icon="overviewIcon"
        v-bind="itemMainProp('')"
      />
    </RouterLink>

    <SidebarMolecule
      text="Resources"
      icon="resourcesIcon"
      v-bind="itemMainProp('/resources')"
    >
      <RouterLink
        v-for="resource in resources"
        :key="resource.path"
        :to="resource.path"
        class="sidebar-subitem"
        :class="{ active: isActiveSubItem(resource.path) }"
      >
        <LinkAtom :text="resource.text" />
      </RouterLink>
    </SidebarMolecule>

    <RouterLink to="/groupsList" class="link">
      <SidebarMolecule
        text="Groups"
        icon="groupsIcon"
        v-bind="itemMainProp('/groupsList')"
      />
    </RouterLink>

    <RouterLink to="/usersList" class="link">
      <SidebarMolecule
        text="Users"
        icon="usersIcon"
        v-bind="itemMainProp('/usersList')"
      />
    </RouterLink>

    <RouterLink to="/notificationList" class="link">
      <SidebarMolecule
        text="Notifications"
        icon="notification-bing"
        v-bind="itemMainProp('/notificationList')"
      />
    </RouterLink>
  </div>
</template>

<style Scoped src="./SidebarOrganism.css"></style>
