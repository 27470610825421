<script setup lang="ts">
  import {
    onMounted,
    onUnmounted,
    watch,
    defineProps,
    defineEmits,
    computed,
  } from 'vue';
  import SearchbarMolecule from '../molecules/searchbar-molecule/SearchbarMolecule.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import Collection from '@/models/collection/collection.model';
  import { useStore } from 'vuex';

  const props = defineProps<{
    id: string;
    show: boolean;
    collections: Collection[];
    actionCollectionsGetter: string;
    actionCollectionSet: string;
    actionCollectionRemove: string;
  }>();

  const emit = defineEmits(['update:show', 'updateCollections']);
  const store = useStore();

  const selectedCollections = computed(
    () => store.getters[props.actionCollectionsGetter],
  );
  const rawSelectedCollections = computed(() => [...selectedCollections.value]);

  watch(
    () => props.show,
    (newShow) => {
      if (newShow) {
        // selectedCollections.value = [];
      }
    },
  );

  const handleCollectionClick = (collection: Collection) => {
    const selected = selectedCollections.value || []; // Ensure it's an array
    if (selected.includes(collection)) {
      store.dispatch(props.actionCollectionRemove, collection);
    } else {
      store.dispatch(props.actionCollectionSet, collection);
    }
  };

  const closeModal = () => {
    emit('update:show', false);
  };

  const addCollections = () => {
    // emit("updateCollections", selectedCollections.value);
    closeModal();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div v-if="props.show" class="collection-modal-overlay" @click="closeModal">
    <div class="collection-modal" @click.stop>
      <h3>Add to Collection</h3>
      <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
      <SearchbarMolecule
        class="border searchbar"
        :showFilter="false"
        :showSort="false"
      />
      <div class="collection-items-container">
        <div
          v-for="collection in props.collections"
          :key="collection.collection_id"
          class="collection-item"
          :class="{
            selected: rawSelectedCollections
              .map((c) => c.collection_id)
              .includes(collection.collection_id),
          }"
          @click="handleCollectionClick(collection)"
        >
          <div class="name-and-img">
            <img
              v-if="collection.banner"
              :src="collection.banner"
              alt="Collection Image"
              class="collection-icon"
            />
            {{ collection.collection_name }}
          </div>
          <IconAtom
            v-if="
              rawSelectedCollections
                .map((c) => c.collection_id)
                .includes(collection.collection_id)
            "
            icon="checkmarkIcon"
          />
        </div>
      </div>
      <ButtonAtom class="btn btn-primary add-btn" @click="addCollections"
        ><p>Add</p></ButtonAtom
      >
    </div>
  </div>
</template>

<style scoped>
  .collection-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .collection-modal {
    padding: 32px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    max-height: 40vh;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .collection-modal h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  .searchbar {
    width: 100%;
    margin-bottom: 16px;
  }

  .collection-items-container {
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
    min-height: 0;
  }

  .collection-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: background-color 0.3s;
    width: 99%;
    text-align: left;
  }

  .collection-item.selected {
    background-color: var(--color-gray-50);
  }

  .add-btn {
    margin-top: 16px;
    align-self: center;
    padding: 8px 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .collection-icon {
    height: 40px;
    width: auto;
    margin-right: 10px;
    border-radius: 6px;
  }

  .name-and-img {
    display: flex;
    align-items: center;
  }
</style>
