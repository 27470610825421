<template>
  <Line
    :data="{
      labels: props.data.map(
        (dataArr) =>
          dataArr[labelKey].charAt(0).toUpperCase() +
          dataArr[labelKey].substring(1),
      ),
      datasets: [
        {
          data: props.data.map((dataSet) => dataSet[dataKey]),
          backgroundColor: 'rgba(29, 67, 166, 0.15)',
          borderColor: '#1D43A6',
          borderWidth: 1,
          fill: {
            target: 'origin',
            below: 'rgba(29, 67, 166, 0.15)',
          },
        },
      ],
    }"
    :options="{
      plugins: {
        legend: {
          display: false,
        },
        filler: {
          propagate: false,
        },
      },
      scales: {
        x: {
          suggestedMax: Math.max(
            ...props.data.map((dataSet) => dataSet[dataKey]),
          ),
          title: { text: xAxisTitle ?? '', display: xAxisTitle !== undefined },
          grid: {
            display: false,
          },
        },
        y: {
          suggestedMax: Math.max(
            ...props.data.map((dataSet) => dataSet[dataKey]),
          ),
          title: {
            text: yAxisTitle ?? '',
            display: yAxisTitle !== undefined,
          },
          grid: {
            display: true,
          },
        },
      },
    }"
  />
</template>

<script setup lang="ts">
  import { Line } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
  } from 'chart.js';

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    Filler,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
  );

  const props = withDefaults(
    defineProps<{
      data: any[];
      xAxisTitle?: string;
      yAxisTitle?: string;
      dataKey?: string;
      labelKey?: string;
      lineColor?: string;
    }>(),
    {
      dataKey: 'data',
      labelKey: 'label',
      lineColor: '#1D43A6',
    },
  );
</script>
