<script setup lang="ts">
  import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
  import commentsIcon from '@/assets/svg/commentsIcon.vue';
  import { defineProps, defineEmits } from 'vue';
  import { SearchParamsAND } from '@/shared/filterParams';
  import TableMolecule from '../table-molecule/TableMolecule.vue';
  import { useStore } from 'vuex';

  const props = defineProps<{
    show: boolean;
    resource_id: string;
  }>();
  const emit = defineEmits(['update:show']);
  const store = useStore();
  const defaultFilters = [
    new SearchParamsAND(props.resource_id, 'resource_id', 'equals'),
  ];
  const comments = computed(() => store.getters['comment/getCount']);

  const closeModal = () => {
    emit('update:show', false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
    // hasComments.value = props.comments.length > 0;
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div v-show="props.show" class="comments-overlay" @click="closeModal">
    <div
      class="comments-content"
      :class="{
        'no-comments': comments,
        'with-comments': comments,
      }"
      @click.stop
    >
      <span class="comments-title">Comments</span>
      <div v-show="comments" class="comments-modal-content">
        <TableMolecule
          dataType="Comment"
          :dynamicDefaultFilter="defaultFilters"
        />
      </div>
      <div v-show="!comments" class="status">
        <div class="icon-wrapper">
          <commentsIcon class="no-comments-icon" />
        </div>
        <h4>This post has no comments yet</h4>
      </div>
    </div>
  </div>
</template>

<style scoped src="./ViewCommentsMolecule.css"></style>
