<template>
  <div class="analytics-grid">
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.daily"
      name="Daily active users"
      icon="analyticsUsers"
    />
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.newUsers"
      name="New users this month"
      icon="analyticsUsers"
    />
    <BaseAnalyticsCardMolecule
      v-bind="analyticsData?.monthly"
      name="Monthly active users"
      icon="analyticsUsers"
    />
    <BaseAnalyticsCardMolecule name="Churn rate" icon="analyticsUsers" />
    <BaseAnalyticsCardMolecule
      name="Installs this month"
      icon="download-icon"
    />
    <BaseAnalyticsCardMolecule
      name="Uninstalls this month"
      icon="download-icon"
    />
    <AnalyticsCardOrganism title="User Growth Rate" class="colspan-2">
      <template #body>
        <LineChart
          v-if="analyticsData?.userGrowthRate"
          :data="analyticsData?.userGrowthRate"
        />
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism title="Retention Rate">
      <template #body>
        <BarChart
          :data="[
            { label: '30d', data: 10 },
            { label: '60d', data: 20 },
            { label: '90d', data: 30 },
          ]"
        />
      </template>
    </AnalyticsCardOrganism>
  </div>
</template>

<script setup lang="ts">
  import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import { computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import PieChart from '../charts/PieChart.vue';
  import AnalyticsCardOrganism from '../analytics-card/AnalyticsCardOrganism.vue';
  import BarChart from '../charts/BarChart.vue';
  import LineChart from '../charts/LineChart.vue';

  const store = useStore();
  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );

  watch(analyticsData, () => {
    console.log(analyticsData.value);
  });
</script>

<style scoped src="./style.css" />
