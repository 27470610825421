<!-- SortModal.vue -->
<template>
  <div
    v-if="isVisible"
    ref="sortModalRef"
    :style="{ position: 'absolute', top: top + 'px', left: left + 'px' }"
    class="sort-modal"
  >
    <span class="sort-modal-title modal-item">
      <p>{{ props.sortModalTitle }}</p>
    </span>
    <!-- Modal content here -->
    <div class="sort-options">
      <p
        v-for="option in props.sortOptions"
        :key="option.key"
        class="modal-item"
      >
        <input
          type="radio"
          class="sort-radio-input"
          :value="option.key"
          v-model="selectedOption"
          @change="emitSelectedOption"
        />
        <label class="sort-radio-label">
          {{ option.value }}
        </label>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SearchbarModalOptions } from "@/shared/searchbarModalOptions";
import { onBeforeUnmount, onMounted, ref } from "vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    top: number;
    left: number;
    sortModalTitle: string;
    sortOptions: SearchbarModalOptions[];
  }>(),
  {
    sortModalTitle: "Sort By:",
  }
);

const modalStyle = ref({
  position: "absolute",
  top: `${props.top}px`,
  left: `${props.left}px`,
});

const emit = defineEmits(["close", "update:sort"]);
const sortModalRef = ref<HTMLElement | null>(null);
const selectedOption = ref<string | null>(null);

const emitSelectedOption = () => {
  emit("update:sort", selectedOption.value);
};

const handleClickOutside = (event: MouseEvent): void => {
  if (
    sortModalRef.value &&
    !sortModalRef.value.contains(event.target as Node) &&
    props.isVisible
  ) {
    emit("close");
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped src="./SortModalMolecule.css"></style>
