<script setup lang="ts">
  import {
    ref,
    defineProps,
    defineEmits,
    computed,
    watch,
    nextTick,
  } from 'vue';
  import ArticleEditorMolecule from '@/components/molecules/article-editor-molecules/ArticleEditorMolecule.vue';
  import ArticlePreviewMolecule from '@/components/molecules/article-editor-molecules/ArticlePreviewMolecule.vue';
  import viewCommentsAtom from '@/components/atoms/viewCommentsAtom.vue';
  import ViewCommentsMolecule from '@/components/molecules/view-comments-molecule/ViewCommentsMolecule.vue';
  import { useStore } from 'vuex';

  const store = useStore();

  const props = defineProps<{ initialResourceDetails: any }>();

  const previewData = ref({
    resource_name: '',
    tags: [],
    text_content: '',
  });
  const showNoCommentsModal = ref(false);

  const coverImage = ref('');

  const resource = computed(() => store.getters['resource/getResource']);
  const localResourceDetails = ref({ ...props.initialResourceDetails });

  const emit = defineEmits(['update:resourceDetails']);

  let isUpdating = false;

  watch(
    resource,
    (newValue) => {
      if (!isUpdating && newValue) {
        localResourceDetails.value = { ...newValue };
        updatePreviewData({ ...newValue });
      }
    },
    { immediate: true, deep: true },
  );

  watch(
    () => store.getters['resource/getResource'],
    (newValue) => {
      if (!isUpdating && newValue) {
        localResourceDetails.value = { ...newValue };
      }
    },
    { immediate: true, deep: true },
  );

  const updateResourceDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('resource/SET_RESOURCE', updatedDetails);

    emit('update:resourceDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };

  const normalizedPreviewData = computed(() => {
    const data = { ...localResourceDetails.value };
    data.tags =
      data.tags?.map((tag) => {
        if (tag.value) {
          return { id: tag.id, value: tag.value };
        } else if (tag.tag_name) {
          return { id: tag.tag_id, value: tag.tag_name };
        } else if (tag.tag && tag.tag.tag_name) {
          return { id: tag.tag.tag_id, value: tag.tag.tag_name };
        } else {
          return { id: '', value: '' };
        }
      }) || [];
    return data;
  });

  function updatePreviewData(newCoverImage) {
    coverImage.value = newCoverImage;
  }

  function openNoCommentsModal() {
    showNoCommentsModal.value = true;
  }
</script>

<template>
  <div class="article-wrapper">
    <div class="article-editor">
      <ArticleEditorMolecule
        :resourceDetails="localResourceDetails"
        @updateResourceDetails="updateResourceDetails"
        @updatePreview="updatePreviewData"
      />
    </div>
    <div class="preview-container">
      <viewCommentsAtom
        @open="openNoCommentsModal"
        :resource_id="localResourceDetails.resource_id"
      />
      <ViewCommentsMolecule
        :show="showNoCommentsModal"
        @update:show="showNoCommentsModal = $event"
        :resource_id="localResourceDetails.resource_id"
      />
      <div class="article-preview">
        <ArticlePreviewMolecule
          :previewData="normalizedPreviewData"
          :coverImage="coverImage"
        />
      </div>
    </div>
  </div>
</template>

<style scoped src="./ArticleEditorOrganism.css"></style>
