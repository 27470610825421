<template>
    <PerformanceAnalyticsOrganism />
</template>

<script setup lang="ts">
import PerformanceAnalyticsOrganism from '@/components/organisms/performance-analytics/PerformanceAnalyticsOrganism.vue';
import store from '@/store';
import { onBeforeMount } from 'vue';
onBeforeMount(() => {
    store.dispatch('analytics/fetchPerformanceAnalytics')
})
</script>