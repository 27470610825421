import { FilterParams } from './filterParams';

const excludedFields = ['created_at', 'updated_at', 'banner', 'url_path'];

export const getStatusCounts = (array: any[], statusField: string): any => {
  const result: any = {};
  array.forEach((item) => {
    result.all = (result.all || 0) + 1;
    result[item[statusField]] = (result[item[statusField]] || 0) + 1;
  });

  return result;
};

export const localArrayFilter = (
  array: any[],
  filter: FilterParams,
  statusField = 'status',
): {
  result: any[];
  resultCount: number;
  totalPages: number;
  counts: {
    all: number;
    published: number;
    draft: number;
    unpublished: number;
  };
} => {
  let resultArray = array;

  let resultForCount = array;

  filter.searchParamsAND?.forEach((filterParam) => {
    resultArray = resultArray.filter((item) => {
      const itemValue = item[filterParam.searchField]?.toString() || '';
      if (filterParam.action === 'contains') {
        return itemValue.includes(filterParam.searchTerm);
      } else {
        return itemValue === filterParam.searchTerm;
      }
    });

    if (filterParam.searchField != statusField) {
      resultForCount = resultForCount.filter((item) => {
        const itemValue = item[filterParam.searchField]?.toString() || '';
        if (filterParam.action === 'contains') {
          return itemValue.includes(filterParam.searchTerm);
        } else {
          return itemValue === filterParam.searchTerm;
        }
      });
    }
  });

  // Handle searchParamsOR filter
  if (filter.searchParamsOR?.searchTerm) {
    resultArray = resultArray.filter((item) => {
      return Object.keys(item).some((key) => {
        if (!excludedFields.includes(key)) {
          const itemValue = item[key]?.toString() || '';
          if (
            itemValue
              .toLowerCase()
              .includes(filter.searchParamsOR?.searchTerm.toLowerCase() || '')
          ) {
            return true;
          }
        }
      });
    });
    resultForCount = resultForCount.filter((item) => {
      return Object.keys(item).some((key) => {
        if (!excludedFields.includes(key)) {
          const itemValue = item[key]?.toString() || '';
          if (
            itemValue
              .toLowerCase()
              .includes(filter.searchParamsOR?.searchTerm.toLowerCase() || '')
          ) {
            return true;
          }
        }
      });
    });
  }

  if (filter.sortParams && filter.sortParams.sortOrder == 'asc') {
    resultArray = resultArray.sort(
      (a, b) =>
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
    );
  } else {
    resultArray = resultArray.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );
  }

  return {
    result: resultArray.slice(
      (filter.page - 1) * filter.pageSize,
      filter.page * filter.pageSize,
    ),
    resultCount: resultArray.length,
    totalPages: resultArray.length / filter.pageSize,
    counts: getStatusCounts(resultForCount, statusField),
  };
};
