<script setup lang="ts">
  import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';
  import GroupsFormOrganism from '@/components/organisms/groups-form-organism/GroupsFormOrganism.vue';
  import CreateDiscussionAtom from '@/components/atoms/CreateDiscussionAtom.vue';

  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Group from '@/models/group/group.model';
  import TableMolecule from '@/components/molecules/table-molecule/TableMolecule.vue';
  import { SearchParamsAND } from '@/shared/filterParams';

  const showCreateDiscussionModal = ref(false);

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const group = computed(() => store.getters['group/getGroup']);

  const loading = ref(true);

  const customFilter = ref([]);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;

  onMounted(() => {
    if (route.query.id) {
      customFilter.value.push(
        new SearchParamsAND(
          route.query.id.toString(),
          'discussions.discussion.discussion_id',
          'equals',
        ),
      );
      store.dispatch('group/fetchItemDetails', route.query.id).then(() => {
        loading.value = false;
      });

      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = publish;
    } else {
      store.commit('group/SET_GROUP', new Group({}));
      loading.value = false;
      loading.value = false;
      topbarPrimaryButtonText = 'Publish';
      topbarSecondaryButtonText = 'Save to draft';
      topbarPrimaryButtonHandler = publish;
      topbarSecondaryButtonHandler = submitToDraft;
    }
  });

  function openCreateDiscussionModal() {
    showCreateDiscussionModal.value = true;
  }

  const publish = async () => {
    store.commit('group/SET_GROUP', {
      ...group.value,
      status: 'published',
      is_published: true,
    });
    await nextTick();
    store.dispatch('group/createItem', group.value).then(() => {
      router.back();
    });
  };

  const submitToDraft = async () => {
    store.commit('group/SET_GROUP', {
      ...group.value,
      status: 'draft',
      is_published: false,
    });
    await nextTick();
    store.dispatch('group/createItem', group.value).then(() => {
      router.back();
    });
  };

  onBeforeUnmount(() => {
    store.commit('group/CLEAR_GROUP');
  });

  const cancelHandler = async () => {
    router.back();
  };
</script>

<template>
  <div>
    <TopbarOrganism
      linkText="Back to groups"
      :showIcon="true"
      icon="angleLeft"
      :secondaryButtonText="topbarSecondaryButtonText"
      :primaryButtonText="topbarPrimaryButtonText"
      :onSecondaryClick="topbarSecondaryButtonHandler"
      :onPrimaryClick="topbarPrimaryButtonHandler"
    />
    <div v-if="!loading">
      <GroupsFormOrganism :initialDetails="group" />
      <div class="group-details-discussion">
        <h2>Discussions</h2>
        <ButtonAtom
          :disabled="!group?.group_id"
          class="btn btn-primary create-discussion-btn"
          @click="openCreateDiscussionModal"
        >
          <IconAtom icon="plusIcon" />
          <p>Create discussion</p>
        </ButtonAtom>
      </div>
      <TableMolecule dataType="GroupDiscussion" />
    </div>
    <p v-else>Loading...</p>
  </div>
  <div class="group-details-discussion-table">
    <CreateDiscussionAtom
      :show="showCreateDiscussionModal"
      :group_id="group?.group_id"
      @update:show="showCreateDiscussionModal = $event"
    />
  </div>
</template>

<style scoped>
  .group-details-discussion {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 20px 20px 0px 20px;
  }

  .create-discussion-btn {
    padding: 12px 16px;
  }

  .group-details-discussion-table {
    margin-bottom: 20px;
  }
</style>
