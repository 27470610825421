import { SearchParamsAND } from './filterParams';
import { TableConfigurationInterface } from './tableConfiguration.interface';

export const tableConfigurations: {
  [key: string]: TableConfigurationInterface;
} = {
  Article: {
    dataType: 'Resource',
    rowSelection: {
      rowSelectionProperty: 'resource_id',
    },
    columns: [
      {
        title: 'Title',
        propertyName: 'resource_name',
        imageProperty: 'banner',
      },
      { title: 'Author', propertyName: 'author' },
      {
        title: 'Tags',
        propertyName: 'tags',
        visibleProperty: 'tag.tag_name',
        class: 'tag-item',
        arrayShowMax: 2,
      },
      { title: 'Date', propertyName: 'updated_at' },
      {
        title: 'Comments',
        propertyName: 'comment_count',
        icon: 'commentsIcon',
      },
      {
        title: 'Settings',
        settings: true,
        settingsUrl: '/articlesDetails',
        settingsIdProperty: 'resource_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('resource/fetchResources', params);
    },
    storeActions: {
      items: 'resource/getItems',
      totalPages: 'resource/getTotalPages',
      deleteAction: 'resource/deleteItems',
    },
    noDataStateProps: {
      route: '/articlesDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new article',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    filterSettings: {
      visible: true,
      title: 'Filter by tags:',
    },
    tableActionButtonProps: [
      {
        route: '/articlesDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New article',
      },
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-secondary',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete selected',
      },
    ],
    tabs: {
      countGetter: 'resource/getResourceCounts',
      field: 'status',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Published', value: 'published', count: 0 },
        { text: 'Drafts', value: 'draft', count: 0 },
        { text: 'Trash', value: 'unpublished', count: 0 },
      ],
    },
    defaultFilters: [new SearchParamsAND('article', 'resource_type', 'equals')],
  },
  Tag: {
    dataType: 'Tag',
    columns: [
      {
        title: 'Name',
        propertyName: 'tag_name',
        class: 'tag-item',
      },
      {
        title: 'Userd for Resource',
        propertyName: 'resource_count',
      },
      {
        title: 'Userd for Collection',
        propertyName: 'collection_count',
      },
      {
        title: 'Settings',
        settings: true,
        settingsUrl: '/tagsDetails',
        settingsIdProperty: 'tag_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('tag/fetchTags', params);
    },
    // Specifies the Vuex store actions related to fetching items, getting total pages, and possibly deleting items.
    storeActions: {
      items: 'tag/getItems',
      totalPages: 'tag/getTotalPages',
      deleteAction: 'tag/deleteItems',
    },
    // Defines what should be displayed if there is no data.
    noDataStateProps: {
      route: '/tagsDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new tag',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    tableActionButtonProps: [
      {
        route: '/tagsDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New Tag',
      },
    ],
    //Optional settings for displaying tabbed views within the table.
    tabs: {
      countGetter: 'tag/getTagCounts',
      field: 'status',
      fields: [{ text: 'All', value: '', count: 0 }],
    },
  },
  Collection: {
    dataType: 'Collection',
    rowSelection: {
      rowSelectionProperty: 'collection_id',
    },
    columns: [
      {
        title: 'Name',
        propertyName: 'collection_name',
        imageProperty: 'banner',
      },
      {
        title: 'Status',
        propertyName: 'status',
      },
      {
        title: 'Qty of content',
        propertyName: '_count',
        visibleProperty: 'resources',
      },
      {
        title: 'Tags',
        propertyName: 'collectionTag',
        visibleProperty: 'tag.tag_name',
        class: 'tag-item',
        arrayShowMax: 2,
      },
      {
        title: 'Type',
        propertyName: 'is_featured',
      },
      {
        title: 'Settings',
        settings: true,
        settingsUrl: '/collectionsDetails',
        settingsIdProperty: 'collection_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('collection/fetchCollections', params);
    },
    storeActions: {
      items: 'collection/getItems',
      totalPages: 'collection/getTotalPages',
      deleteAction: 'collection/deleteItems',
    },
    noDataStateProps: {
      route: '/collectionsDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new collection',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    tableActionButtonProps: [
      {
        route: '/collectionsDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New Collection',
      },
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-secondary',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete selected',
      },
    ],
    tabs: {
      countGetter: 'collection/getCollectionCounts',
      field: 'status',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Published', value: 'published', count: 0 },
        { text: 'Drafts', value: 'draft', count: 0 },
        { text: 'Trash', value: 'unpublished', count: 0 },
      ],
    },
  },
  Audio: {
    dataType: 'Resource',
    rowSelection: {
      rowSelectionProperty: 'resource_id',
    },
    columns: [
      {
        title: 'Title',
        propertyName: 'resource_name',
        imageProperty: 'banner',
      },
      { title: 'Author', propertyName: 'author' },
      {
        title: 'Tags',
        propertyName: 'tags',
        visibleProperty: 'tag.tag_name',
        class: 'tag-item',
        arrayShowMax: 2,
      },
      { title: 'Date', propertyName: 'updated_at' },
      {
        title: 'Comments',
        propertyName: 'comment_count',
        icon: 'commentsIcon',
      },
      {
        title: 'Settings',
        settings: true,
        settingsUrl: '/audiosDetails',
        settingsIdProperty: 'resource_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('resource/fetchResources', params);
    },
    storeActions: {
      items: 'resource/getItems',
      totalPages: 'resource/getTotalPages',
      deleteAction: 'resource/deleteItems',
    },
    noDataStateProps: {
      route: '/audiosDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new audio',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    filterSettings: {
      visible: true,
      title: 'Filter by tags:',
    },
    tableActionButtonProps: [
      {
        route: '/audiosDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New audio',
      },
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-secondary',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete selected',
      },
    ],
    tabs: {
      countGetter: 'resource/getResourceCounts',
      field: 'status',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Published', value: 'published', count: 0 },
        { text: 'Drafts', value: 'draft', count: 0 },
        { text: 'Trash', value: 'unpublished', count: 0 },
      ],
    },
    defaultFilters: [new SearchParamsAND('audio', 'resource_type', 'equals')],
  },
  Video: {
    dataType: 'Resource',
    rowSelection: {
      rowSelectionProperty: 'resource_id',
    },
    columns: [
      {
        title: 'Title',
        propertyName: 'resource_name',
        imageProperty: 'banner',
      },
      { title: 'Author', propertyName: 'author' },
      {
        title: 'Tags',
        propertyName: 'tags',
        visibleProperty: 'tag.tag_name',
        class: 'tag-item',
        arrayShowMax: 2,
      },
      { title: 'Date', propertyName: 'updated_at' },
      {
        title: 'Comments',
        propertyName: 'comment_count',
        icon: 'commentsIcon',
      },
      {
        title: 'Settings',
        settings: true,
        settingsUrl: '/videosDetails',
        settingsIdProperty: 'resource_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('resource/fetchResources', params);
    },
    storeActions: {
      items: 'resource/getItems',
      totalPages: 'resource/getTotalPages',
      deleteAction: 'resource/deleteItems',
    },
    noDataStateProps: {
      route: '/videosDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new video',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    tableActionButtonProps: [
      {
        route: '/videosDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New video',
      },
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-secondary',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete selected',
      },
    ],
    tabs: {
      countGetter: 'resource/getResourceCounts',
      field: 'status',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Published', value: 'published', count: 0 },
        { text: 'Drafts', value: 'draft', count: 0 },
        { text: 'Trash', value: 'unpublished', count: 0 },
      ],
    },
    defaultFilters: [new SearchParamsAND('video', 'resource_type', 'equals')],
  },
  Group: {
    dataType: 'Group',
    rowSelection: {
      rowSelectionProperty: 'group_id',
    },
    columns: [
      {
        title: 'Title',
        propertyName: 'name',
        imageProperty: 'banner',
      },
      {
        title: 'Description',
        propertyName: 'description',
      },
      {
        title: 'Followers',
        propertyName: 'user_count',
      },
      {
        title: 'Posts',
        propertyName: 'comment_count',
      },
      {
        title: 'Date Created',
        propertyName: 'created_at',
        type: 'date',
      },
      {
        title: 'Last Active',
        propertyName: 'last_comment_at',
      },
      {
        title: 'Settings',
        settings: true,
        settingsUrl: '/groupsDetails',
        settingsIdProperty: 'group_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('group/fetchGroups', params);
    },
    storeActions: {
      items: 'group/getItems',
      totalPages: 'group/getTotalPages',
      deleteAction: 'group/deleteItems',
    },
    noDataStateProps: {
      route: '/groupsDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new group',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    tableActionButtonProps: [
      {
        route: '/groupsDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New Group',
      },
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-secondary',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete selected',
      },
    ],
    tabs: {
      countGetter: 'group/getGroupCounts',
      field: 'status',
      fields: [
        { text: 'Published', value: 'published', count: 0 },
        { text: 'Drafts', value: 'draft', count: 0 },
        { text: 'Trash', value: 'unpublished', count: 0 },
      ],
    },
  },
  GroupDiscussion: {
    dataType: 'Discussion',
    rowSelection: {
      rowSelectionProperty: 'discussion_id',
    },
    columns: [
      {
        title: 'Discussion',
        propertyName: 'description',
      },
      {
        title: 'Creator',
        propertyName: 'created',
        visibleProperty: 'name',
        arrayShowMax: 2,
      },
      {
        title: 'Created',
        propertyName: 'created_at',
        type: 'date',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('group/fetchGroupDiscussions', params);
    },
    storeActions: {
      items: 'group/getGroupDiscussions',
      totalPages: 'group/getTotalDiscussionsPages',
    },
    noDataStateProps: {
      action: 'openDiscussionModal',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      //buttonText: 'Start Discussion',
      headerText: 'There are no Discussions yet',
      descriptionText: 'It’s time to create the first one!',
    },
    tabs: {
      countGetter: 'tag/getGroupDiscussionCount',
    },
  },
  ConnectedResources: {
    dataType: 'Resource',
    rowSelection: {
      rowSelectionProperty: 'resource_id',
    },
    columns: [
      {
        title: 'Title',
        propertyName: 'resource_name',
        imageProperty: 'banner',
      },
      {
        title: 'Type',
        propertyName: 'resource_type',
        class: 'capitalize',
      },
      { title: 'Date', propertyName: 'updated_at' },
      {
        title: 'Tags',
        propertyName: 'tags',
        visibleProperty: 'tag.tag_name',
        class: 'tag-item',
        arrayShowMax: 2,
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('resource/fetchConnectedResources', {
        params,
        notConnected: false,
      });
    },
    storeActions: {
      items: 'resource/getConnectedResources',
      totalPages: 'resource/getConnectedResourcesTotalPages',
      deleteAction: 'resource/disconnectResources',
    },
    noDataStateProps: {
      action: 'openResourcesModal',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      // buttonText: 'Connect resource',
      headerText: 'There are no resources connected yet.',
      descriptionText: "It's time to connect the first one!",
    },
    tableActionButtonProps: [
      {
        action: 'openResourcesModal',
        buttonClass: 'btn btn-no-border',
        icon: 'plusIcon',
        iconColor: 'black',
        buttonText: 'Add resource',
      },
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-no-border',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete resource',
      },
    ],
    tabs: {
      countGetter: 'resource/getConnectedResourceCounts',
      field: 'resource_type',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Article', value: 'article', count: 0 },
        { text: 'Video', value: 'video', count: 0 },
        { text: 'Audio', value: 'audio', count: 0 },
      ],
    },
  },

  NotConnectedResources: {
    dataType: 'Resource',
    rowSelection: {
      rowSelectionProperty: 'resource_id',
    },
    columns: [
      {
        title: 'Title',
        propertyName: 'resource_name',
        imageProperty: 'banner',
      },
      {
        title: 'Type',
        propertyName: 'resource_type',
        class: 'capitalize',
      },
      { title: 'Date', propertyName: 'updated_at' },
      {
        title: 'Tags',
        propertyName: 'tags',
        visibleProperty: 'tag.tag_name',
        class: 'tag-item',
        arrayShowMax: 2,
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('resource/fetchConnectedResources', {
        params,
        notConnected: true,
      });
    },
    storeActions: {
      items: 'resource/getNotConnectedResources',
      totalPages: 'resource/getNotConnectedResourcesTotalPages',
      deleteAction: 'resource/connectResources',
    },
    noDataStateProps: {
      action: 'openResourcesModal',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      // buttonText: 'Connect resource',
      headerText: 'There are no resources connected yet.',
      descriptionText: "It's time to connect the first one!",
    },
    tableActionButtonProps: [
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'Add resource',
      },
    ],
    tabs: {
      countGetter: 'resource/getNotConnectedResourceCounts',
      field: 'resource_type',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Article', value: 'article', count: 0 },
        { text: 'Video', value: 'video', count: 0 },
        { text: 'Audio', value: 'audio', count: 0 },
      ],
    },
  },

  User: {
    dataType: 'User',
    columns: [
      {
        title: 'Name',
        propertyName: 'full_name',
        imageProperty: 'user_photo',
        userImage: true,
      },
      {
        title: 'Username',
        propertyName: 'username',
      },
      {
        title: 'Mobile',
        propertyName: 'phone_number',
      },
      {
        title: 'Email ID',
        propertyName: 'email',
      },
      {
        title: 'Role',
        propertyName: 'role_names',
      },
      {
        title: 'Status',
        propertyName: 'user_status',
      },
      {
        title: 'Actions',
        action: 'edit',
        settingsIdProperty: 'user_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('user/fetchUsers', params);
    },
    storeActions: {
      items: 'user/getItems',
      totalPages: 'user/getTotalPages',
      deleteAction: 'user/deleteItems',
    },
    noDataStateProps: {
      route: '/groupsDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      headerText: 'There are no data to display yet',
      descriptionText: 'There are no users registered yet',
    },
    tableActionButtonProps: [],
    tabs: {
      countGetter: 'user/getUserCounts',
      field: 'user_status',
      fields: [
        { text: 'All', value: '', count: 0 },
        { text: 'Inactive', value: 'inactive', count: 0 },
        { text: 'Banned', value: 'banned', count: 0 },
      ],
    },
  },
  Comment: {
    dataType: 'Comment',
    rowSelection: {
      rowSelectionProperty: 'comment_id',
    },
    columns: [
      {
        title: 'Comment',
        propertyName: 'content',
      },
      {
        title: 'Commenter',
        propertyName: 'full_name',
        imageProperty: 'user_photo',
        userImage: true,
      },
      {
        title: 'Date',
        propertyName: 'created_at',
        type: 'date',
      },
      {
        title: 'ID',
        propertyName: 'commen',
      },
      {
        title: 'Date',
        propertyName: 'created_at',
        type: 'date',
      },
      {
        title: 'Delete',
        action: 'delete',
        settingsIdProperty: 'comment_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('comment/fetchComments', params);
    },
    storeActions: {
      items: 'comment/getItems',
      totalPages: 'comment/getTotalPages',
      deleteAction: 'comment/deleteItems',
    },
    noDataStateProps: {
      route: '',
      buttonClass: '',
      icon: '',
      iconColor: '',
      headerText: '',
      descriptionText: '',
    },
    tableActionButtonProps: [
      {
        action: 'deleteSelected',
        buttonClass: 'btn btn-secondary',
        icon: 'deleteIcon',
        iconColor: 'black',
        buttonText: 'Delete selected',
      },
    ],
    tabs: {},
  },
  Notification: {
    dataType: 'Notification',
    columns: [
      {
        title: 'Title',
        propertyName: 'title',
        imageProperty: 'banner',
      },
      {
        title: 'Message',
        propertyName: 'message',
      },
      {
        title: 'Send to',
        propertyName: 'send_to_count',
      },
      {
        title: 'Time Sent',
        propertyName: 'time_sent',
        type: 'datetime',
      },
      {
        title: 'Last Modified',
        propertyName: 'updated_at',
        type: 'datetime',
      },
      {
        title: 'Scheduled',
        propertyName: 'schedule_at',
        type: 'datetime',
      },
      {
        title: 'Repeat',
        propertyName: 'repeat_type',
      },
      {
        title: 'Actions',
        action: 'edit',
        settingsIdProperty: 'notification_id',
      },
    ],
    fetchData: async (store, params) => {
      return store.dispatch('notification/fetchNotifications', params);
    },
    storeActions: {
      items: 'notification/getItems',
      totalPages: 'notification/getTotalPages',
    },
    noDataStateProps: {
      route: '/notificationDetails',
      buttonClass: 'btn btn-secondary',
      icon: 'plusIcon',
      iconColor: 'black',
      buttonText: 'Add new notification',
      headerText: 'There are no data to display yet',
      descriptionText: "It's time to create the first one!",
    },
    tableActionButtonProps: [
      {
        route: '/notificationDetails',
        buttonClass: 'btn btn-primary',
        icon: 'plusIcon',
        iconColor: 'white',
        buttonText: 'New Notification',
      },
      {},
    ],
    tabs: {
      countGetter: 'notification/getNotificationCounts',
      field: 'status',
      fields: [
        { text: 'Sent', value: 'sent', count: 0 },
        { text: 'Scheduled', value: 'scheduled', count: 0 },
        { text: 'Drafts', value: 'draft', count: 0 },
      ],
    },
  },
};
