import Tag from "../tag/tag.model";

export default class Collection {
  collection_id: string;
  collection_name: string;
  banner: string;
  content_qty: number;
  status: string;
  is_featured: boolean;
  is_deleted: boolean;
  is_published: boolean;
  collectionTag?: Tag[];

  constructor(data: Partial<Collection>) {
    this.collection_id = data.collection_id || "";
    this.collection_name = data.collection_name || "";
    this.banner = data.banner || "";
    this.content_qty = data.content_qty || 0;
    this.status = data.status || "draft";
    this.is_featured = data.is_featured || false;
    this.is_deleted = data.is_deleted || false;
    this.is_published = data.is_published || false;
  }
}
