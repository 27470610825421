<script setup lang="ts">
  import {
    ref,
    defineProps,
    defineEmits,
    computed,
    watch,
    nextTick,
  } from 'vue';
  import AudioEditorMolecule from '@/components/molecules/audio-editor-molecules/AudioEditorMolecule.vue';
  import viewCommentsAtom from '@/components/atoms/viewCommentsAtom.vue';
  import ViewCommentsMolecule from '@/components/molecules/view-comments-molecule/ViewCommentsMolecule.vue';
  import { useStore } from 'vuex';

  const store = useStore();

  const props = defineProps<{ initialResourceDetails: any }>();

  const previewData = ref({
    resource_name: '',
    tags: [],
    text_content: '',
  });

  const showNoCommentsModal = ref(false);

  const coverImage = ref('');

  const resource = computed(() => store.getters['resource/getResource']);
  const localResourceDetails = ref({ ...props.initialResourceDetails });

  const emit = defineEmits(['update:resourceDetails']);

  let isUpdating = false;

  watch(
    resource,
    (newValue) => {
      if (!isUpdating && newValue) {
        localResourceDetails.value = { ...newValue };
        updatePreviewData({ ...newValue });
      }
    },
    { immediate: true, deep: true },
  );

  const updateResourceDetails = (updatedDetails) => {
    if (isUpdating) return;
    isUpdating = true;

    store.commit('resource/SET_RESOURCE', updatedDetails);

    emit('update:resourceDetails', updatedDetails);

    nextTick(() => {
      isUpdating = false;
    });
  };

  function updatePreviewData(data) {
    coverImage.value = data;
  }

  function openNoCommentsModal() {
    showNoCommentsModal.value = true;
  }
</script>

<template>
  <div class="audio-wrapper">
    <div class="audio-editor">
      <AudioEditorMolecule
        :resourceDetails="localResourceDetails"
        @updateResourceDetails="updateResourceDetails"
        @updatePreview="updatePreviewData"
      />
    </div>
    <div class="preview-container">
      <viewCommentsAtom
        @open="openNoCommentsModal"
        :resource_id="localResourceDetails.resource_id"
      />
      <ViewCommentsMolecule
        :resource_id="localResourceDetails.resource_id"
        :show="showNoCommentsModal"
        @update:show="showNoCommentsModal = $event"
      />
    </div>
  </div>
</template>

<style scoped>
  .audio-wrapper {
    width: 90%;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    margin: auto;
    box-sizing: border-box;
  }

  .audio-editor {
    background-color: white;
    flex: 1.5;
    border-radius: 12px;
    box-sizing: border-box;
  }

  .preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
</style>
