<template>
  <div
    v-if="isVisible"
    ref="filterModalRef"
    :style="{ position: 'absolute', top: top + 'px', right: right + 'px' }"
    class="filter-modal"
  >
    <span class="filter-modal-title modal-item">
      <p>{{ filterModalTitle }}</p>
    </span>
    <div class="filter-options">
      <label
        v-for="option in filterOptions"
        :key="option.key"
        class="modal-item"
      >
        <input
          type="checkbox"
          class="filter-checkbox-input"
          :value="option.key"
          v-model="selectedOptions"
          @change="emitSelectedOptions"
        />
        <span class="custom-checkbox"></span>
        <span class="filter-checkbox-label">
          {{ option.value }}
        </span>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SearchbarModalOptions } from "@/shared/searchbarModalOptions";
import { onBeforeUnmount, onMounted, ref } from "vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    top: number;
    right: number;
    filterModalTitle: string;
    filterOptions: SearchbarModalOptions[];
  }>(),
  {
    filterModalTitle: "Filter By:",
  }
);

const emit = defineEmits(["close", "update:sort"]);
const filterModalRef = ref<HTMLElement | null>(null);
const selectedOptions = ref<string[]>([]);

const emitSelectedOptions = () => {
  emit("update:sort", selectedOptions.value);
};

const handleClickOutside = (event: MouseEvent): void => {
  if (
    filterModalRef.value &&
    !filterModalRef.value.contains(event.target as Node) &&
    props.isVisible
  ) {
    emit("close");
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped src="./FilterModalMolecule.css"></style>
