<script setup lang="ts">
import { ref, watch, defineProps, defineEmits, nextTick, computed } from "vue";
import CollectionEditMolecule from "@/components/molecules/collection-edit-molecule/CollectionEditMolecule.vue";
import { useStore } from "vuex";

const props = defineProps<{ initialDetails: any }>();

const emit = defineEmits(["update:collectionDetails"]);

let isUpdating = false;

const store = useStore();

const collection = computed(() => store.getters["collection/getCollection"]);
const localDetails = ref({ ...props.initialDetails });

watch(
  collection,
  (newValue) => {
    if (!isUpdating && newValue) {
      localDetails.value = { ...newValue };
    }
  },
  { immediate: true, deep: true }
);

const updateDetails = (updatedDetails) => {
  if (isUpdating) return;
  isUpdating = true;

  store.commit("collection/SET_COLLECTION", updatedDetails);

  emit("update:collectionDetails", updatedDetails);

  nextTick(() => {
    isUpdating = false;
  });
};
</script>

<template>
  <div class="wrapper">
    <CollectionEditMolecule
      :details="localDetails"
      @updateDetails="updateDetails"
    />
  </div>
</template>

<style src="./CollectionsFormOrganism.css" scoped></style>
