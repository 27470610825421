<script setup lang="ts">
import { ref, computed, defineProps } from "vue";

const props = defineProps<{ modelValue: boolean }>();
const emit = defineEmits(["update:modelValue"]);

const isOn = ref(props.modelValue);

const computedClass = computed(() => ({
  on: isOn.value,
}));

function toggle() {
  isOn.value = !isOn.value;
  emit("update:modelValue", isOn.value);
}
</script>

<template>
  <div class="switch-container">
    <h4>Feature collection</h4>
    <div class="switch" :class="computedClass" @click="toggle">
      <div class="toggle"></div>
    </div>
  </div>
</template>

<style scoped>
.switch-container {
  width: 90%;
  padding: 16px 32px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f1f1f1;
  border-radius: 12px;
}

.switch {
  width: 50px;
  height: 25px;
  background-color: #f1f1f1;
  border-radius: 25px;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.switch.on {
  background-color: black;
}

.toggle {
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s ease;
  transform: translateX(0);
}

.switch.on .toggle {
  transform: translateX(25px);
}
</style>
