<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from "vue";
import InputMolecule from "../input-molecule/InputMolecule.vue";

const props = defineProps<{ details: any }>();
const emit = defineEmits(["updateDetails"]);

const localDetails = ref({ ...props.details });

const showAddNewTagModal = ref(false);
const tags = ref([]);
const coverImage = ref<string | null>(null);
const uploadedImg = ref<HTMLInputElement | null>(null);
const tagOptions = ref(["Parenting", "Adoption", "Co-parenting", "Teenagers"]);

watch(
  () => props.details,
  (newValue) => {
    localDetails.value = { ...newValue };
  },
  { immediate: true, deep: true }
);

watch(
  localDetails,
  () => {
    emit("updateDetails", localDetails);
  },
  { deep: true }
);
</script>

<template>
  <div class="tag-editor-molecule">
    <InputMolecule
      placeholder="Name"
      title="Add new tag here"
      label="Title"
      v-model="localDetails.tag_name"
    />
  </div>
</template>

<style src="./TagEditorMoelcule.css" scoped></style>
