<script setup lang="ts">
  import { onMounted, onUnmounted, defineEmits, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import User from '@/models/user/user.model';
  import { watch } from 'vue';

  const store = useStore();
  const selectedUser = computed<User>(
    () => store.getters['user/getSelectedUser'],
  );
  const props = withDefaults(
    defineProps<{
      show: boolean;
    }>(),
    {},
  );

  const emits = defineEmits(['update:show']);

  const localUser = ref<User>({} as User);

  onMounted(() => {
    if (selectedUser.value) {
      localUser.value = JSON.parse(JSON.stringify(selectedUser.value));
    }
  });

  watch(selectedUser, (newVal) => {
    if (newVal) {
      localUser.value = JSON.parse(JSON.stringify(newVal));
    }
  });

  const closeModal = () => {
    emits('update:show', false);
    localUser.value = JSON.parse(JSON.stringify(selectedUser.value));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  const updateUser = () => {
    const updatedUser = {
      name: localUser.value.full_name?.split(' ')[0],
      surname: localUser.value.full_name?.split(' ')[1],
      phone_number: localUser.value.phone_number,
      email: localUser.value.email,
      username: localUser.value.username,
      user_id: localUser.value.user_id,
    };
    store.dispatch('user/updateUser', updatedUser);
    closeModal();
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div>
    <div
      v-if="props.show"
      class="edit-detials-modal-overlay"
      @click="closeModal"
    >
      <div class="edit-details-modal" @click.stop>
        <h2>Edit details</h2>
        <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
        <div class="user-details-section">
          <div class="center-container">
            <div class="cover-section">
              <div class="cover-img">
                <ButtonAtom class="btn btn-secondary coverBtn">
                  <IconAtom icon="cameraIcon" />
                  <p>Cover</p>
                  <button class="clear-image-btn">
                    <IconAtom icon="editIcon" color="#6B6E78" />
                  </button>
                </ButtonAtom>
                <input
                  type="file"
                  ref="uploadedImg"
                  accept="image/*"
                  @change="imgupload"
                  style="display: none"
                />
              </div>
            </div>
          </div>

          <div class="form-section">
            <div class="form-item title">
              <label for="title">Name</label>
              <InputAtom
                id="title"
                placeholder="Name"
                class="input"
                v-model="localUser.full_name"
              />
            </div>
            <div class="form-item username">
              <label for="username">Username</label>
              <InputAtom
                id="username"
                placeholder="Username"
                v-model="localUser.username"
                class="input"
              />
            </div>
            <div class="form-item mobile">
              <label for="mobile">Mobile</label>
              <InputAtom
                id="mobile"
                placeholder="Mobile"
                v-model="localUser.phone_number"
                class="input"
              />
            </div>
            <div class="form-item email-id">
              <label for="email-id">Email ID</label>
              <InputAtom
                id="email-id"
                placeholder="Email ID"
                v-model="localUser.email"
                class="input"
              />
            </div>
          </div>
        </div>
        <div class="buttons-section">
          <ButtonAtom class="btn btn-secondary" @click="closeModal">
            <p>Cancel</p>
          </ButtonAtom>
          <ButtonAtom class="btn btn-primary" @click="updateUser">
            <p>Save</p>
          </ButtonAtom>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .edit-detials-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-details-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 32px;
    position: relative;
  }

  .edit-details-modal h2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .user-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }

  .cover-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
  }

  .btn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
  }

  .btn.coverBtn {
    height: 100px;
    width: 100px;
  }

  .clear-image-btn {
    position: absolute;
    top: -3px;
    right: -3px;
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }

  .center-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .form-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  .form-item label {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .buttons-section {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
  }

  .btn {
    padding: 8px 48px;
  }
  .btn p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
</style>
