<script setup lang="ts">
  import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
  import TopbarOrganism from '@/components/organisms/topbar-organism/TopbarOrganism.vue';

  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import Notification from '@/models/notification/notification.model';
  import NotificationFormOrganisam from '@/components/organisms/notification-form-organism/NotificationFormOrganisam.vue';
  import ScheduleNotificationModal from '@/components/atoms/ScheduleNotificationModal.vue';

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const notification = computed(
    () => store.getters['notification/getNotification'],
  );
  const showScheduleNotificationModal = ref(false);

  const loading = ref(true);

  let topbarPrimaryButtonText = '';
  let topbarSecondaryButtonText = '';
  let topbarPrimaryButtonHandler;
  let topbarSecondaryButtonHandler;
  let modalActionButtonOneText = '';
  let modalActionButtonTwoText = '';
  let modalActionButtonOneHandler;
  let modalActionButtonTwoHandler;

  onMounted(() => {
    if (route.query.id) {
      store
        .dispatch('notification/fetchItemDetails', route.query.id)
        .then(() => {
          loading.value = false;
        });

      topbarPrimaryButtonText = 'Save';
      topbarSecondaryButtonText = 'Cancel';
      topbarSecondaryButtonHandler = cancelHandler;
      topbarPrimaryButtonHandler = sendNotification;
    } else {
      store.commit('notification/SET_NOTIFICATION', new Notification({}));
      loading.value = false;
      loading.value = false;
      topbarPrimaryButtonText = 'Send notification';
      modalActionButtonOneText = 'Schedule';
      modalActionButtonTwoText = 'Save to draft';
      topbarSecondaryButtonText = 'Cancel';

      modalActionButtonOneHandler = showScheduleModal;
      modalActionButtonTwoHandler = submitToDraft;

      topbarPrimaryButtonHandler = sendNotification;
      topbarSecondaryButtonHandler = cancelHandler;
    }
  });

  const showScheduleModal = () => {
    showScheduleNotificationModal.value = true;
  };

  const sendNotification = async () => {
    store.commit('notification/SET_NOTIFICATION', {
      ...notification.value,
      status: 'sent',
    });

    await nextTick();
    store.dispatch('notification/createItem', notification.value).then(() => {
      router.back();
    });
  };

  const submitToDraft = async () => {
    store.commit('notification/SET_NOTIFICATION', {
      ...notification.value,
      status: 'draft',
    });

    await nextTick();
    store.dispatch('notification/createItem', notification.value).then(() => {
      router.back();
    });
  };

  onBeforeUnmount(() => {
    store.commit('notification/CLEAR_NOTIFICATION');
  });

  const cancelHandler = async () => {
    router.back();
  };
</script>

<template>
  <div>
    <TopbarOrganism
      linkText="Back to notifications"
      :showIcon="true"
      icon="angleLeft"
      :secondaryButtonText="topbarSecondaryButtonText"
      :primaryButtonText="topbarPrimaryButtonText"
      :onSecondaryClick="topbarSecondaryButtonHandler"
      :onPrimaryClick="topbarPrimaryButtonHandler"
      :modalButtonOne="modalActionButtonOneText"
      :modalButtonTwo="modalActionButtonTwoText"
      :onButtonOneClick="modalActionButtonOneHandler"
      :onButtonTwoClick="modalActionButtonTwoHandler"
    />
    <div v-if="!loading">
      <NotificationFormOrganisam :initialDetails="notification" />
      <ScheduleNotificationModal
        :show="showScheduleNotificationModal"
        @update:show="showScheduleNotificationModal = $event"
      />
    </div>
  </div>
</template>

<style scoped></style>
