<template>
  <Editor :init="init" v-model="content" @input="updateContent" />
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from "vue";

// TinyMCE
import "tinymce";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver/theme";
import "tinymce/models/dom/model";
import "tinymce/skins/ui/oxide/skin.css";

// TinyMCE plugins
import "tinymce/plugins/lists/plugin";
import "tinymce/plugins/link/plugin";
import "tinymce/plugins/image/plugin";
import "tinymce/plugins/table/plugin";
import "tinymce/plugins/code/plugin";
import "tinymce/plugins/help/plugin";
import "tinymce/plugins/wordcount/plugin";

import Editor from "@tinymce/tinymce-vue";
import uploadService from "@/services/upload/upload.service";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps<{ modelValue: string }>();
const emit = defineEmits(["update:modelValue"]);

const content = ref(props.modelValue);

watch(content, (newValue) => {
  content.value = newValue;
  emit("update:modelValue", newValue);
});

const init = {
  license_key: "gpl",
  skin: false,
  plugins: "lists link image table code wordcount",
  content_css: false,
  toolbar: "h1 h2 h3 bold italic numlist bullist link blockquote image",
  menubar: false,
  statusbar: false,
  image_title: true,
  automatic_uploads: true,
  file_picker_types: "image",
  file_picker_callback: function (cb, value, meta) {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = async function (event: Event) {
      store.dispatch("loader/showLoader");
      const target = event.target as HTMLInputElement;
      if (!target.files) return;

      const file = target.files[0];

      // Create a FormData object and append the file
      var formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the file using uploadService.upload

        const response = await uploadService.upload(formData);

        // Assuming the response contains the uploaded image URL
        const imageUrl = response.data.link;

        // Insert the image into TinyMCE editor
        cb(imageUrl, { title: file.name });
      } catch (error) {
        console.error("Image upload failed:", error);
        store.dispatch("loader/hideLoader");
      }
      store.dispatch("loader/hideLoader");
    };

    input.click();
  },
};
</script>

<style>
blockquote {
  position: relative;
  background: #000;
  color: white;
  padding: 40px;
  border-radius: 24px;
  font-style: italic;
  font-size: 1.2em;
  line-height: 1.6;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
}

blockquote:before {
  content: "“";
  font-size: 8em;
  left: 0px;
  bottom: -55px;
  font-weight: bold;
  height: auto;
  position: absolute;
  background: var(--gradient);
  background-clip: text;
  color: transparent;
  width: 30%;
  filter: brightness(0.5) contrast(1.2);
}

blockquote:after {
  content: "”";
  font-size: 8em;
  right: 0px;
  top: 5px;
  height: auto;
  position: absolute;
  font-weight: bold;
  background: var(--gradient);
  background-clip: text;
  color: transparent;
  width: 30%;
  filter: brightness(0.5) contrast(1.2);
}

blockquote p {
  margin: 0;
  color: white;
  text-align: center;
}

blockquote {
  display: inline-block;
  max-width: 100%;
}
</style>
