<script setup lang="ts">
  import { SelectOption } from '@/shared/selectOption';
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();

  const props = defineProps<{
    id: string;
    placeholder: string;
    options: SelectOption[];
    tagsGetter: string;
    tagSet: string;
    tagRemove: string;
  }>();

  const emits = defineEmits(['addNew', 'update:modelValue']);

  const showDropdown = ref(false);
  const userInput = ref('');

  // Get the selected tags using the Vuex store getter
  const selectedTags = computed(() => store.getters[props.tagsGetter]); // Ensure it's never undefined
  const rawSelectedTags = computed(() => [...selectedTags.value]);

  function handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    userInput.value = target.value;
  }

  const filteredOptions = computed(() => {
    return (
      props.options?.filter(
        (option: SelectOption) =>
          option.value.toLowerCase().includes(userInput.value.toLowerCase()) &&
          !selectedTags.value.map((tag) => tag.id).includes(option.id),
      ) || []
    );
  });

  function selectOption(option: SelectOption) {
    if (selectedTags.value.length < 3) {
      store.dispatch(props.tagSet, option);
      userInput.value = '';
    }
    showDropdown.value = false;
    emits('update:modelValue', selectedTags.value);
  }

  function removeTag(tag: SelectOption) {
    store.dispatch(props.tagRemove, tag);
    emits('update:modelValue', selectedTags.value);
  }

  function addNew() {
    emits('addNew', userInput.value);
    showDropdown.value = false;
  }

  function handleBlur() {
    setTimeout(() => {
      showDropdown.value = false;
    }, 200);
  }
</script>
<template>
  <div class="input-select-container">
    <div class="tags-input" @click="showDropdown = true">
      <div v-for="tag in rawSelectedTags" :key="tag.id" class="tag">
        {{ tag.value }}
        <span class="remove-tag" @click.stop="removeTag(tag)">×</span>
      </div>
      <input
        class="input"
        :id="props.id"
        :placeholder="rawSelectedTags?.length === 0 ? props.placeholder : ''"
        @input="handleInput"
        v-model="userInput"
        @focus="showDropdown = true"
        @blur="handleBlur"
      />
    </div>
    <div v-if="showDropdown" class="dropdown-list">
      <div class="dropdown-item" @click="addNew">+ Add new</div>
      <div
        v-for="option in filteredOptions"
        :key="option.id"
        class="dropdown-item"
        @click="selectOption(option)"
      >
        {{ option.value }}
      </div>
    </div>
  </div>
</template>

<style scoped>
  .input-select-container {
    position: relative;
  }

  .tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-gray-30);
  }

  .tag {
    background-color: var(--color-gray-100);
    margin: 2px;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .remove-tag {
    margin-left: 8px;
    cursor: pointer;
  }

  .input {
    flex-grow: 1;
    border: none;
    background: none;
    outline: none;
  }

  .dropdown-list {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    /* Adjusted to match padding */
    align-items: center;
    text-align: left;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    height: 400px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid var(--color-gray-100);
    transition: background-color 0.3s;
  }

  .dropdown-item:hover {
    background-color: var(--color-gray-50);
  }

  .dropdown-item:last-child {
    border-bottom: none;
  }
</style>
