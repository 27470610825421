<script setup lang="ts">
  import { defineProps, onMounted, ref, watch } from 'vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';

  const props = defineProps<{
    previewData: {
      resource_name: string;
      text_content: string;
      tags: { id: string; value: string }[];
    };
    coverImage: string;
  }>();

  const localPreviewData = ref({ ...props.previewData });

  onMounted(() => {
    localPreviewData.value = props.previewData;
  });
</script>

<template>
  <div>
    <h3 class="preview-title">Preview</h3>
    <div class="article-preview-molecule">
      <div
        class="preview-header"
        :style="{
          backgroundImage: `url(${props.coverImage})`,
        }"
      >
        <h1>{{ props.previewData.resource_name }}</h1>
        <div class="tags-container">
          <ButtonAtom
            v-for="tag in props.previewData.tags"
            :key="tag.id"
            class="btn btn-secondary tag-button"
          >
            {{ tag.value }}
          </ButtonAtom>
        </div>
      </div>
      <div
        class="preview-content"
        v-html="props.previewData.text_content"
      ></div>
    </div>
  </div>
</template>

<style scoped>
  .preview-title {
    text-align: left;
    margin-bottom: 28px;
    font-weight: bold;
    color: var(--color-black);
  }

  .article-preview-molecule {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 800px;
    min-height: 600px;
    margin: auto;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid var(--color-black);
    padding: 0;
    box-sizing: border-box;
  }

  .preview-header {
    background-size: cover;
    background-position: center;
    font-weight: bold;
    background-color: var(--color-gray-30);
    padding: 20px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 240px;
    word-wrap: break-word;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .preview-header h1 {
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    line-height: 1.2;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    color: white;
  }

  .preview-content {
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--color-black);
    border-top: 1px solid var(--color-black);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    text-align: left;
    max-width: 100%;
    box-sizing: border-box;
  }

  .tags-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }

  .tag-button {
    color: #fff;
    border: 1px solid #fff;
    cursor: default;
  }
</style>
