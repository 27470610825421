<script setup lang="ts">
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
  import UserActionsModal from '@/components/atoms/UserActionsModal.vue';
  import EditUserDetailsModal from '@/components/atoms/EditUserDetailsModal.vue';
  import PushNotificationsModal from '@/components/atoms/PushNotificationsModal.vue';
  import { useStore } from 'vuex';
  import User from '@/models/user/user.model';

  const emit = defineEmits(['close', 'action']);
  const userActionModalRef = ref<HTMLElement | null>(null);
  const showActionModal = ref(false);
  const showEditUserDetails = ref(false);
  const showPushNotifications = ref(false);
  const store = useStore();

  const user = computed<User>(() => store.getters['user/getSelectedUser']);
  const action = ref('');

  const openActionModal = (selectedAction: string) => {
    showActionModal.value = true;
    action.value = selectedAction;
  };

  const openEditUserDetails = () => {
    showEditUserDetails.value = true;
  };

  const openPushNotifications = () => {
    showPushNotifications.value = true;
  };

  const onActionClick = (action: string): void => {
    if (action === 'detail') {
      openEditUserDetails();
    } else if (action === 'notification') {
      openPushNotifications();
    } else {
      openActionModal(action);
    }
  };

  const actions = [
    { icon: 'settingIcon', label: 'Edit Details', type: 'detail' },
    { icon: 'bellIcon', label: 'Push Notifications', type: 'notification' },
    { icon: 'lockIcon', label: 'Reset Password', type: 'passwordReset' },
    { icon: 'banIcon', label: 'Ban from Community', type: 'ban' },
    {
      icon: 'deleteRedIcon',
      label: 'Delete Account',
      type: 'delete',
      color: 'red',
    },
  ];

  const props = withDefaults(
    defineProps<{
      isVisible: boolean;
    }>(),
    {},
  );

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      userActionModalRef.value &&
      !userActionModalRef.value.contains(event.target as Node) &&
      props.isVisible
    ) {
      emit('close');
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
</script>

<template>
  <div class="user-action-container" ref="userActionModalRef">
    <span
      v-for="(action, index) in actions"
      :key="index"
      class="user-action"
      :class="{ 'user-inactive': user.user_status === 'inactive' }"
      :style="{ color: action.color || 'inherit' }"
      @click="
        user.user_status != 'inactive' ? onActionClick(action.type) : null
      "
    >
      <IconAtom
        :icon="action.icon"
        class="user-action-icon"
        :aria-label="action.label"
      ></IconAtom>
      {{ action.label }}
    </span>
  </div>

  <UserActionsModal
    :show="showActionModal"
    :action="action"
    @update:show="showActionModal = $event"
    @dispatch:action="emit('action', action)"
  />
  <EditUserDetailsModal
    :show="showEditUserDetails"
    @update:show="showEditUserDetails = $event"
  />

  <PushNotificationsModal
    :show="showPushNotifications"
    @update:show="showPushNotifications = $event"
  />
</template>

<style scoped src="./UserActionModal.css"></style>
