<script setup lang="ts">
  import { ref, onMounted, onUnmounted, defineEmits } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';

  const props = defineProps({
    show: Boolean,
  });

  const emits = defineEmits(['update:show', 'go-back']);

  const handleGoBack = () => {
    emits('go-back');
  };

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div v-if="props.show" class="reset-password-modal-overlay">
    <div class="reset-password-modal">
      <h3 class="reset-title">
        Reset password has been sent. Check your email.
      </h3>
      <div class="buttons">
        <ButtonAtom class="btn btn-primary">Go to Mail</ButtonAtom>
        <ButtonAtom class="btn btn-secondary" @click="handleGoBack"
          >Go Back</ButtonAtom
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
  .reset-password-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reset-password-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 440px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .reset-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 31.2px;
    margin-bottom: 32px;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
  }

  .btn {
    margin: auto;
    padding: 8px 32px;
  }
</style>
