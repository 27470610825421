<script setup lang="ts">
  import { defineProps, withDefaults } from 'vue';
  const props = withDefaults(
    defineProps<{
      id: string;
      placeholder: string;
      type: string;
      modelValue: string;
    }>(),
    {
      type: 'text',
    },
  );
</script>

<template>
  <input
    :id="props.id"
    :placeholder="props.placeholder"
    :value="props.modelValue"
    :type="props.type"
    @input="$emit('update:modelValue', $event.target.value)"
    class="input"
  />
</template>

<style scoped>
  .input {
    width: 100%;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--color-gray-30);
  }
</style>
