<template>
  <DemographicsAnalyticsOrganism />
</template>

<script setup lang="ts">
  import DemographicsAnalyticsOrganism from '@/components/organisms/demographics-analytics/DemographicsAnalyticsOrganism.vue';
  import store from '@/store';
  import { onBeforeMount } from 'vue';

  onBeforeMount(() => {
    store.dispatch('analytics/fetchDemographicsAnalytics');
  });
</script>
