<script setup lang="ts">
  import {
    ref,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    computed,
  } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import InputSelectAtom from '@/components/atoms/InputSelectAtom.vue';
  import AddNewTagAtom from '@/components/atoms/AddNewTagAtom.vue';
  import TinyEditor from '@/components/atoms/TinyEditor.vue';
  import AddToCollectionAtom from '@/components/atoms/AddToCollectionAtom.vue';
  import {
    SelectOption,
    transformToSelectOptions,
  } from '@/shared/selectOption';
  import tagService from '@/services/tag/tag.service';
  import Collection from '@/models/collection/collection.model';
  import collectionService from '@/services/collection/collection.service';
  import { useStore } from 'vuex';

  const props = defineProps<{ resourceDetails: any }>();
  const emit = defineEmits(['updateResourceDetails', 'updatePreview']);
  const store = useStore();

  const localResourceDetails = ref({ ...props.resourceDetails });
  const showAddNewTagModal = ref(false);
  const showAddToCollectionModal = ref(false);
  const coverImage = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);
  const tagOptions = ref<SelectOption[]>([]);
  const collectionOptions = ref<Collection[]>([]);

  const actionCollectionsGetter = 'resource/getResourceCollections';
  const actionCollectionSet = 'resource/addCollection';
  const actionCollectionRemove = 'resource/removeCollection';
  const actionTagGetter = 'resource/getResourceTags';
  const actionTagSet = 'resource/addTag';
  const actionTagRemove = 'resource/removeTag';

  const selectedCollections = computed(
    () => store.getters[actionCollectionsGetter] || [],
  );
  const selectedTags = computed(() => store.getters[actionTagGetter] || []);

  onMounted(() => {
    fetchTags();
    fetchCollections();
  });

  watch(
    () => props.resourceDetails,
    (newValue) => {
      localResourceDetails.value = { ...newValue };
      coverImage.value = localResourceDetails.value.banner;
    },
    { immediate: true, deep: true },
  );

  watch(
    localResourceDetails,
    () => {
      updatePreview();
    },
    { deep: true },
  );

  const fetchTags = async () => {
    tagOptions.value = transformToSelectOptions(
      ((await tagService.getAllNoFilter()) as any).data,
      'tag_id',
      'tag_name',
    );
  };

  const fetchCollections = async () => {
    collectionOptions.value = (
      (await collectionService.getAllNoFilter()) as any
    ).data;
  };

  function handleNewTagAdded() {
    console.log(456);

    showAddNewTagModal.value = false;
    fetchTags();
  }

  function addTagToSelection(newTag) {
    if (!localResourceDetails.value.tags) {
      localResourceDetails.value.tags = [];
    }

    if (localResourceDetails.value.tags.length < 3) {
      localResourceDetails.value.tags.push({
        id: newTag.tag_id,
        value: newTag.tag_name,
      });

      store.dispatch(actionTagSet, {
        id: newTag.tag_id,
        value: newTag.tag_name,
      });
    }
  }

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function openAddToCollectionModal() {
    showAddToCollectionModal.value = true;
  }

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
      updatePreview();
    };
    reader.readAsDataURL(file);
    localResourceDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
      localResourceDetails.value.banner = '';
    }
    updatePreview();
  }

  function updatePreview() {
    emit('updatePreview', coverImage.value);

    emit('updateResourceDetails', localResourceDetails);
  }

  function handleCollectionsUpdate(collections: string[]) {
    //selectedCollections.value = collections;
    localResourceDetails.value.collections = collections;
    showAddToCollectionModal.value = false;
  }

  function handleCollectionRemove(collection: string) {
    // selectedCollections.value = selectedCollections.value.filter(
    //   (c) => c !== collection
    // );

    store.dispatch(actionCollectionRemove, collection);
    localResourceDetails.value.collections = selectedCollections.value;

    if (selectedCollections.value.length === 0) {
      showAddToCollectionModal.value = false;
    }

    updatePreview();
  }

  updatePreview();
</script>

<template>
  <div class="article-editor-molecule">
    <div class="cover-img">
      <h3 class="editor-title">Add new articles details</h3>
      <ButtonAtom
        class="btn btn-secondary coverBtn"
        :style="{
          backgroundImage: coverImage ? `url(${coverImage})` : '',
          border: coverImage ? 'none' : '1px dashed rgba(8, 14, 30, 0.30)',
        }"
        @click="triggerImgUpload"
      >
        <IconAtom v-if="!coverImage" icon="cameraIcon" />
        <p v-if="!coverImage">Cover</p>

        <button
          v-if="coverImage"
          class="clear-image-btn"
          @click.stop="clearImage"
        >
          X
        </button>
      </ButtonAtom>
      <input
        type="file"
        ref="uploadedImg"
        accept="image/*"
        @change="imgupload"
        style="display: none"
      />
    </div>

    <div class="title">
      <label for="title">Title</label>
      <InputAtom
        id="title"
        v-model="localResourceDetails.resource_name"
        placeholder="Name"
        class="input"
      />
    </div>

    <div class="tags">
      <label for="tags">Tags (maximum 3)</label>
      <InputSelectAtom
        id="tags"
        placeholder="Add tags"
        v-model="localResourceDetails.tags"
        :options="tagOptions"
        @addNew="openAddNewTagModal"
        :tagsGetter="actionTagGetter"
        :tagSet="actionTagSet"
        :tagRemove="actionTagRemove"
      />
      <AddNewTagAtom
        :show="showAddNewTagModal"
        @update:show="showAddNewTagModal = $event"
        @newTagAdded="handleNewTagAdded"
        @addTagToSelection="addTagToSelection"
      />
    </div>

    <div class="collection">
      <label for="collection">Collection</label>
      <div
        v-if="selectedCollections.length > 0"
        class="selected-collections-container"
      >
        <div
          v-for="collection in selectedCollections"
          :key="collection"
          class="collection-item"
        >
          <div class="name-and-img">
            <img
              v-if="collection.banner"
              :src="collection.banner"
              alt="Collection Image"
              class="collection-icon"
            />

            {{ collection.collection_name }}
          </div>
          <IconAtom
            icon="deleteIcon"
            @click="() => handleCollectionRemove(collection)"
            class="pointer"
          />
        </div>
      </div>
      <ButtonAtom
        class="btn btn-secondary collectionBtn"
        @click="openAddToCollectionModal"
      >
        + Add to a collection
      </ButtonAtom>
      <AddToCollectionAtom
        :show="showAddToCollectionModal"
        :collections="collectionOptions"
        @update:show="showAddToCollectionModal = $event"
        @updateCollections="handleCollectionsUpdate"
        :actionCollectionsGetter="actionCollectionsGetter"
        :actionCollectionSet="actionCollectionSet"
        :actionCollectionRemove="actionCollectionRemove"
      />
    </div>

    <div class="text-editor">
      <label for="text">Text</label>
      <TinyEditor v-model="localResourceDetails.text_content" />
    </div>
  </div>
</template>

<style src="./ArticleEditorMolecule.css" scoped></style>
