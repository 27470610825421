<script setup lang="ts">
  import { ref, computed, onMounted, watch } from 'vue';
  import { Store, useStore } from 'vuex';
  import { ColumnConfigurationInterface } from '@/shared/columnConfiguration.interface';
  import {
    FilterParams,
    SearchParamsAND,
    AdditionalParams,
  } from '@/shared/filterParams';
  import { tableConfigurations } from '@/shared/tableConfigurations';
  import Resource from '@/models/resource/resource.model';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import { useRoute, useRouter } from 'vue-router';
  import NoDataMolecule from '../no-data-molecule/NoDataMolecule.vue';
  import { NoDataStateProps } from '@/shared/noDataStateProps';
  import SearchbarMolecule from '../searchbar-molecule/SearchbarMolecule.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import TabbarOrganism from '@/components/organisms/tabbar-organism/TabbarOrganism.vue';
  import userDefaultImage from '@/assets/default-user-img.png';
  import defaultImage from '@/assets/default-image.png';
  import UserActionModal from '../user-action-modal/UserActionModal.vue';
  import ViewCommentsMolecule from '@/components/molecules/view-comments-molecule/ViewCommentsMolecule.vue';
  import NotificationActionModal from '../notification-action-modal/NotificationActionModal.vue';
  const emits = defineEmits(['update:selectedRows']);

  const props = defineProps<{
    dataType: string;
    filterOptions?: any[];
    dynamicDefaultFilter?: SearchParamsAND[];
    dynamicAdditionalFilter?: AdditionalParams;
    onActionClick?: () => void;
  }>();
  const route = useRoute();
  const router = useRouter();

  const store = useStore();
  const currentPage = ref(1);
  const userActionModal = ref(false);
  const notificationActionModal = ref(false);
  const selectedId = ref<string>('');
  const activeButton = ref<string>('');
  const showNoCommentsModal = ref(false);
  const filterParams = ref(
    new FilterParams(
      1,
      10,
      { searchTerm: '' },
      tableConfigurations[props.dataType]?.defaultFilters ??
        props.dynamicDefaultFilter ??
        [],
      props.dynamicAdditionalFilter || undefined,
    ),
  );
  const statusBarSelection = ref();
  const selectedOptions = ref<string[]>([]);

  const showResourcesModal = ref(false);

  const actionButton = ref<any>(null);

  const tableActionButtons = () => {
    const buttonCount =
      tableConfigurations[props.dataType]?.tableActionButtonProps?.length ?? 0;
    const buttons =
      tableConfigurations[props.dataType]?.tableActionButtonProps ?? [];

    if (buttonCount > 0) {
      if (buttonCount == 1 && selectedOptions.value.length == 0) {
        actionButton.value = null;
      } else if (selectedOptions.value.length > 0 && buttonCount > 1) {
        actionButton.value = buttons[1];
      } else {
        actionButton.value = buttons[0];
      }
    } else {
      actionButton.value = null;
    }
  };

  const columnConfiguration = ref<ColumnConfigurationInterface[]>(
    tableConfigurations[props.dataType].columns,
  );

  const noDataStateProps = computed<NoDataStateProps>(() => {
    return tableConfigurations[props.dataType].noDataStateProps;
  });

  const rowsData = computed(() => {
    return store.getters[
      `${tableConfigurations[props.dataType].storeActions.items}`
    ];
  });

  const totalPages = computed(() => {
    return store.getters[
      `${tableConfigurations[props.dataType].storeActions.totalPages}`
    ];
  });

  let isUpdating = false;

  watch(selectedOptions, (newVal) => {
    tableActionButtons();
  });

  function getFilterParams(): FilterParams {
    const newFilterParams = structuredClone(filterParams.value);
    newFilterParams.searchParamsAND = newFilterParams.searchParamsAND?.filter(
      (item) => {
        return (
          item.searchField != tableConfigurations[props.dataType].tabs.field
        );
      },
    );

    if (statusBarSelection.value && statusBarSelection.value.searchTerm != '') {
      newFilterParams.searchParamsAND?.push(statusBarSelection.value);
    }

    newFilterParams.searchParamsAND = [
      ...(newFilterParams.searchParamsAND || []),
      ...(tableConfigurations[props.dataType]?.defaultFilters || []),
      ...(props.dynamicDefaultFilter || []),
    ];

    if (props.dynamicAdditionalFilter) {
      newFilterParams.additionalParams = props.dynamicAdditionalFilter;
    }

    if (statusBarSelection.value && statusBarSelection.value.searchTerm != '') {
      if (newFilterParams.searchParamsAND) {
        newFilterParams.searchParamsAND?.push(statusBarSelection.value);
      } else {
        newFilterParams.searchParamsAND = [statusBarSelection.value];
      }
    }

    return newFilterParams;
  }

  const createActionButtuon = (
    buttonName: string,
    dataModel: string,
    actionName: string,
  ) => {
    columnConfiguration.value = [
      ...tableConfigurations[props.dataType].columns,
      {
        actionName: buttonName,
        actionHandler: async (data: any) => {
          await store.dispatch(dataModel + actionName, {
            id: data[`${dataModel}_id`],
            filters: { ...filterParams.value },
          });
        },
      },
    ];
  };

  const openCommentsModal = (
    dataType: string,
    id: string,
    isCommentColumn: boolean,
  ) => {
    if (isCommentColumn) {
      selectedId.value = id;
      showNoCommentsModal.value = true;
    } else {
      return;
    }
  };

  watch(
    filterParams,
    (newVal) => {
      if (isUpdating) return;
      const newFilterParams = getFilterParams();

      isUpdating = true;
      currentPage.value = newFilterParams.page;
      fetchData(newFilterParams).finally(() => {
        isUpdating = false;
      });
    },
    { deep: true },
  );

  watch(statusBarSelection, (newVal) => {
    if (
      ['Article', 'Video', 'Audio', 'Group', 'User', 'Collection'].includes(
        props.dataType,
      )
    ) {
      const dataModel = ['Article', 'Video', 'Audio'].includes(props.dataType)
        ? 'resource'
        : props.dataType.toLowerCase();

      switch (statusBarSelection.value.searchTerm) {
        case 'draft':
          createActionButtuon('Publish', dataModel, '/publishItem');
          break;
        case 'unpublished':
        case 'banned':
          createActionButtuon('Restore', dataModel, '/restoreItem');
          break;
        case 'inactive':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) => column.title != 'Actions',
            ),
          ];
          break;
        default:
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns,
          ];
          break;
      }
    }
    if (props.dataType == 'Notification') {
      switch (statusBarSelection.value.searchTerm) {
        case 'sent':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) =>
                column.title != 'Scheduled' &&
                column.title != 'Repeat' &&
                column.title != 'Last Modified',
            ),
          ];
          break;
        case 'draft':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) =>
                column.title != 'Scheduled' &&
                column.title != 'Repeat' &&
                column.title != 'Time Sent' &&
                column.title != 'Last Modified',
            ),
          ];
          break;
        case 'scheduled':
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns.filter(
              (column) => column.title != 'Time Sent',
            ),
          ];
          break;
        default:
          columnConfiguration.value = [
            ...tableConfigurations[props.dataType].columns,
          ];
          break;
      }
    }

    const newFilterParams = filterParams.value;
    newFilterParams.page = 1;
    filterParams.value = { ...newFilterParams };
  });

  const deleteComment = (comment_id, event) => {
    event.stopPropagation();

    store.dispatch('comment/deleteItems', { data: [comment_id] });
    fetchData(getFilterParams());
  };

  const openActionModal = (actionType: string, id: string, event) => {    
    if (actionType == 'edit') {
      if (props.dataType == 'Notification') {
        openNotificationActionsModal(id, event);
      } else {
        openUserActionsModal(id, event);
      }
    } else {
      deleteComment(id, event);
    }
  };

  const setPage = (page: number) => {
    if (page >= 1 && page <= totalPages.value) {
      currentPage.value = page;
      filterParams.value.page = page;
    }
  };

  const visiblePages = computed(() => {
    const pages: (number | string)[] = [];
    const current = currentPage.value;
    const total = totalPages.value;
    const delta = 2;

    if (total <= 1) return [1];

    pages.push(1);

    const rangeStart = Math.max(2, current - delta);
    const rangeEnd = Math.min(total - 1, current + delta);

    if (rangeStart > 2) {
      pages.push('...');
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i);
    }

    if (rangeEnd < total - 1) {
      pages.push('...');
    }

    pages.push(total);

    return pages;
  });

  const fetchData = async (params: FilterParams = getFilterParams()) => {
    await tableConfigurations[props.dataType].fetchData(store, params);
  };

  // Fetch data when the component is mounted
  onMounted(() => {
    tableActionButtons();
    let params = JSON.parse(JSON.stringify(filterParams.value));
    if (props.dataType === 'Notification') {
      columnConfiguration.value = [
        ...tableConfigurations[props.dataType].columns.filter(
          (column) =>
            column.title != 'Scheduled' &&
            column.title != 'Repeat' &&
            column.title != 'Last Modified',
        ),
      ];
      params.searchParamsAND = [
        { searchTerm: 'sent', searchField: 'status', action: 'equals' },
      ];
    }
    fetchData(params);
  });

  const getProperty = (element, propName) => {
    if (!element || typeof element !== 'object') {
      return element;
    }
    return resolveProperty(element, propName.split('.'));
  };
  const resolveUpdatedAt = (obj: any): string => {
    var label = 'Last Modified: ';
    if (obj instanceof Resource) {
      if (obj.is_published) {
        label = 'Published: ';
      }
    }

    const date = new Date(obj.updated_at);
    const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '/');

    return label + formattedDate;
  };

  const resolveDate = (date: Date): string => {
    if (!date) {
      return '';
    }
    const dateObj = new Date(date);
    const formattedDate = dateObj
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '/');

    return formattedDate;
  };

  const resolveDateTime = (dateTime: Date): string => {
    const time = new Date(dateTime);
    const date = resolveDate(dateTime);
    return (
      date +
      ' ,' +
      time.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    );
  };

  const cutArray = (array, showMax) => {
    if (!showMax) {
      return array;
    }
    if (array.length > showMax) {
      const cutArray = array.slice(0, showMax - 1);
      cutArray.push(`+${array.length - showMax + 1}`);
      return cutArray;
    }
    return array;
  };

  const resolveProperty = (obj, propNameSplit): string => {
    if (propNameSplit.length > 1) {
      return resolveProperty(obj[propNameSplit[0]], propNameSplit.slice(1));
    } else {
      return obj[propNameSplit[0]];
    }
  };

  const openNotificationActionsModal = (notification_id, event) => {
    event.stopPropagation();
    notificationActionModal.value = true;
    selectedId.value = notification_id;
  };

  const handleNotificationAction = () => {
    notificationActionModal.value = false;
  };

  const openUserActionsModal = (user_id, event) => {
    event.stopPropagation();
    store.dispatch('user/selectUser', user_id);
    userActionModal.value = true;
    selectedId.value = user_id;
  };

  const handleUserAction = (action) => {
    store.dispatch('user/' + action, {
      id: selectedId.value,
      filters: filterParams.value || {},
    });
    userActionModal.value = false;
  };

  const settingsOnClick = (url, id) => {
    router.push({ path: url, query: { id } });
  };

  const emitSelectedOptions = () => {
    emits('update:selectedRows', selectedOptions.value);
  };

  const openResourcesModal = () => {
    showResourcesModal.value = true;
  };

  const deleteResources = () => {
    console.log('deleteResources');
  };

  const deleteSelected = () => {
    let payload = {
      data: selectedOptions.value,
    };
    if (
      (props.dataType === 'ConnectedResources' ||
        props.dataType === 'NotConnectedResources') &&
      filterParams.value.additionalParams
    ) {
      payload = {
        ...payload,
        [filterParams.value.additionalParams.name as string]: route.query.id,
      };
    }
    if (!tableConfigurations[props.dataType].storeActions.deleteAction) {
      console.error(
        'To use table delete functionality you have to provide deleteAction in storeActions for the table configuration.',
      );
    }
    store
      .dispatch(
        `${tableConfigurations[props.dataType].storeActions.deleteAction}`,
        payload,
      )
      .then(() => {
        fetchData(getFilterParams());
        selectedOptions.value = [];
      });
  };

  const handleClick = (buttonProps) => {
    const functionsMap = {
      deleteSelected,
      openResourcesModal,
      deleteResources,
    };
    if (buttonProps.action != undefined) {
      const fnName = buttonProps.action;
      if (functionsMap[fnName]) {
        functionsMap[fnName]();
        if (fnName === 'openResourcesModal' && props.onActionClick) {
          props.onActionClick();
        }
      } else {
        console.error(`Function ${fnName} not found!`);
      }
    } else {
      router.push(buttonProps.route);
    }
  };

  const refreshSentNotifications = () => {
  statusBarSelection.value = {
    searchTerm: 'sent',
    searchField: 'status',
    action: 'equals'
  };
  
  fetchData(getFilterParams());
};
</script>

<template>
  <ViewCommentsMolecule
    :show="showNoCommentsModal"
    @update:show="showNoCommentsModal = $event"
    :resource_id="selectedId"
    v-if="showNoCommentsModal"
  />
  <TabbarOrganism
    v-if="tableConfigurations[props.dataType].tabs"
    :buttons="tableConfigurations[props.dataType].tabs.fields"
    :countGetter="tableConfigurations[props.dataType].tabs.countGetter"
    :detailsButtonprops="actionButton"
    v-model="statusBarSelection"
    v-model:activeButton="activeButton"
    @buttonClick="handleClick"
    :filterField="tableConfigurations[props.dataType].tabs.field"
  />
  <SearchbarMolecule
    v-model="filterParams"
    :showFilter="
      tableConfigurations[props.dataType].filterSettings?.title ?? false
    "
    :filterTitle="
      tableConfigurations[props.dataType].filterSettings?.title ?? ''
    "
    :filterOptions="props.filterOptions"
  />

  <NoDataMolecule
    v-if="!rowsData || rowsData.length == 0"
    :noDataButtonProps="noDataStateProps"
    icon="bookIcon"
    @buttonClick="handleClick"
  />
  <div v-else>
    <table>
      <thead>
        <tr>
          <th v-if="tableConfigurations[props.dataType].rowSelection"></th>
          <th v-for="column in columnConfiguration" :key="column.propertyName">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rowsData" :key="row.id">
          <td v-if="tableConfigurations[props.dataType].rowSelection">
            <div class="flex-container">
              <!-- Wrap the checkbox input and custom checkbox in a label -->
              <label class="checkbox-label">
                <input
                  type="checkbox"
                  class="table-row-checkbox-input"
                  :value="
                    row[
                      tableConfigurations[props.dataType].rowSelection
                        .rowSelectionProperty
                    ]
                  "
                  v-model="selectedOptions"
                  @change="emitSelectedOptions"
                />
                <span class="table-custom-checkbox"></span>
                <span class="table-checkbox-label"></span>
              </label>
            </div>
          </td>
          <td v-for="column in columnConfiguration" :key="column.propertyName">
            <template v-if="column.settings">
              <IconAtom
                icon="settingIcon"
                @click="
                  settingsOnClick(
                    column.settingsUrl,
                    row[column.settingsIdProperty],
                  )
                "
              >
              </IconAtom>
            </template>
            <template v-if="column.action">
              <IconAtom
                :icon="
                  column.action == 'edit' ||
                  column.action == 'notification_action'
                    ? 'threeDotsIcon'
                    : 'deleteIcon'
                "
                class="three-dots-icon"
                @click.stop="
                  openActionModal(
                    column.action,
                    row[column.settingsIdProperty],
                    $event,
                  )
                "
              >
              </IconAtom>
              <UserActionModal
                v-if="
                  userActionModal &&
                  row[column.settingsIdProperty] == selectedId
                "
                @close="userActionModal = false"
                @action="handleUserAction"
                :is-visible="userActionModal"
              ></UserActionModal>
              <NotificationActionModal
                v-if="
                  notificationActionModal &&
                  row[column.settingsIdProperty] == selectedId
                 "
                @close="notificationActionModal = false"
                @action="handleNotificationAction"
                @refresh="refreshSentNotifications()"
                :is-visible="notificationActionModal"
                :activeButton="activeButton"
                :notification-id="selectedId"
              >
            </NotificationActionModal>
            </template>
            <template v-if="column.actionName">
              <ButtonAtom
                class="btn btn-secondary"
                @click="column.actionHandler(row)"
              >
                {{ column.actionName }}
              </ButtonAtom>
            </template>
            <template v-else>
              <div
                class="flex-container"
                @click="
                  openCommentsModal(
                    props.dataType,
                    row[
                      tableConfigurations[props.dataType].rowSelection
                        ?.rowSelectionProperty
                    ],
                    column.title === 'Comments' ? true : false,
                  )
                "
              >
                <span v-if="column.imageProperty && row[column.imageProperty]">
                  <img
                    :class="column.userImage ? 'user-image' : 'row-image'"
                    :src="row[column.imageProperty]"
                  />
                </span>
                <span v-if="column.imageProperty && !row[column.imageProperty]">
                  <img
                    :class="column.userImage ? 'user-image' : 'row-image'"
                    :src="column.userImage ? userDefaultImage : defaultImage"
                  />
                </span>
                <template v-if="column.icon != null">
                  <IconAtom :icon="column.icon"></IconAtom>
                </template>
                <!-- Handling Arrays -->
                <span
                  class="flex-container"
                  v-if="Array.isArray(row[column.propertyName])"
                >
                  <div
                    :class="[column.class, 'row-text']"
                    v-for="(element, index) in cutArray(
                      row[column.propertyName],
                      column.arrayShowMax,
                    )"
                    :key="index"
                  >
                    {{ getProperty(element, column.visibleProperty) }}
                  </div>
                </span>

                <!-- Handling Objects -->
                <span
                  class="flex-container"
                  v-else-if="typeof row[column.propertyName] === 'object'"
                >
                  <div
                    :class="[column.class, 'row-text']"
                    :key="column.propertyName"
                  >
                    {{
                      getProperty(
                        row[column.propertyName],
                        column.visibleProperty,
                      )
                    }}
                  </div>
                </span>
                <div
                  :class="[column.class, 'row-text']"
                  v-else-if="column.propertyName == 'updated_at'"
                >
                  {{ resolveUpdatedAt(row) }}
                </div>
                <div
                  :class="[column.class, 'row-text']"
                  v-else-if="column.type && column.type == 'date'"
                >
                  {{ resolveDate(row[column.propertyName]) }}
                </div>
                <div
                  :class="[column.class, 'row-text']"
                  v-else-if="column.type && column.type == 'datetime'"
                >
                  {{ resolveDateTime(row[column.propertyName]) }}
                </div>
                <div v-else-if="column.propertyName == 'user_status'">
                  <div
                    :class="{
                      'status-active-wrapper':
                        row[column.propertyName] == 'active',
                    }"
                  >
                    <span
                      class="status-text"
                      :class="{
                        'status-border': row[column.propertyName] != 'active',
                        'status-color': row[column.propertyName] == 'banned',
                      }"
                    >
                      <IconAtom
                        icon="banIcon"
                        class="status-color"
                        v-if="row[column.propertyName] == 'banned'"
                      />
                      {{ row[column.propertyName] }}</span
                    >
                  </div>
                </div>
                <div
                  :class="[column.class, 'row-text']"
                  v-else-if="
                    column.visibleOn &&
                    column.visibleOn == statusBarSelection?.searchTerm
                  "
                >
                  {{ row[column.propertyName] }}
                </div>
                <div :class="[column.class, 'row-text']" v-else>
                  {{ row[column.propertyName] }}
                </div>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="setPage(currentPage - 1)" :disabled="currentPage === 1">
        &laquo;
      </button>
      <button
        v-for="page in visiblePages"
        :key="page"
        @click="typeof page === 'number' && setPage(page)"
        :disabled="currentPage === page || typeof page !== 'number'"
        :class="{ active: currentPage === page }"
      >
        {{ page }}
      </button>
      <button
        @click="setPage(currentPage + 1)"
        :disabled="currentPage >= totalPages"
      >
        &raquo;
      </button>
    </div>
  </div>
</template>

<style scoped src="./TableMolecule.css"></style>
