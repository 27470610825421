<script setup lang="ts">
  import {
    ref,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    onBeforeUnmount,
  } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import InputSelectAtom from '@/components/atoms/InputSelectAtom.vue';
  import AddNewTagAtom from '@/components/atoms/AddNewTagAtom.vue';
  import SwitchAtom from '@/components/atoms/SwitchAtom.vue';
  import TinyEditor from '@/components/atoms/TinyEditor.vue';
  import AddToCollectionAtom from '@/components/atoms/AddToCollectionAtom.vue';
  import tagService from '@/services/tag/tag.service';
  import Tag from '@/models/tag/tag.model';
  import {
    SelectOption,
    transformToSelectOptions,
  } from '@/shared/selectOption';
  import { useStore } from 'vuex';

  const props = defineProps<{ details: any; resourceDetails: any }>();
  const emit = defineEmits(['updateDetails']);
  const store = useStore();

  const localDetails = ref({ ...props.details });
  const localResourceDetails = ref({ ...props.resourceDetails });

  const showAddNewTagModal = ref(false);
  const showAddToCollectionModal = ref(false);
  const tags = ref([]);
  const coverImage = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);
  const tagOptions = ref<SelectOption[]>([]);
  const actionTagGetter = 'collection/getCollectionTags';
  const actionTagSet = 'collection/addTag';
  const actionTagRemove = 'collection/removeTag';

  const fetchTags = async () => {
    tagOptions.value = transformToSelectOptions(
      ((await tagService.getAllNoFilter()) as any).data,
      'tag_id',
      'tag_name',
    );
  };

  onMounted(() => {
    fetchTags();
  });

  watch(
    () => props.details,
    (newValue) => {
      localDetails.value = { ...newValue };
      coverImage.value = localDetails.value.banner;
    },
    { immediate: true, deep: true },
  );

  function handleNewTagAdded(newTag: string) {
    fetchTags();
    showAddNewTagModal.value = false;
  }

  function openAddNewTagModal() {
    showAddNewTagModal.value = true;
  }

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
    localDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    localDetails.value.banner = '';
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
    }
  }

  watch(
    localDetails,
    () => {
      emit('updateDetails', localDetails);
    },
    { deep: true },
  );

  function addTagToSelection(newTag) {
    if (!localResourceDetails.value.tags) {
      localResourceDetails.value.tags = [];
    }

    if (localResourceDetails.value.tags.length < 3) {
      localResourceDetails.value.tags.push({
        id: newTag.tag_id,
        value: newTag.tag_name,
      });

      store.dispatch(actionTagSet, {
        id: newTag.tag_id,
        value: newTag.tag_name,
      });
    }
  }
</script>

<template>
  <div class="collections-organism">
    <label for="title">Add new articles details</label>

    <div class="sections-container">
      <div class="cover-section">
        <div class="cover-img">
          <ButtonAtom
            class="btn btn-secondary coverBtn"
            :style="{
              backgroundImage: coverImage ? `url(${coverImage})` : '',
              border: coverImage ? 'none' : '2px dashed #ccc',
            }"
            @click="triggerImgUpload"
          >
            <IconAtom v-if="!coverImage" icon="cameraIcon" />
            <p v-if="!coverImage">Cover</p>

            <button
              v-if="coverImage"
              class="clear-image-btn"
              @click.stop="clearImage"
            >
              X
            </button>
          </ButtonAtom>
          <input
            type="file"
            ref="uploadedImg"
            accept="image/*"
            @change="imgupload"
            style="display: none"
          />
        </div>
      </div>

      <div class="details-section">
        <div class="title">
          <label for="title">Title</label>
          <InputAtom
            id="title"
            v-model="localDetails.collection_name"
            placeholder="Name"
            class="input"
          />
        </div>

        <div class="tags">
          <label for="tags">Tags (maximum 3)</label>
          <InputSelectAtom
            id="tags"
            placeholder="Add tags"
            v-model="localDetails.collectionTag"
            :options="tagOptions"
            @addNew="openAddNewTagModal"
            :tagsGetter="actionTagGetter"
            :tagSet="actionTagSet"
            :tagRemove="actionTagRemove"
          />
          <AddNewTagAtom
            :show="showAddNewTagModal"
            @update:show="showAddNewTagModal = $event"
            @newTagAdded="handleNewTagAdded"
            @addTagToSelection="addTagToSelection"
          />
        </div>
        <SwitchAtom v-model="localDetails.is_featured" />
      </div>
    </div>
  </div>
</template>

<style src="./CollectionEditMolecule.css" scoped></style>
