<script setup lang="ts">
  import { ref, onMounted, onUnmounted, defineEmits } from 'vue';
  import InputAtom from './InputAtom.vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';

  const props = defineProps({
    show: Boolean,
  });

  const newTagName = ref('');
  const emits = defineEmits(['update:show']);

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });
</script>

<template>
  <div
    v-if="props.show"
    class="change-password-modal-overlay"
    @click="closeModal"
  >
    <div class="change-password-modal" @click.stop>
      <h3 class="change-password-title">Change Password</h3>
      <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
      <InputAtom
        id="changePassword"
        placeholder="Current Password"
        class="input"
      />
      <InputAtom id="changePassword" placeholder="New Password" class="input" />
      <InputAtom
        id="changePassword"
        placeholder="Confirm Password"
        class="input"
      />
      <ButtonAtom class="btn btn-primary">Submit</ButtonAtom>
    </div>
  </div>
</template>

<style scoped>
  .change-password-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .change-password-modal {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .change-password-title {
    margin-bottom: 24px;
    font-size: bold;
  }

  .input {
    width: 100%;
    margin-bottom: 10px;
    border: none;
    margin: 12px 0;
  }
  .btn {
    margin: auto;
    padding: 8px 32px;
  }
</style>
