import Tag from '../tag/tag.model';

export default class Resource {
  resource_id: string;
  resource_name: string;
  resource_type: string;
  use_to_train_chatbot: boolean;
  is_premium: boolean;
  is_published: boolean;
  is_deleted: boolean;
  status: string;
  url_path: string;
  text_content: string;
  banner: string;
  captions_url: string;
  created_at: Date;
  updated_at: Date;
  tags?: Tag[];
  comment_count: number;

  constructor(data: Partial<Resource>) {
    this.resource_id = data.resource_id || '';
    this.resource_name = data.resource_name || '';
    this.resource_type = data.resource_type || '';
    this.use_to_train_chatbot = data.use_to_train_chatbot || true;
    this.is_premium = data.is_premium || false;
    this.is_published = data.is_published || false;
    this.is_deleted = data.is_deleted || false;
    this.status = data.status || '';
    this.url_path = data.url_path || '';
    this.text_content = data.text_content || '';
    this.banner = data.banner || '';
    this.captions_url = data.captions_url || '';
    this.created_at = data.created_at ? new Date(data.created_at) : new Date();
    this.updated_at = data.updated_at ? new Date(data.updated_at) : new Date();
    this.comment_count = data.comment_count || 0;
  }
}
