<script setup lang="ts">
import { ref } from "vue";
import { defineProps, defineEmits } from "vue";
import InputAtom from "@/components/atoms/InputAtom.vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  title: String,
  label: String,
  id: String,
  placeholder: String,
  modelValue: String,
});

const localModelValue = ref(props.modelValue);

const updateValue = (value) => {
  localModelValue.value = value;
  emit("update:modelValue", value);
};
</script>

<template>
  <div class="input-molecule-container">
    <div class="input-molecule">
      <h4>{{ props.title }}</h4>
      <label for="props.id">{{ props.label }}</label>
      <InputAtom
        :id="props.id"
        :placeholder="props.placeholder"
        :modelValue="localModelValue"
        @update:modelValue="updateValue"
      />
    </div>
  </div>
</template>

<style scoped src="./InputMolecule.css"></style>
