<script setup lang="ts">
  import { ref, watch, defineProps, defineEmits, onMounted } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import ButtonAtom from '@/components/atoms/ButtonAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import DropdownAtom from '@/components/atoms/DropdownAtom.vue';

  const props = defineProps<{ details: any }>();
  const emit = defineEmits(['updateDetails']);

  const localDetails = ref({ ...props.details });
  const coverImage = ref<string | null>(null);
  const uploadedImg = ref<HTMLInputElement | null>(null);

  watch(
    () => props.details,
    (newValue) => {
      localDetails.value = { ...newValue };
      coverImage.value = localDetails.value.banner;
    },
    { immediate: true, deep: true },
  );

  function triggerImgUpload() {
    uploadedImg.value?.click();
  }

  function imgupload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      coverImage.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
    localDetails.value.banner = file;
  }

  function clearImage() {
    coverImage.value = null;
    localDetails.value.banner = '';
    if (uploadedImg.value) {
      uploadedImg.value.value = '';
    }
  }

  watch(
    localDetails,
    () => {
      emit('updateDetails', localDetails);
    },
    { deep: true },
  );
</script>

<template>
  <div class="notifications-molecule">
    <label for="title">Notification details</label>

    <div class="sections-container">
      <div class="cover-section">
        <div class="cover-img">
          <ButtonAtom
            class="btn btn-secondary coverBtn"
            :style="{
              backgroundImage: coverImage ? `url(${coverImage})` : '',
              border: coverImage ? 'none' : '2px dashed #ccc',
            }"
            @click="triggerImgUpload"
          >
            <IconAtom v-if="!coverImage" icon="cameraIcon" />
            <p v-if="!coverImage">Cover</p>

            <button
              v-if="coverImage"
              class="clear-image-btn"
              @click.stop="clearImage"
            >
              X
            </button>
          </ButtonAtom>
          <input
            type="file"
            ref="uploadedImg"
            accept="image/*"
            @change="imgupload"
            style="display: none"
          />
        </div>
      </div>

      <div class="details-section">
        <div class="title">
          <label for="title">Title</label>
          <InputAtom
            id="title"
            v-model="localDetails.title"
            placeholder="Title"
            class="input"
          />
        </div>
        <div class="description">
          <label for="message">Message</label>
          <textarea
            id="message"
            placeholder="Text"
            v-model="localDetails.message"
            class="description-textarea"
          />
        </div>
        <div class="title">
          <label for="url">URL Link</label>
          <InputAtom
            id="url"
            v-model="localDetails.url"
            placeholder="Link"
            class="input"
          />
        </div>

        <div class="title">
          <label for="url">Send to</label>
          <DropdownAtom
            id="sendTo"
            :model-value="localDetails.send_to"
            class="input"
          ></DropdownAtom>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./NotificationFormMolecule.css" scoped></style>
