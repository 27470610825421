<template>
  <Bar
    :options="{
      indexAxis: orientation === 'horizontal' ? 'y' : 'x',
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          suggestedMax:
            Math.max(...props.data.map((dataSet) => dataSet[dataKey])) * 1.2,
          title: { text: xAxisTitle ?? '', display: xAxisTitle !== undefined },
          grid: {
            display: orientation === 'horizontal',
          },
        },
        y: {
          suggestedMax:
            Math.max(...props.data.map((dataSet) => dataSet[dataKey])) * 1.2,
          title: {
            text: yAxisTitle ?? '',
            display: yAxisTitle !== undefined,
          },
          grid: {
            display: orientation === 'vertical',
          },
        },
      },
    }"
    :data="{
      labels: props.data.map(
        (dataArr) =>
          dataArr[labelKey].charAt(0).toUpperCase() +
          dataArr[labelKey].substring(1),
      ),
      datasets: [
        {
          data: props.data.map((dataSet) => dataSet[dataKey]),
          backgroundColor: props.data.map((i) => props.barColor),
          barThickness: props.barThickness,
        },
      ],
    }"
  />
</template>

<script setup lang="ts">
  import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    scales,
  } from 'chart.js';

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  );
  const props = withDefaults(
    defineProps<{
      orientation?: 'horizontal' | 'vertical';
      data: any[];
      xAxisTitle?: string;
      yAxisTitle?: string;
      dataKey?: string;
      labelKey?: string;
      barColor?: string;
      barThickness?: number;
    }>(),
    {
      orientation: 'vertical',
      dataKey: 'data',
      labelKey: 'label',
      barColor: '#1D43A6',
      barThickness: 40,
    },
  );
</script>
