<template>
    <div class="analytics-container">
        <UsersAnalyticsOrganism />
    </div>
</template>

<script setup lang="ts">
import UsersAnalyticsOrganism from '@/components/organisms/users-analytics/UsersAnalyticsOrganism.vue';
import store from '@/store';
import { onBeforeMount } from 'vue';
onBeforeMount(() => {
    store.dispatch('analytics/fetchAnalyticsData')
})
</script>