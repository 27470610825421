<script setup lang="ts">
import { computed, useSlots } from "vue";
import IconAtom from "@/components/atoms/IconAtom.vue";
import LinkAtom from "@/components/atoms/LinkAtom.vue";

const props = defineProps({
  text: String,
  icon: String,
  isOpen: Boolean,
  isActive: Boolean,
});

const slots = useSlots();

const hasAtoms = computed(() => !!slots.default?.().length);
</script>

<template>
  <div class="sidebar-item-wrapper">
    <div
      class="sidebar-item-main"
      :class="{ open: props.isOpen, active: props.isActive }"
      @click="$emit('toggle')"
    >
      <IconAtom :icon="props.icon" />
      <LinkAtom class="sidebar-item-title" :text="props.text" />
      <IconAtom
        v-if="hasAtoms"
        :icon="props.isOpen ? 'angleUp' : 'angleDown'"
        color="white"
        class="toggle-icon"
      />
    </div>
    <div v-if="props.isOpen && hasAtoms" class="sidebar-subitems-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<style Scoped src="./SidebarItemMolecule.css"></style>
