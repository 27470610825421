<template>
  <div class="dropdown-container">
    <div class="dropdown" @click="toggleModal">
      <span>{{ selectedOption }}</span>
      <span class="arrow">&#9662;</span>
    </div>

    <div v-show="isModalOpen" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="input-container">
          <IconAtom icon="searchIcon" color="#333" class="search-icon" />
          <InputAtom
            class="search-input"
            placeholder="Search..."
            v-model="searchQuery"
          />
        </div>
        <ul class="options-list">
          <li :key="'everyone'" class="option-item">
            <label>
              <label class="checkbox-label">
                <input
                  type="checkbox"
                  class="checkbox-input"
                  v-model="isEveryoneSelected"
                  @change="onEveryoneChange"
                />
                <span class="custom-checkbox"></span>
                <span class="checkbox-label"></span>
              </label>
              <span>{{ 'Everyone' }}</span>
            </label>
          </li>
          <li v-for="option in users" :key="option.user_id" class="option-item">
            <label>
              <label class="checkbox-label">
                <input
                  type="checkbox"
                  class="checkbox-input"
                  :value="option"
                  v-model="selectedOptions"
                  @change="onUserOptionChange"
                />
                <span class="custom-checkbox"></span>
                <span class="checkbox-label"></span>
              </label>
              <img
                :src="option.user_photo ? option.user_photo : userDefaultImage"
                alt=""
                class="avatar"
              />
              <span>{{ option.full_name }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch, onMounted } from 'vue';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import InputAtom from '@/components/atoms/InputAtom.vue';
  import { useStore } from 'vuex';
  import userDefaultImage from '@/assets/default-user-img.png';
  import { FilterParams } from '@/shared/filterParams';
  import User from '@/models/user/user.model';

  const isModalOpen = ref(false);
  const searchQuery = ref('');
  const isEveryoneSelected = ref(false);
  const selectedOptions = ref<User[]>([]);
  const store = useStore();
  const props = defineProps({
    modelValue: Array,
  });
  const filters = ref<FilterParams>({
    page: 1,
    pageSize: 5,
    searchParamsOR: { searchTerm: '' },
    searchParamsAND: [
      { searchTerm: 'active', searchField: 'user_status', action: 'equals' },
    ],
  });

  const users = computed(() => store.getters['user/getItems']);
  const notification = computed(
    () => store.getters['notification/getNotification'],
  );

  const initializeSelectedOptions = (sendTo = props.modelValue) => {
    if (sendTo && typeof sendTo[0] === 'object' && sendTo[0].user_id) {
      sendTo = sendTo.map(item => item.user_id);
    }

    if (sendTo?.includes('Everyone')) {
      isEveryoneSelected.value = true;
      selectedOptions.value = [];
    } else {
      isEveryoneSelected.value = false;
      selectedOptions.value = users.value.filter((user) =>
        sendTo?.includes(user.user_id),
      );
    }
  };

  onMounted(() => {
    store.dispatch('user/fetchUsers', filters.value);
    initializeSelectedOptions();
  });

  watch(
    () => props.modelValue,
    (newSendTo) => {
      initializeSelectedOptions(newSendTo);
    },
    { immediate: true },
  );

  watch(searchQuery, (newValue) => {
    filters.value.searchParamsOR.searchTerm = newValue;
    store.dispatch('user/fetchUsers', filters.value);
  });

  const selectedOption = computed(() => {
    if (isEveryoneSelected.value) {
      return 'Everyone';
    }
    if (selectedOptions.value.length === 1) {
      return selectedOptions.value[0].full_name;
    }
    return `${selectedOptions.value.length} selected`;
  });

  const toggleModal = () => {
    isModalOpen.value = !isModalOpen.value;
  };

  const closeModal = () => {
    isModalOpen.value = false;
  };

  const onEveryoneChange = () => {
    if (isEveryoneSelected.value) {
      store.commit('notification/SET_NOTIFICATION', {
        ...notification.value,
        send_to_all: true,
        send_to: [],
      });
      selectedOptions.value = [];
    }
  };

  const onUserOptionChange = () => {
    if (selectedOptions.value.length > 0) {
      const userIds = selectedOptions.value.map((option) => option.user_id);
      store.commit('notification/SET_NOTIFICATION', {
        ...notification.value,
        send_to_all: false,
        send_to: userIds,
      });
      isEveryoneSelected.value = false;
    }
  };
</script>

<style scoped>
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 8px;
  }

  .dropdown-container {
    position: relative;
    width: 100%;
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-30);
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  .modal-overlay {
    position: absolute;
    width: 100%;
    transform: translateY(10px);
  }

  .modal-content {
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .search-input {
    background-color: var(--color-gray-30);
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: 0px solid #ccc;
    border-radius: 15px;
    padding-left: 40px;
  }

  .options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .option-item {
    display: flex;
    align-items: center;
    padding: 5px;
    position: relative;
  }

  .option-item label {
    display: flex;
    padding: 5px;
    align-items: center;
  }

  .option-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110%;
    transform: translateX(-15px);
    height: 1px;
    background-color: var(--color-gray-50);
  }

  .option-item:last-child::after {
    display: none;
  }

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .arrow {
    font-size: 15px;
  }

  .checkbox-input {
    display: none;
  }

  .custom-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid var(--color-gray-50);
    position: relative;
    margin-right: 8px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox-input:checked + .custom-checkbox::after {
    content: '✔';
    font-size: 14px;
    color: white;
  }

  .checkbox-input:checked + .custom-checkbox {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }

  .custom-checkbox:hover {
    border-color: var(--color-gray-50);
    background-color: var(--color-gray-50);
  }

  .checkbox-label {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-dark);
    cursor: pointer;
  }
</style>
