<script setup>
const props = defineProps({
  text: String,
});
</script>

<template>
  <div>
    <span>{{ props.text }}</span>
  </div>
</template>

<style></style>
