<script setup lang="ts">
  import { ref, onMounted, onUnmounted, defineEmits } from 'vue';
  import ButtonAtom from './ButtonAtom.vue';
  import IconAtom from './IconAtom.vue';
  import SendNotificationModal from './SendNotificationModal.vue';

  const props = defineProps({
    show: Boolean,
  });

  const showSendNotification = ref(false);

  const emits = defineEmits(['update:show']);

  const closeModal = () => {
    emits('update:show', false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  const handleClick = () => {
    showSendNotification.value = true;
    closeModal();
  };
</script>

<template>
  <div
    v-if="props.show"
    class="push-notifications-modal-overlay"
    @click="closeModal"
  >
    <div class="push-notifications-modal" @click.stop>
      <h3 class="push-notifications-modal-title">Personal Push Notification</h3>
      <IconAtom class="close-icon" icon="closeIcon" @click="closeModal" />
      <div class="container">
        <div class="table"></div>
        <div class="buttons">
          <ButtonAtom class="btn btn-secondary" @click="closeModal"
            >Cancel</ButtonAtom
          >
          <ButtonAtom class="btn btn-primary" @click="handleClick()"
            >Send personal notification</ButtonAtom
          >
        </div>
      </div>
    </div>
  </div>
  <SendNotificationModal
    :show="showSendNotification"
    @update:show="showSendNotification = $event"
  />
</template>

<style scoped>
  .push-notifications-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .push-notifications-modal {
    padding: 32px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 910px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 8px;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .push-notifications-modal-title {
    margin-bottom: 24px;
    font-size: bold;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .table {
    border: black solid 1px;
    height: 400px;
    width: 100%;
    border-radius: 16px;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  .btn-secondary {
    width: 130px;
    height: 44px;
  }

  .btn-primary {
    width: 227px;
    height: 44px;
  }
</style>
