<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import UserImgAtom from '@/components/atoms/UserImgAtom.vue';
  import userImgSrc from '@/assets/user-default.png';
  import IconAtom from '@/components/atoms/IconAtom.vue';
  import SignOutMolecule from '../sign-out-molecule/SignOutMolecule.vue';
  import ChangePasswordModal from '@/components/atoms/ChangePasswordModal.vue';

  const isVisible = ref(false);
  const showChangePasswordModal = ref(false);
  const wrapperRef = ref(null);

  const handleClick = () => {
    isVisible.value = !isVisible.value;
  };

  const handleClickOutside = (event) => {
    if (
      isVisible.value &&
      wrapperRef.value &&
      !wrapperRef.value.contains(event.target)
    ) {
      isVisible.value = false;
    }
  };

  const handleChangePassword = () => {
    showChangePasswordModal.value = true;
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
</script>

<template>
  <div class="topbar-section-userAcc" ref="wrapperRef" @click="handleClick">
    <UserImgAtom :src="userImgSrc" />
    <IconAtom
      :icon="isVisible ? 'angleUp' : 'angleDown'"
      @click.stop="handleClick"
    />
  </div>
  <SignOutMolecule
    v-if="isVisible"
    @close="isVisible = false"
    @change-password="handleChangePassword"
  />
  <ChangePasswordModal
    v-if="showChangePasswordModal"
    :show="showChangePasswordModal"
    @update:show="showChangePasswordModal = $event"
  />
</template>

<style scoped>
  .topbar-section-userAcc {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    border: 2px solid #e6e7e8;
    border-radius: 25px;
    height: 44px;
    cursor: pointer;
    background-color: #fff;
    position: relative; /* Ensure positioning context */
    z-index: 100;
  }
</style>
