import localStorageService from '@/shared/localStorageService';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import router from '@/router';

export type AuthData = {
  access_token: string;
  refresh_token: string;
};

export type ResponseData<T> = {
  data: T;
  resultCount: number;
  totalPages?: number;
};

export type APIResponse<T> = AxiosResponse<ResponseData<T>>;

class APIService {
  private static instance: APIService;
  apiClient: AxiosInstance;

  constructor(useInterceptors = true) {
    this.apiClient = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (useInterceptors) {
      this.apiClient.interceptors.request.use(
        (config) => {
          if (!config.headers['Authorization']) {
            const token = this.getAuthHeadersFromStorage();
            if (token) {
              config.headers['Authorization'] = token;
            }
          }
          return config;
        },
        (error) => Promise.reject(error),
      );

      this.apiClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          const originalRequest = error.config;
          if (error.response.status === 401 && !originalRequest._retry) {
            if (this.isRefreshTokenExpired()) {
              await this.redirectToLogin();
              return Promise.reject(error);
            }
            originalRequest._retry = true;
            return this.handle401Error(originalRequest);
          }
          return Promise.reject(error);
        },
      );
    }
  }

  private isRefreshTokenExpired() {
    const authTokens = localStorageService.getValue<AuthData>('auth');
    const token = authTokens?.refresh_token;
    if (!token) return true; // Assume expired if no token is present

    try {
      const decoded = jwtDecode(token);
      const now = Date.now() / 1000; // JS works in milliseconds, token in seconds

      return (decoded.exp ?? 0) < now;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return true; // Assume expired on any error
    }
  }

  public static getInstance(): APIService {
    if (!APIService.instance) {
      APIService.instance = new APIService();
    }

    return APIService.instance;
  }

  private async handle401Error(originalRequest: AxiosRequestConfig) {
    const authTokens = localStorageService.getValue<AuthData>('auth');
    const refreshToken = authTokens?.refresh_token;

    if (!refreshToken) {
      await this.redirectToLogin();
      return Promise.reject(originalRequest);
    }

    try {
      const response = await this.apiClient.post<ResponseData<AuthData>>(
        '/auth/refresh-tokens',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${refreshToken}`,
          },
        },
      );

      const authData = response.data.data;
      await this.storeAuthData(authData);
      delete originalRequest.headers?.Authorization;
      // Retry the original request with the new access token
      return this.apiClient(originalRequest);
    } catch (refreshError) {
      console.error('Token refresh failed:', refreshError);
      await this.redirectToLogin();
      return Promise.reject(refreshError);
    }
  }

  private async redirectToLogin() {
    localStorageService.deleteFromStorage('auth');
    window.location.reload();
  }

  public getAuthHeadersFromStorage(): string | undefined {
    const authData = localStorageService.getValue<AuthData>('auth');

    if (authData?.access_token) {
      return `Bearer ${authData.access_token}`;
    }

    return undefined;
  }

  public storeAuthData(authData: AuthData): void {
    localStorageService.setValue('auth', authData);
    this.setAuthHeaders(authData.access_token);
  }

  public setAuthHeaders(jwtToken: string): void {
    this.apiClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${jwtToken}`;
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.apiClient.get(url, config);
    return response.data;
  }

  public async post<T>(url: string, data: any): Promise<T> {
    let config = {};

    if (data instanceof FormData) {
      config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    } else if (typeof data === 'object' && data !== null) {
      config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    const response: AxiosResponse<T> = await this.apiClient.post(
      url,
      data,
      config,
    );
    return response.data;
  }

  public async put<T>(url: string, data: Record<string, any>): Promise<T> {
    const response: AxiosResponse<T> = await this.apiClient.put(url, data);
    return response.data;
  }

  public patch<T>(
    url: string,
    data: Record<string, any>,
  ): Promise<AxiosResponse<T>> {
    return this.apiClient.patch(url, data);
  }

  public delete<T>(url: string): Promise<AxiosResponse<T>> {
    return this.apiClient.delete(url);
  }
}

export default APIService.getInstance();
