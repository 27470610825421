export class SearchParamsOR {
  constructor(public searchTerm: string) {}
}

export class SearchParamsAND {
  constructor(
    public searchTerm: string,
    public searchField: string,
    public action?: string,
  ) {}
}

export class AdditionalParams {
  constructor(
    public table: string,
    public name: string,
    public value: string,
    public action: string,
  ) {}
}

export class SortParams {
  constructor(public sortBy: string, public sortOrder: string) {}
}
export class FilterParams {
  constructor(
    public page: number,
    public pageSize: number,
    public searchParamsOR?: SearchParamsOR,
    public searchParamsAND?: SearchParamsAND[],
    public additionalParams?: AdditionalParams,
    public sortParams?: SortParams,
  ) {}
}
