import Tag from '@/models/tag/tag.model';
import APIService from '../api.service';
import { FilterParams } from '@/shared/filterParams';

class TagService {
  public async getAll(data: FilterParams): Promise<Tag[]> {
    return APIService.post<Tag[]>(`/tags/search`, data);
  }

  public async getAllNoFilter(): Promise<Tag[]> {
    return APIService.get<Tag[]>(`/tags`);
  }

  public async getById(id: string): Promise<Tag> {
    return APIService.get<Tag>(`/tags/${id}`);
  }

  public async create(data: any): Promise<Tag> {
    return APIService.post<Tag>(`/tags`, data);
  }

  public async update(data: Tag): Promise<Tag> {
    return APIService.post(`/tags/update/${data.tag_id}`, data);
  }

  public async delete(id: string): Promise<any> {
    return APIService.delete(`/tags/${id}`);
  }
}

export default new TagService();
