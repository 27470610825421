<script setup lang="ts">
import IconAtom from "@/components/atoms/IconAtom.vue";
import ButtonAtom from "@/components/atoms/ButtonAtom.vue";
import { RouterLink } from "vue-router";

const emits = defineEmits(["update:modelValue", "buttonClick"]);

const props = defineProps<{
  noDataButtonProps?: {
    route: string;
    buttonClass: string;
    icon: string;
    iconColor: string;
    buttonText: string;
    headerText: string;
    descriptionText: string;
  };
  icon: string;
}>();


// const props = defineProps({
//   noDataButtonProps: {
//     type: Object,
//     default: () => ({
//       route: "",
//       buttonClass: "",
//       icon: "",
//       iconColor: "",
//       buttonText: "",
//       headerText: "There are no data to display yet",
//       descriptionText: "It's time to create the first one!",
//     }),
//   },
//   icon: {
//     type: String,
//     default: "",
//   },
// });

const emitButtonClick = () => {
  emits("buttonClick", props.noDataButtonProps);
}
</script>

<template>
  <div class="no-data-container">
    <div class="no-data-text">
      <div class="icon">
        <IconAtom :icon="icon" />
      </div>
      <h3>{{ props.noDataButtonProps.headerText }}</h3>
      <p>{{ props.noDataButtonProps.descriptionText }}</p>
    </div>

    <div class="no-data-button" v-if="props.noDataButtonProps?.buttonText">

      <div @click="emitButtonClick" class="link">
        <ButtonAtom :class="props.noDataButtonProps.buttonClass">
          <IconAtom :icon="props.noDataButtonProps.icon" :color="props.noDataButtonProps.iconColor" />
          <p>{{ props.noDataButtonProps.buttonText }}</p>
        </ButtonAtom>
      </div>
    </div>
  </div>
</template>

<style Scoped src="./NoDataMolecule.css"></style>
