import { createStore } from 'vuex';
// import VuexPersistence from "vuex-persist";
// import ResourceStore from "./modules/resource.store";
import resource from './modules/resource.store';
import user from './modules/user.store';
import tag from './modules/tag.store';
import collection from './modules/collection.store';
import group from './modules/group.store';
import comment from './modules/comment.store';
import notification from './modules/notification.store';
import { loader } from './modules/loader.store';
import analytics from './modules/analytics.store';

const store = createStore({
  modules: {
    resource,
    user,
    tag,
    collection,
    loader,
    group,
    comment,
    notification,
    analytics,
  },
});

export default store;
