export default class Tag {
  tag_id: string;
  tag_name: string;
  resources_count: number;
  collection_count: number;

  constructor(data: Partial<Tag>) {
    this.tag_id = data.tag_id || '';
    this.tag_name = data.tag_name || '';
    this.resources_count = data.resources_count || 0;
    this.collection_count = data.collection_count || 0;
  }
}
