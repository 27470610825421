<template>
  <div class="analytics-container">
    <BaseAnalyticsCardMolecule
      icon="revenue-icon"
      prefix="$"
      name="MRR"
      :count="analyticsData?.MRR"
    />
    <BaseAnalyticsCardMolecule
      icon="revenue-icon"
      prefix="$"
      name="ARR"
      :count="analyticsData?.ARR"
    />
    <BaseAnalyticsCardMolecule
      icon="revenue-icon"
      suffix="%"
      name="Conversion Rate"
    />
    <BaseAnalyticsCardMolecule
      icon="revenue-icon"
      prefix="$"
      name="Gross margin"
    />
    <BaseAnalyticsCardMolecule
      icon="revenue-icon"
      prefix="$"
      name="Avg.  revenue per user"
      :count="analyticsData?.averagePerUser"
    />
    <BaseAnalyticsCardMolecule
      icon="revenue-icon"
      suffix="%"
      name="Refund rate"
    />
    <AnalyticsCardOrganism
      title="Monthly Recurring Revenue (MRR)"
      class="colspan-3"
    >
      <template #body>
        <LineChart
          v-if="analyticsData?.mrrPeriod"
          :data="analyticsData?.mrrPeriod"
        />
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism
      title="Annual Recurring Revenue (ARR)"
      class="colspan-3"
    >
      <template #body>
        <LineChart
          v-if="analyticsData?.arrPeriod"
          :data="analyticsData?.arrPeriod"
        />
      </template>
    </AnalyticsCardOrganism>
    <AnalyticsCardOrganism
      v-if="analyticsData?.conversionRateFromInstallToSub"
      title="Conversion rate from install to subscription"
      class="colspan-3"
    />
  </div>
</template>

<script setup lang="ts">
  import BaseAnalyticsCardMolecule from '@/components/molecules/base-analytics-card/BaseAnalyticsCardMolecule.vue';
  import AnalyticsCardOrganism from '@/components/organisms/analytics-card/AnalyticsCardOrganism.vue';
  import LineChart from '@/components/organisms/charts/LineChart.vue';
  import store from '@/store';
  import { computed, onBeforeMount } from 'vue';

  onBeforeMount(() => {
    store.dispatch('analytics/fetchFinancialAnalytics');
  });

  const analyticsData = computed(
    () => store.getters['analytics/getAnalyticsData'],
  );
</script>

<style scoped>
  .analytics-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  .colspan-3 {
    grid-column: span 3;
  }
</style>
